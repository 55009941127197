<template>
  <div class="faq-container">
    <!-- Navigation/Legend -->
    <nav class="faq-nav">
      <h1>F.A.Q</h1>
      <img class="faq-img" src="../assets/faq.jpg" />
      <h2 class="faq-title">Thumpr FAQ</h2>
      <ul class="faq-nav-list">
        <li><a href="#what-is-thumpr" @click.prevent="scrollTo('what-is-thumpr')">{{ localize('NAV_WHAT_IS_THUMPR') }}</a></li>
        <li><a href="#how-do-i-sign-up" @click.prevent="scrollTo('how-do-i-sign-up')">{{ localize('NAV_HOW_DO_I_SIGN_UP') }}</a></li>
        <li><a href="#how-do-i-create-gig" @click.prevent="scrollTo('how-do-i-create-gig')">{{ localize('NAV_HOW_DO_I_CREATE_GIG') }}</a></li>
        <li><a href="#do-i-need-eth-gas" @click.prevent="scrollTo('do-i-need-eth-gas')">{{ localize('NAV_DO_I_NEED_ETH_GAS') }}</a></li>
        <li><a href="#what-if-no-gas" @click.prevent="scrollTo('what-if-no-gas')">{{ localize('NAV_WHAT_IF_NO_GAS') }}</a></li>
        <li><a href="#how-does-ordering-work" @click.prevent="scrollTo('how-does-ordering-work')">{{ localize('NAV_HOW_DOES_ORDERING_WORK') }}</a></li>
        <li><a href="#what-if-wrong" @click.prevent="scrollTo('what-if-wrong')">{{ localize('NAV_WHAT_IF_WRONG') }}</a></li>
        <li><a href="#why-base-wallet" @click.prevent="scrollTo('why-base-wallet')">{{ localize('NAV_WHY_BASE_WALLET') }}</a></li>
        <li><a href="#is-money-safe" @click.prevent="scrollTo('is-money-safe')">{{ localize('NAV_IS_MONEY_SAFE') }}</a></li>
        <li><a href="#tech-details" @click.prevent="scrollTo('tech-details')">{{ localize('NAV_TECH_DETAILS') }}</a></li>
        <li><a href="#more-questions" @click.prevent="scrollTo('more-questions')">{{ localize('NAV_MORE_QUESTIONS') }}</a></li>
      </ul>
    </nav>

    <!-- FAQ Sections -->
    <div class="faq-content">
      <section id="what-is-thumpr" class="faq-section">
        <h3>{{ localize('WHAT_IS_THUMPR') }}</h3>
        <p>{{ localize('WHAT_IS_THUMPR_DESC') }}</p>
      </section>

      <section id="how-do-i-sign-up" class="faq-section">
        <h3>{{ localize('HOW_DO_I_SIGN_UP') }}</h3>
        <p>{{ localize('HOW_DO_I_SIGN_UP_DESC') }}</p>
      </section>

      <section id="how-do-i-create-gig" class="faq-section">
        <h3>{{ localize('HOW_DO_I_CREATE_GIG') }}</h3>
        <p>{{ localize('HOW_DO_I_CREATE_GIG_DESC') }}</p>
      </section>

      <section id="do-i-need-eth-gas" class="faq-section">
        <h3>{{ localize('DO_I_NEED_ETH_GAS') }}</h3>
        <p>{{ localize('DO_I_NEED_ETH_GAS_DESC') }}</p>
      </section>

      <section id="what-if-no-gas" class="faq-section">
        <h3>{{ localize('WHAT_IF_NO_GAS') }}</h3>
        <p>{{ localize('WHAT_IF_NO_GAS_DESC') }}</p>
      </section>

      <section id="how-does-ordering-work" class="faq-section">
        <h3>{{ localize('HOW_DOES_ORDERING_WORK') }}</h3>
        <p>{{ localize('HOW_DOES_ORDERING_WORK_DESC') }}</p>
      </section>

      <section id="what-if-wrong" class="faq-section">
        <h3>{{ localize('WHAT_IF_WRONG') }}</h3>
        <p>{{ localize('WHAT_IF_WRONG_DESC') }}</p>
      </section>

      <section id="why-base-wallet" class="faq-section">
        <h3>{{ localize('WHY_BASE_WALLET') }}</h3>
        <p>{{ localize('WHY_BASE_WALLET_DESC') }}</p>
      </section>

      <section id="is-money-safe" class="faq-section">
        <h3>{{ localize('IS_MONEY_SAFE') }}</h3>
        <p>{{ localize('IS_MONEY_SAFE_DESC') }}</p>
      </section>

      <section id="tech-details" class="faq-section">
        <h3>{{ localize('TECH_DETAILS') }}</h3>
        <p>{{ localize('TECH_DETAILS_DESC') }}</p>
      </section>

      <a href="https://basescan.org/address/0xE1d7071a229D8f0B899A99C19AC3a51EF621F152" class="faq-link">
        https://basescan.org/address/0xE1d7071a229D8f0B899A99C19AC3a51EF621F152
      </a>

      <section id="more-questions" class="faq-section">
        <h3>{{ localize('MORE_QUESTIONS') }}</h3>
        <p>{{ localize('MORE_QUESTIONS_DESC') }}</p>
      </section>
    </div>
  </div>
</template>

<script>
import {translationStore} from "@/stores/translation";
export default {
  name: "ThumprFAQ",
  components: {},
  props: {
    isMin: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    // Placeholder for your localize function (replace with your actual implementation)
    const translation = translationStore(),
      scrollTo = (sectionId) => {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };

    return {"localize": translation.localize,
      scrollTo
    };
  }
};
</script>

<style scoped>
/* Optional: Tailwind-inspired styling */
.h2 {
  color: #8831bc;
}
.h2 {
  color: #8831bc;
}
.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.faq-nav {
  position: sticky;
  top: 0;
  padding: 10px 0;
  border-bottom: 1px solid #e5e7eb;
}

.faq-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.faq-nav-list {
  list-style: none;
  padding: 0;
}

.faq-nav-list li {
  margin: 5px 0;
}

.faq-nav-list a {
  text-decoration: none;
  color: #59179d;
  cursor: pointer;
}

.faq-nav-list a:hover {
  text-decoration: underline;
}

.faq-content {
  margin-top: 20px;
}

.faq-section {
  margin-bottom: 20px;
}

.faq-section h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
}

.faq-section p {
  color: #4b5563;
}

.faq-link {
  color: #266dab;
  text-decoration: none;
}

.faq-img {
  max-width: 500px;
}

</style>
