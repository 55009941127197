<template>
  <div id="vue-app">
    <!-- Header & Navbar -->
    <div
      class="app-flex"
    >
      <div
        class="top-mobile-border"
        v-if="serverConfig.view == 'mobile'"
      />
      <div
        id="header"
        class="header-white"
      >
        <div
          id="navbar-background"
          :class="navbarBackgroundClass"
        />
        <div
          class="navbar"
          justify="start"
        >
          <div
            id="Nav"
          >
            <div
              class="top-bar-flex"
            >
              <div class="flex-row-grow">
                <Burger />
                <div
                  class="uchan-logo"
                  @click="gotoPath('')"
                />
                <CSearchBar
                  v-if="serverConfig.view == 'desktop' || serverConfig.view == 'laptop' || serverConfig.view == 'tablet'"
                  :class="navbarSearchBG"
                  @inputValue="query.text = $event"
                >
                  <template #results>
                    <div
                      v-show="queryServices.length > 0 || queryCreators.length > 0"
                      class="query-response-container"
                    >
                      <div
                        class="query-topic"
                        v-if="queryServices.length > 0"
                      >
                        {{localize("TEXT_SERVICES")}}
                      </div>
                      <div
                        v-for="i in queryServices"
                        class="query-response-line"
                      >
                        <Transition
                          name="lineitem"
                          appear
                        >
                          <div
                            class="query-result"
                            @click="gotoPath('services/' + i.id)"
                          >
                            <img v-if="i.image" class="query-result-image" :src="getURL(i.image)" />
                            <div>
                              <div>
                                {{ i.title }}
                              </div>
                              <div>
                                {{ i.name }}
                              </div>
                            </div>
                          </div>
                        </Transition>
                      </div>
                      <div
                        class="query-topic"
                        v-if="queryCreators.length > 0"
                      >
                        {{localize("TEXT_PEOPLE")}}
                      </div>
                      <div
                        v-for="i in queryCreators"
                        class="query-response-line"
                      >
                        <Transition
                          name="lineitem"
                          appear
                        >
                          <div
                            class="query-result"
                            @click="gotoPath('profile/' + i.user_id)"
                          >
                            <img v-if="i.image" class="query-result-image" :src="getURL(i.image)" />
                            <div>
                              <div>
                                {{ i.title }}
                              </div>
                              <div>
                                {{ i.name }}
                              </div>
                            </div>
                          </div>
                        </Transition>
                      </div>
                    </div>
                  </template>
                </CSearchBar>
              </div>
              <div class="center-end">
                <div class="flex-row">
                  <div
                    class="flex-col-app-center"
                    @click="showClaimModal = true"
                  >
                    <div
                      :class="navbarItem"
                    >
                      <div
                        class="carrot-icon"
                      />
                      <div
                        v-if="serverConfig.view == 'mobile'"
                        :class="navbarItem"
                      >
                        {{numCarrots}}
                      </div>
                      <div
                        v-else
                        :class="navbarItem"
                      >
                        {{numCarrots}} {{localize('TEXT_CARROTS')}}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="selectedProvider == 'coinbase'"
                    class="flex-col-app-center"
                    @click="gotoUrl('https://wallet.coinbase.com/assets')"
                  >
                    <div
                      :class="navbarItem"
                    >
                      <div
                        class="base-wallet-icon"
                      >
                      </div>
                      <div
                        v-if="serverConfig.view != 'mobile'"
                        :class="navbarItem"
                      >
                        {{localize('TEXT_WALLET')}}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="userInfo?.authorized == true"
                    class="flex-col-app-center"
                    @click="gotoPath('messenger/')"
                  >
                    <div
                      :class="navbarItem"
                    >
                      <div
                        class="messages-icon"
                        v-html="getRawIcon('MESSAGES')"
                      />
                      <div
                        v-if="serverConfig.view != 'mobile'"
                        :class="navbarItem"
                      >
                        {{localize('TEXT_MESSAGES')}}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="userInfo?.authorized == true"
                    class="flex-col-app-center"
                    @click="gotoPath('orders/')"
                  >
                    <div
                      :class="navbarItem"
                    >
                      <div
                        class="orders-icon"
                        v-html="getRawIcon('ORDERS')"
                      />
                      <div
                        v-if="serverConfig.view != 'mobile'"
                        :class="navbarItem"
                      >
                        {{localize('TEXT_ORDERS')}}
                      </div>
                    </div>
                  </div>
                  <div
                    class="menu-items"
                    v-if="userInfo?.authorized == false"
                    @click="gotoSignUp('profile')"
                  >
                    <div
                      class="flex-col-app-center"
                    >
                      <div
                        :class="navbarItem"
                      >
                        <img
                          class="profile-img-small"
                          src="./assets/profile-out.png"
                        >
                        <div
                          :class="navbarItem"
                        >
                          {{localize('TEXT_LOGIN')}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="menu-items"
                    v-if="userInfo.authorized == true"
                    @click="gotoSignUp('profile')"
                    @mouseenter="showMenu" @mouseleave="hideMenu"
                  >
                    <div class="profile-menu" :class="{ 'show-menu': isMenuVisible }">
                      <div
                        class="menu-item-text"
                        @click.stop="gotoPath('faq/')"
                      >
                        faq
                      </div>
                      <div
                        class="menu-item-text"
                        @click.stop="doLogout()"
                      >
                        logout
                      </div>
                    </div>
                    <div id="app-profile-image" class="profile-icon">
                      <img
                        class="profile-img"
                        src="./assets/profile-in.png"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CSearchBar
            v-if="serverConfig.view == 'mobile'"
            :class="navbarSearchBG"
            @inputValue="query.text = $event"
          >
            <template #results>
              <div
                v-show="queryServices.length > 0 || queryCreators.length > 0"
                class="query-response-container"
              >
                <div
                  class="query-topic"
                  v-if="queryServices.length > 0"
                >
                  {{localize("TEXT_SERVICES")}}
                </div>
                <div
                  v-for="i in queryServices"
                  class="query-response-line"
                >
                  <Transition
                    name="lineitem"
                    appear
                  >
                    <div
                      class="query-result"
                      @click="gotoPath('services/' + i.id)"
                    >
                      <img class="query-result-image" :src="getURL(i.image)" />
                      <div>
                        <div>
                          {{ i.title }}
                        </div>
                        <div>
                          {{ i.name }}
                        </div>
                      </div>
                    </div>
                  </Transition>
                </div>
                <div
                  class="query-topic"
                  v-if="queryCreators.length > 0"
                >
                  {{localize("TEXT_PEOPLE")}}
                </div>
                <div
                  v-for="i in queryCreators"
                  class="query-response-line"
                >
                  <Transition
                    name="lineitem"
                    appear
                  >
                    <div
                      class="query-result"
                      @click="gotoPath('profile/' + i.user_id)"
                    >
                      <img class="query-result-image" :src="getURL(i.image)" />
                      <div>
                        <div>
                          {{ i.title }}
                        </div>
                        <div>
                          {{ i.name }}
                        </div>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
            </template>
          </CSearchBar>

        </div>
      </div>

      <div class="app-container">

<!--
        <div v-if="$route.path === '/'" id="home" class="bg-mask">
          <div class="bg-mask-rect-outer">
            <div class="bg-mask-rect-inner" />
          </div>
        </div>
        <div v-if="$route.path === '/exchange/'" id="home" class="bg-mask-exchange">
          <div class="bg-mask-exchange-outer">
            <div class="bg-mask-exchange-inner">
              <div class="bg-mask-exchange-inner-inner" />
            </div>
          </div>
        </div>
-->

        <Sidebar class="sidebar">
          <template #mobile>
            <div class="sidebar-panel-nav">
              <div>
                <div
                  class="nav-tab-no-mobile"
                  @click="gotoTab('home')"
                >
                  {{ localize('TEXT_HOME') }}
                </div>
              </div>
              <div
                v-if="userInfo.authorized == false"
              >
                <div
                  class="nav-tab-no-mobile"
                  @click="showLoginModal = true"
                >
                  {{ localize('TEXT_CONNECT') }}
                </div>
              </div>
              <div
                v-if="userInfo.authorized == true"
              >
                <div
                  class="nav-tab-no-mobile"
                  @click="doLogout"
                >
                  {{ localize('TEXT_LOGOUT') }}
                </div>
              </div>
            </div>
            <div
              class="social-mobile"
            >
              <div
                class="discord"
                @click="gotoUrl('https://discord.gg/hhz7ZkfZe8')"
              />
              <div
                class="twitter"
                @click="gotoUrl('https://x.com/Thumpr915396')"
              />
            </div>
          </template>
          <template #desktop-compact>
            <div class="desktop-compact">
              <div
                class="compact-icon"
                v-html="icon('NEWS_FEED')"
              />
              <div
                class="compact-icon"
                v-html="icon('TRENDING')"
              />
              <div
                class="compact-icon"
                v-html="icon('FOLLOWING')"
              />
            </div>
          </template>
          <template #desktop-expand>
            <div class="desktop-expand">
              <div class="flex-row desktop-expand-item">
                <div
                  class="expand-icon"
                  v-html="icon('NEWS_FEED_SMALL')"
                />
                <div class="expand-text">
                  News Feeds
                </div>
              </div>
              <div class="flex-row desktop-expand-item">
                <div
                  class="expand-icon"
                  v-html="icon('TRENDING_SMALL')"
                />
                <div class="expand-text">
                  Trending
                </div>
              </div>
              <div class="flex-row desktop-expand-item">
                <div
                  class="expand-icon"
                  v-html="icon('FOLLOWING_SMALL')"
                />
                <div class="expand-text">
                  Following
                </div>
              </div>
            </div>
          </template>
        </Sidebar>

        <div :key="$route.path" :class="contentClass">

          <div class="header-design">

            <Transition
              name="route"
              mode="out-in"
            >
              <div v-if="$route.path === '/'" :class="mainBanner" :style="getBanner()">
                <div :class="bannerText" />
              </div>
              <div v-else :class="mainBanner" :style="getBanner()">
                <div :class="bannerText" />
              </div>
            </Transition>

            <div v-if="$route.path === '/'" class="mid-bottom-mask">
              <svg :class="dashboardSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <defs>
                  <radialGradient id="grad" x1="50%" y1="70%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#ff5d99" />
                    <stop offset="100%" stop-color="#ff5cfc78" />
                  </radialGradient>
                </defs>
                <path data-v-2f1a2f6e="" fill="#fafbfb" fill-opacity="1" d="M 0 192 L 30 176 C 60 160 120 128 180 144 C 240 160 300 224 360 245.3 C 420 267 480 245 540 224 C 600 203 660 181 720 165.3 C 780 149 840 139 900 154.7 C 960 171 1020 213 1080 197.3 C 1140 181 1206.328 145.755 1266.328 118.755 C 1326.328 91.755 1409.832 100.937 1439.832 111.237 L 1440 320 L 1410 320 C 1380 320 1320 320 1260 320 C 1200 320 1140 320 1080 320 C 1020 320 960 320 900 320 C 840 320 780 320 720 320 C 660 320 600 320 540 320 C 480 320 420 320 360 320 C 300 320 240 320 180 320 C 120 320 60 320 30 320 L 0 320 L 0 192 Z"></path>
              </svg>
            </div>

          </div>

          <div :class="navMenu">

            <div
              class="flex-col"
            >
              <div v-if="$route.path === '/'" :class="bannerText">
                {{localize("MAIN_BANNER_TEXT")}}
              </div>
              <div v-if="$route.path === '/'" class="normal-text">
                {{localize("MAIN_BANNER_SUBTEXT")}}
              </div>
            </div>
            <Transition name="bounce">
              <div
                v-if="claimableCarrots"
                :class="centerBanner"
                @click="showClaimModal = true"
              >
                <div class="orangebutton" />
                <div class="claimcarrot" />
              </div>
            </Transition>
            <div
              v-if="$route.path === '/' || $route.path === '/services/'"
              @click="gotoSignUp('list')"
              :class="createButton"
            >
              <div
                :class="menuIcon"
                v-html="getRawIcon('CREATE')"
              />
              <div
                class="menu-text"
                v-if="serverConfig.view == 'desktop' || serverConfig.view == 'laptop'"
              >
                {{ localize('TEXT_CREATE') }}
              </div>
            </div>

          </div>

            <Transition
              name="route"
              mode="out-in"
            >
          <router-view v-slot="{Component}">
              <component
                class="component-margin"
                v-if="$route.path"
                :is="Component"
                :key="$route.fullPath"
              />
          </router-view>
            </Transition>
        </div>
      </div>
      <SVFooter
        @showTerms="showTermsModal = true"
        class="app-footer"
      />
    </div>

    <Transition>
      <CModal
        v-if="showChainModal"
        width="small"
        color="#efefef"
        backgroundColor="#333"
        secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        @close="showChainModal = false"
      >
        <template #header>
          <div> {{ localize("TEXT_CHANGE_CHAIN") }} </div>
        </template>
        <template #body>
          <div class="change-chain-modal">
            <div
              v-for="i in chains"
            >
              <div
                class="change-chain-modal-icon"
                @click="switchChain(i.id)"
                v-html="getIcon(i.icon)"
              />
            </div>
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

    <Transition>
      <CModal
        v-if="showClaimModal"
        width="small"
        color="#efefef"
        backgroundColor="#FFF"
        secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        @close="showClaimModal = false"
      >
        <template #header>
          <div> {{ localize("TEXT_CARROTS") }} </div>
        </template>
        <template #body>
          <img src="./assets/carrot-icon.png" style="width:100%;">
          <div v-if="userInfo?.authorized == true"> 
            <div class="center-carrot">
               {{localize('CLAIM_CARROTS')}}
            </div>
            <div
              class="accept-button margin-top"
              @click="claimCarrots"
            >
              <div v-if="claimableCarrots==0">
                No carrots left :-< , please check later
              </div>
              <div v-else>
                {{claimableCarrots}} {{localize('TEXT_CARROTS')}}
               </div>
            </div>
          </div>
          <div
            v-else
          >
            {{ localize("ABOUT_CLAIM_CARROTS") }}
            <div
              class="accept-button margin-top"
              @click="showClaimModal = false;showLoginModal = true"
            >
                Login
            </div>
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

    <Transition>
      <CModal
        v-if="showRegisterModal"
        width="small"
        color="#efefef"
        backgroundColor="#FFF"
        secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        @close="showRegisterModal = false"
      >
        <template #header>
          <div> {{ localize("APP_SET_EMAIL") }} </div>
        </template>
        <template #body>
        <UserEmail @hide-modal-event="showRegisterModal = false" @get-userinfo-event="emitAppEvent('getUserInfo')" />
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

    <Transition>
      <CModal
        v-if="showTermsModal"
        color="#efefef"
        backgroundColor="#f9f9f9"
        secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        width="medium"
        @close="showTermsModal = false"
      >
        <template #header>
          <div> {{ localize("TEXT_TERMS_CONDITIONS") }} </div>
        </template>
        <template #body>
          <div class="pretty-text">
            <br />
            <h2> <b> {{ localize("TEXT_TERMS_CONDITIONS") }} </b></h2>
            <br />
            <br />
            <b>{{ localize("TERMS_INTRODUCTION") }}</b>
            <br />
            <br />
            <div>{{ localize("TERMS_INTRODUCTION_A") }}</div>
            <br />
            <div>{{ localize("TERMS_INTRODUCTION_B") }}</div>
            <br />
            <div>{{ localize("TERMS_INTRODUCTION_C") }}</div>
            <br />
            <div>
              <span>
                {{ localize("TERMS_INTRODUCTION_D") }} 
                <a href="mailto:contact@thumpr.xyz" style="text-decoration: none; color: inherit;"></a>
              </span>
            </div>
            <br />
            <b>{{ localize("TERMS_SERVICES_PROVIDED") }}</b>
            <br />
            <br />
            <div> {{ localize("TERMS_SERVICES_PROVIDED_A") }}</div>
            <br />
            <div> {{ localize("TERMS_SERVICES_PROVIDED_B") }}</div>
            <br />
            <div> {{ localize("TERMS_SERVICES_PROVIDED_C") }}</div>
            <br />
            <div> {{ localize("TERMS_SERVICES_PROVIDED_D") }}</div>
            <br />
            <b> {{ localize("TERMS_COMMUNITY_STANDARDS") }}</b>
            <br />
            <br />
            <div> {{ localize("TERMS_COMMUNITY_STANDARDS_A") }}</div>
            <br />
            <ul>
            <li> {{ localize("TERMS_COMMUNITY_STANDARDS_B") }}</li>
            <li> {{ localize("TERMS_COMMUNITY_STANDARDS_C") }}</li>
            <li> {{ localize("TERMS_COMMUNITY_STANDARDS_D") }}</li>
            <li> {{ localize("TERMS_COMMUNITY_STANDARDS_E") }}</li>
            <li> {{ localize("TERMS_COMMUNITY_STANDARDS_F") }}</li>
            <li> {{ localize("TERMS_COMMUNITY_STANDARDS_G") }}</li>
            </ul>
            <br />
            <div> {{ localize("TERMS_COMMUNITY_STANDARDS_H") }}</div>
            <br />
            <b>{{ localize("TERMS_PAYMENT") }}</b>
            <br />
            <br />
            <div> {{ localize("TERMS_PAYMENT_A") }}</div>
            <br />
            <div> {{ localize("TERMS_PAYMENT_B") }}</div>
            <br />
            <div> {{ localize("TERMS_PAYMENT_C") }}</div>
            <br />
            <b> {{ localize("TERMS_LIMITATION_OF_LIABILITY") }}</b>
            <br />
            <br />
            <div> {{ localize("TERMS_LIMITATION_OF_LIABILITY_A") }}</div>
            <br />
            <div> {{ localize("TERMS_LIMITATION_OF_LIABILITY_B") }}</div>
            <br />
            <div> {{ localize("TERMS_LIMITATION_OF_LIABILITY_C") }}</div>
          </div>
          <div class="flex-col">
            <div
              class="accept-button margin-top"
              @click="enableAccount(); showTermsModal = false;"
            >
               {{localize('TEXT_ACCEPT')}}
            </div>
            <div
              class="decline-button margin-top"
              @click="doLogout(); showTermsModal = false;"
            >
               {{localize('TEXT_DECLINE')}}
            </div>
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

    <Transition>
      <CModal
        v-if="showAboutModal"
        width="small"
        color="#efefef"
        backgroundColor="#333"
        secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        @close="showAboutModal = false"
      >
        <template #header>
          <div> {{ localize('TEXT_ABOUT') }} </div>
        </template>
        <template #body>
          <div class="flex-col">
            <div class="connect-text">
              {{ localize('TEXT_ABOUT') }}
            </div>
            <div class="flex-col connect-wallets">
              Uchan enabled online services 
            </div>
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

    <Transition>
      <CModal
        v-if="showLoginModal"
        color="#efefef"
        backgroundColor="#333"
        secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        @close="showLoginModal = false"
      >
        <template #header>
          <div> {{ localize('TEXT_LOGIN') }} </div>
        </template>
        <template #body>
          <div class="flex-col">
            <div class="connect-text">
              {{ localize('LOGIN_CONNECT_WALLET') }}
            </div>
            <div class="flex-col connect-wallets">
              <div class="wallet-select-container">
                <div
                  class="connect-img-container"
                  @click="coinbaseLogin"
                >
                  <img
                    class="connect-img"
                    src="./assets/coinbase-wallet-small.png"
                  >
                  <div
                    :class="walletText"
                  >
                    Coinbase
                  </div>
                </div>
                <div
                  class="connect-img-container"
                  @click="metamaskLogin"
                >
                  <img
                    class="connect-img"
                    src="./assets/metamask-small.png"
                  >
                  <div
                    :class="walletText"
                  >
                    MetaMask
                  </div>
                </div>
<!--
                <div
                  class="connect-img-container"
                  @click="phantomLogin"
                >
                  <img
                    class="connect-img"
                    src="./assets/phantom-small.png"
                  >
                  <div
                    :class="walletText"
                  >
                    Phantom (testing)
                  </div>
                </div>
-->
              </div>
<!--
              <div
                class="connect-img-container"
                @click="magicClicks.login++"
              >
                <img
                  class="connect-img"
                  src="./assets/magiclink.png"
                >
                <div :class="walletText">
                  MagicConnect
                </div>
              </div>
-->
              <div class="wallet-advice">
                <b>{{ localize('WALLET_UNSURE') }}</b>
                <br>
                <br>
                {{ localize('WALLET_ADVICE') }}
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

  </div>
</template>

<script>
import {inject, nextTick, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import Axios from "axios";
import Burger from "./components/Burger.vue";
import SVFooter from "./components/SVFooter.vue";
import Sidebar from "./components/Sidebar.vue";
import {iconStore} from "@/stores/icon";
import {mutations} from "@/stores/sidebar.js";
import {translationStore} from "@/stores/translation";
import UserEmail from "./components/UserEmail.vue";
import { parseErc6492Signature } from 'viem/experimental'


export default {
    "name": "App",
    // conflict between vue ordering and alphavetic ordering
    // eslint-disable-next-line
    components: {
        Burger,
        Sidebar,
        SVFooter,
        UserEmail
    },
    setup (_, {emit}) {

        const eBus = inject("eBus"),
            contentClass = ref(""),
            emitAppEvent = (e, d) => {

                eBus.emit(
                    "app-event",
                    {
                        "action": e,
                        "data": d
                    }
                );

            },
            chains = reactive([]),
            name = ref(""),
            email = ref(""),
            createButton = ref(),
            menuIcon = ref(),
            navMenu = ref("menu-container"),
            query = ref({}),
            doQuery = ref(false),
            queryInFlight = ref(false),
            queryServices = ref([]),
            queryCreators = ref([]),
            accessRequest = ref(false),
            isMenuVisible = ref(false),
            // TODO : make dynamic from database
            activeChain = ref(0),
            showChainModal = ref(false),
            showClaimModal = ref(false),
            centerBanner = ref('center-banner-desktop'),
            gotoPage = ref('profile'),
            numCarrots = ref(0),
            claimableCarrots = ref(10),
            icon = iconStore(),
            mainBanner = ref('main-banner-desktop'),
            bannerText = ref('banner-text'),
            dashboardSvg = ref('dashboard-svg-desktop'),
            lastScrollPosition = ref(0),
            navbarSearchBG = ref('navbar-search-light'),
            navbarItem = ref('navbar-item-dark'),
            navbarSearchDesktop = ref("navbar-search-container"),
            navbarBackgroundClass = ref("navbar-background-tall"),
            selectedProvider = ref(),
            provider = inject("provider"),
            userProfile = inject("profile"),
            route = useRoute(),
            router = useRouter(),
            serverConfig = inject("serverConfig"),
            showTermsModal = ref(false),
            showLoginModal = ref(false),
            showRegisterModal = ref(false),
            translation = translationStore(),
            userInfo = inject("userInfo"),
            walletText = ref("wallet-text-desktop"),
            helperVideoContainer = ref("helper-video-container"),
            videoHelper = ref("video-helper"),
            closeVideoHelper = ref("close-video"),
            videoControls = ref(false),
            web3 = inject("web3"),
            eth = inject("eth"),
            gotoUrl = function (url) {

                window.open(url, "_blank");

            },
            showMenu = function () {

                isMenuVisible.value = true;

            },
            hideMenu = function () {

                isMenuVisible.value = false;

            },
            setEmail = async function () {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}setEmail`,
                    {

                        "params": {

                            name:name.value,
                            email:email.value

                        }

                    }

                ).
                    then((response) => {

                        if (response.status === 200) {

                            showRegisterModal.value = false;

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getCarrots = async function () {

                if (userInfo.authorized === false) {

                    numCarrots.value = 0;
                    return

                }

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}getCarrots`,
                    {

                        "params": {

                        }

                    }

                ).
                    then((response) => {

                        if (response.status === 200) {

                            if (response.data?.length === 0) {

                                numCarrots.value = 0;
                                claimableCarrots.value = 10;

                            } else {

                                numCarrots.value = response.data.carrots;
                                claimableCarrots.value = response.data.claimable;

                            }

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            claimCarrots = async function () {

                if (userInfo.authorized === false) {

                    claimableCarrots.value = 10;
                    return

                }

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}claimCarrots`,
                    {

                        "params": {

                        }

                    }

                ).
                    then((response) => {

                        if (response.status === 200) {

                            showClaimModal.value = false;
                            getCarrots();

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            connectWallet = async function() {

                try {

                  const accounts = await eth.value.request({ method: 'eth_requestAccounts' });
                  console.log('Connected accounts:', accounts);

                } catch (error) {

                  console.error('Connection failed:', error);

                }

            },
            enableAccount = async function () {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}enableAccount`,
                    {

                        "params": {

                        }

                    }

                ).
                    then((response) => {

                        if (response.status === 200) {

                            showTermsModal.value = false;
                            userInfo.creator = response.data;

                            if (accessRequest.value === true) {

                                gotoSignUp(gotoPage.value);

                            }

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            switchChain = function (id) {

                showChainModal.value = false;
                emitAppEvent(
                    "setNetwork",
                    id
                );

            },
/*
            // Plain JS functions
            signInAccount = async function (signature, account, provider) {

                const accounts = await eth.value.request({ method: 'eth_accounts' });
                const address = accounts[0];

                let ep = "signIn";
                if (userInfo.authorized === true) {

                    ep = "addAccount";

                }

                Axios.get(

                    process.env.VUE_APP_SERVER_URI + ep,
                    {
                        "params": {
                            account,
                            signature,
                            provider,
                            address
                        }
                    }

                ).
                    then((response) => {

                        if (response.status === 200) {

                            showLoginModal.value = false;
                            emitAppEvent("getUserInfo");

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
*/
            metamaskLogin = function () {

                localStorage.setItem(
                    "selectedProvider",
                    "metamask"
                );

                emitAppEvent(
                    "getWeb3",
                    "login"
                );

            },
            coinbaseLogin = function () {

                localStorage.setItem(
                    "selectedProvider",
                    "coinbase"
                );

                emitAppEvent(
                    "getWeb3",
                    "login"
                );

            },
            phantomLogin = function () {

                localStorage.setItem(
                    "selectedProvider",
                    "phantom"
                );

                emitAppEvent(
                    "getWeb3",
                    "login"
                );

            },
            doLogout = function () {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}/logout`,
                    {
                    }
                ).
                    then((response) => {

                        if (response.data) {

                            emitAppEvent("getUserInfo");

                        }

                    });

            },
            getURL = function (endpoint) {

                return `${process.env.VUE_APP_SERVER_URI + endpoint}`;

            },
            getBanner = function () {

                var num = 1;
                if (route.path != "/") {
                  num = Math.floor(Math.random() * 16) + 1;
                }

                return {
                  backgroundImage: `url(${"/public/images/usagi-banner" + num + ".jpg"})`,
                  backgroundSize: 'cover', // Optional styling
                  backgroundPosition: 'center', // Optional styling
                };

            },
            getIcon = function (i) {

                //if (serverConfig.visualMode.value === "dark") {
                if (serverConfig.visualMode === "dark") {

                    return icon.get(`${i}_DARK`);

                }
                return icon.get(`${i}_LIGHT`);

            },
            getRawIcon = function (i) {

                return icon.get(`${i}`);

            },
            gotoTab = function (t) {

                //if (t === "home" && serverConfig.view.value === "mobile") {
                if (t === "home" && serverConfig.view === "mobile") {

                    mutations.toggleMobileNav();

                }
                router.push({"name": t,
                    "path": `/${t}`}).catch((err) => {

                    throw err;

                });

            },
            gotoPath = function (t) {

                router.push({"path": `/${t}`}).catch((err) => {

                    throw err;

                });

            },
            // go to create (list) contents
            gotoSignUp = function (p) {

                gotoPage.value = p;
                accessRequest.value = true;
                if (userInfo.authorized === true) {

                    if (userInfo.creator === true) {

                        accessRequest.value = false;

                        const url = new URL(process.env.VUE_APP_SERVER_URI);
                        let domain = url.hostname;
                        domain = domain.replace(/^www\./, '').replace(/\./g, '\\.');

                        const regex = new RegExp(`^0x[a-fA-F0-9]{20,}@${domain}$`);

                        if (regex.test(userInfo.email)) {

                            showRegisterModal.value = true;

                        } else {

                            gotoTab(gotoPage.value);

                        }

                    } else {

                        showTermsModal.value = true;

                    }

                } else {

                    // list request just means the users wants to list
                    // so we know after login to run gotoSignUp again
                    showLoginModal.value = true;

                }

            },
            handleScroll = function () {

                const n = document.getElementById("navbar-background"),
                    h = document.getElementById("header"),
                    s = window.scrollY;

                if (s > lastScrollPosition.value && s > 50) {

                    // we are scrolling down.
                    h.style.top = "-0px";
                    navbarBackgroundClass.value = "navbar-background-short";

                } else {

                    // we are scrolling up.
                    h.style.top = "0px";
                    navbarBackgroundClass.value = "navbar-background-tall";

                }

                if (s < 50) {

                    n.style.backgroundColor = "rgb(255 255 255 / 0%)";
                    //serverConfig.visualMode.value = "light";
                    if (window.innerWidth <= 768) {
                        n.style.height = "150px"
                    } else {
                      n.style.height = "80px"
                    }
                    serverConfig.visualMode = "light";
                    navbarSearchBG.value = "navbar-search-light";
                    navbarItem.value = "navbar-item-light";

                } else {

                    if (window.innerWidth <= 768) {
                        n.style.height = "150px"
                    } else {
                      n.style.height = "80px"
                    }
                    n.style.backgroundColor = "#000000bf";
                    //serverConfig.visualMode.value = "dark";
                    serverConfig.visualMode = "dark";
                    navbarSearchBG.value = "navbar-search-dark";
                    navbarItem.value = "navbar-item-dark";

                }
                lastScrollPosition.value = s;

            },
            hideModalEvent = function () {

                showLoginModal.value = false;

            },
            runQuery = function () {

                queryInFlight.value = true;

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}query`,
                    {

                        "params": {

                            text:query.value.text,
                            userId:query.value.userId,
                            category:query.value.category,
                            subcategory:query.value.subcategory,
                            geo:query.value.geo,

                        }

                    }
                ).
                    then((response) => {

                        queryServices.value.splice(0);
                        queryCreators.value.splice(0);
                        queryInFlight.value = false;

                        for (var i in response.data.services) {

                            queryServices.value.push(response.data.services[i]);

                        }

                        for (var i in response.data.creators) {

                            queryCreators.value.push(response.data.creators[i]);

                        }

                        if (doQuery.value === true) {

                            doQuery.value = false;
                            runQuery();

                        }

                    });

            },
            updateView = function () {

                /*
                 * general layout
                 * const m = document.getElementById('mobileNav')
                 * const d = document.getElementById('desktopNav')
                 */
                if (serverConfig.view === "desktop") {

                    // console.log("desktop");
                    /*
                     * m.style.display = 'none'
                     * d.style.display = 'initial'
                     */
                    menuIcon.value = "menu-icon";
                    createButton.value = "create-button";
                    contentClass.value = "desktop-content";
                    walletText.value = "wallet-text-desktop";
                    navbarSearchDesktop.value = "navbar-search-container";
                    dashboardSvg.value = "dashboard-svg-desktop";
                    bannerText.value = "banner-text";
                    mainBanner.value = "main-banner-desktop"
                    centerBanner.value = "center-banner-desktop";

                } else if (serverConfig.view === "laptop") {

                    // console.log("laptop");
                    menuIcon.value = "menu-icon";
                    createButton.value = "create-button";
                    contentClass.value = "desktop-content";
                    walletText.value = "wallet-text-desktop";
                    navbarSearchDesktop.value = "navbar-search-container";
                    dashboardSvg.value = "dashboard-svg-desktop";
                    bannerText.value = "banner-text";
                    mainBanner.value = "main-banner-desktop"
                    centerBanner.value = "center-banner-desktop";

                } else if (serverConfig.view === "tablet") {

                    // console.log("tablet");
                    menuIcon.value = "menu-icon-mobile";
                    createButton.value = "create-button-mobile";
                    contentClass.value = "mobile-content";
                    walletText.value = "wallet-text-desktop";
                    navbarSearchDesktop.value = "navbar-search-container";
                    dashboardSvg.value = "dashboard-svg-desktop";
                    bannerText.value = "banner-text";
                    mainBanner.value = "main-banner-desktop"
                    centerBanner.value = "center-banner-desktop";

                } else if (serverConfig.view === "mobile") {

                    // console.log("mobile");
                    menuIcon.value = "menu-icon-mobile";
                    createButton.value = "create-button-mobile";
                    contentClass.value = "mobile-content";
                    walletText.value = "wallet-text-mobile";
                    navbarSearchDesktop.value = "display-off";
                    dashboardSvg.value = "dashboard-svg-mobile";
                    bannerText.value = "banner-text-mobile";
                    mainBanner.value = "main-banner-mobile"
                    centerBanner.value = "center-banner-mobile";

                } else {

                    // console.log("small");
                    menuIcon.value = "menu-icon-mobile";
                    createButton.value = "create-button-mobile";
                    contentClass.value = "mobile-content";
                    walletText.value = "wallet-text-mobile";
                    navbarSearchDesktop.value = "display-off";
                    bannerText.value = "banner-text-mobile";
                    mainBanner.value = "main-banner-mobile"
                    centerBanner.value = "center-banner-mobile";

                }

            },
            closeVideo = function () {

                if (videoHelper.value === "video-helper-max") {

                    minHelperVideo();

                } else {

                    helperVideoContainer.value = "helper-video-container-gone";

                }

            },
            maxHelperVideo = function () {

                videoControls.value = true;
                videoHelper.value = "video-helper-max";
                helperVideoContainer.value = "helper-video-container-max";
                closeVideoHelper.value = "close-video-max";

            },
            minHelperVideo = function () {

                videoControls.value = false;
                videoHelper.value = "video-helper";
                helperVideoContainer.value = "helper-video-container";
                closeVideoHelper.value = "close-video";

            };

        onBeforeMount(() => {

            document.title = "thumpr";

        });

        onBeforeMount(() => {

            chains.push({
                "id": parseInt(
                    process.env.VUE_APP_ETH_CHAIN_ID,
                    10
                ),
                "name": "Ethereum",
                "icon": "ETHEREUM_PURPLE",
                "symbol": "ETH",
                "active": "false"
            });

            chains.push({
                "id": parseInt(
                    process.env.VUE_APP_MATIC_CHAIN_ID,
                    10
                ),
                "name": "Polygon",
                "icon": "POLYGON",
                "symbol": "MATIC",
                "active": "false"
            });

        });

        onMounted(() => {

            if (route.path == 'login/') {

                showLoginModal.value = true;

            }

            switchChain(8453)
            updateView();
            handleScroll();

            nextTick(async () => {

                await router.isReady();

                window.addEventListener(
                    "scroll",
                    handleScroll
                );

                if (route.query.hl) {

                    translation.setLanguage(route.query.hl);

                }

                if (route.query.ha) {

                    if (route.query.ha === "login") {

                        showLoginModal.value = true;

                    }

                }

            });

            eBus.on(
                "app-event",
                (e) => {

                    switch (e.action) {

                    case "showLoginModal":
                        showLoginModal.value = true;

                    }

                }
            );

            watch(
                () => route.name,
                () => {

                    updateView();

                }
            );
            watch(
                () => serverConfig.view,
                () => {

                    updateView();

                }
            );
            watch(
                () => serverConfig.chainId,
                () => {

                    for (let i = 0; i < chains.length; i++) {

                        //if (chains[i].id == serverConfig.chainId.value) {
                        if (chains[i].id == serverConfig.chainId) {

                            chains[i].active = true;
                            activeChain.value = i;

                        } else {

                            chains[i].active = false;

                        }

                    }

                }
            );

            watch(
                () => userInfo.authorized,
                () => {

                    selectedProvider.value = localStorage.getItem("selectedProvider");

                    getCarrots();

                    console.log("userProfile.value")
                    console.log(userProfile.value)

                    if (userProfile.value.image) {

                        const n = document.getElementById("app-profile-image");
                        if (n) {
                            n.style.background = "userProfile.value.image";
                            n.style.borderRadius = "50%";
                        }

                    }

                    if (userInfo.authorized === true) {

                        showLoginModal.value = false;

                        if (accessRequest.value === true) {

                            gotoSignUp(gotoPage.value);

                        } else if (!userInfo.email) {

                            showRegisterModal.value = true;

                        }

                    }

                }
            );

            watch(
                () => query.value.text,
                () => {

                    if (query.value.text.length < 2) {

                        queryServices.value.splice(0);
                        queryCreators.value.splice(0);
                        return;

                    }

                    doQuery.value = true;

                    if (queryInFlight.value === false) {

                        runQuery();

                        setTimeout(
                            () => {

                                queryInFlight.value = false;

                            },
                            3000
                        );

                    }

                },
                { deep: true }
            );

            watch(
                () => name,
                () => {


                }

            );

            watch(
                () => email,
                () => {


                }

            );

        });

        onBeforeUnmount(() => {

            window.removeEventListener(
                "scroll",
                handleScroll
            );

        });

        return {contentClass,
            userInfo,
            userProfile,
            walletText,
            doLogout,
            emitAppEvent,
            chains,
            activeChain,
            query,
            runQuery,
            queryInFlight,
            queryServices,
            queryCreators,
            createButton,
            selectedProvider,
            connectWallet,
            menuIcon,
            navMenu,
            centerBanner,
            navbarItem,
            navbarSearchBG,
            accessRequest,
            numCarrots,
            claimableCarrots,
            email,
            name,
            setEmail,
            isMenuVisible,
            showMenu,
            hideMenu,
            bannerText,
            enableAccount,
            dashboardSvg,
            getURL,
            mainBanner,
            getCarrots,
            claimCarrots,
            getBanner,
            getIcon,
            getRawIcon,
            gotoSignUp,
            gotoPage,
            gotoTab,
            gotoPath,
            gotoUrl,
            eBus,
            handleScroll,
            hideModalEvent,
            phantomLogin,
            metamaskLogin,
            coinbaseLogin,
            "icon": icon.get,
            lastScrollPosition,
            "localize": translation.localize,
            navbarBackgroundClass,
            navbarSearchDesktop,
            provider,
            route,
            serverConfig,
            showLoginModal,
            showChainModal,
            showClaimModal,
            showRegisterModal,
            showTermsModal,
            switchChain,
            // signInAccount,
            updateView,
            closeVideo,
            minHelperVideo,
            maxHelperVideo,
            videoControls,
            videoHelper,
            closeVideoHelper,
            helperVideoContainer,
            web3,
            eth};

    }
};

</script>

<style>

#Nav {
  width:100%;
}
#vue-app {
  position: relative;
  height: 100%;
  font-family: 'PTSans-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.15em;
  scroll-behavior: smooth;
}
[v-cloak] {
    display: none !important;
}
a {
  text-decoration: none;
}
a.nav-link {
  color: white;
  padding: 0;
}
a.nav-link:hover {
  cursor: pointer;
}
svg.profile-coat {
  margin: 10px;
}
.layout {
  width:350px;
  margin-left: auto;
  margin-right: auto;
}
#background {
  position: absolute;
  width: 100%;
  overflow: hidden;
}
.navbar {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  text-align: left;
  width: 100%;
  max-width: 1200px;
  z-index: 3;
}
.profile-icon {
  cursor: pointer;
  width: 56px;
  height: 56px;
}
.profile-icon-small {
  cursor: pointer;
  width: 32px;
  height: 32px;
}
.login-text {
  font-size: 1em;
}
.login-text:hover {
  text-decoration: underline;
  cursor: pointer;
}
#header {
  position: fixed;
  width: 100%;
  transition: top .33s ease-in-out;
  -moz-transition: top .33s ease-in-out;
  -webkit-transition: top .33s ease-in-out;
  z-index: 2;
}
.header-white {
  display: flex;
  background-color: none;
}
.app-container {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}
.navbar-right {
  flex-grow: 1;
  align-items: center;
  justify-content: space-around;
}
#navbar-background {
  position: fixed;
  width: 100%;
  height: 80px;
}
.navbar-background-tall {
  z-index: 3;
  padding: 15px;
  transition: all .25s ease-in-out;
}
.navbar-background-short {
  z-index: 3;
  padding: 15px;
  transition: all .25s ease-in-out;
}
.social-mobile {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}
.carrot-icon {
  margin: 2px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-image: url("./assets/carrot-icon.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.discord {
  margin: 32px;
  width: 42px;
  height: 42px;
  cursor: pointer;
  background-image: url("./assets/discord-128.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.twitter {
  margin: 32px;
  width: 42px;
  height: 42px;
  cursor: pointer;
  background-image: url("./assets/twitter-128.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.linkedin {
  margin: 32px;
  width: 48px;
  height: 48px;
  cursor: pointer;
  background-image: url("./assets/ln-128.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.discord-small {
  margin: 15px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-image: url("./assets/discord-128.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.twitter-small {
  margin: 15px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-image: url("./assets/twitter-128.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.linkedin-small {
  margin: 15px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-image: url("./assets/ln-128.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.desktop-content {
  width: 100%;
  flex-grow: 1;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}
.mobile-content {
  width: 100%;
  flex-grow: 1;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
.nav-tab {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1em;
  color: white;
}
.nav-tab-no-menu {
  position: relative;
  display: inline-block;
  font-size: 1em;
  color: #583644;
}
.nav-tab-no-mobile {
  margin-top: 50px;
  position: relative;
  display: inline-block;
  font-size: 1em;
  font-weight: bold;
  color: #564580;
}
.nav-tab-no-menu:hover {
  cursor: pointer;
}
.nav-menu {
  top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 15%;
}
.menu-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-menu-ten {
  top: 50px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 15%;
}
.nav-menu-container-profile {
  position: absolute;
  top: 60px;
  width: 200px;
  margin-left: 10px;
  margin-top: 50px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear, margin-top 0.2s ease-in-out;
  z-index: 1;
}
.nav-menu-container-chains {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 50px;
  width: 50px;
  margin-top: 50px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear, margin-top 0.2s ease-in-out;
  z-index: 1;
}
.nav-tab:hover .nav-menu-container-chains {
  visibility: visible;
  margin-top: 0px;
  opacity: 1;
}
.nav-tab:hover .nav-menu-container-profile {
  visibility: visible;
  margin-top: 0px;
  opacity: 1;
}
.nav-item {
  display: block;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  color: #668691;
  border: 1px solid #fff;
  background-color: white;
  border-radius: 10px;
  font-size: 0.9em;
}
.nav-item:hover {
  font-weight: bold;
}
.nav-arrow-container {
  width: 100px;
  margin: 10px;
}
.nav-arrow-left {
  position: absolute;
  width: 0;
  height: 0;
  margin-top: 1px;
  margin-left: 20px;
  border-style: solid;
  border-width: 0 7px 8px 7px;
  border-color: transparent transparent white transparent;
}
.nav-arrow-middle {
  position: absolute;
  width: 0;
  height: 0;
  margin-top: 1px;
  margin-left: 43px;
  border-style: solid;
  border-width: 0 7px 8px 7px;
  border-color: transparent transparent white transparent;
}
.nav-arrow-right {
  position: absolute;
  width: 0;
  height: 0;
  margin-top: 1px;
  margin-left: 70px;
  border-style: solid;
  border-width: 0 7px 8px 7px;
  border-color: transparent transparent white transparent;
}
.navbar-search-container {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin: 10px;
  height: 60px;
  border-radius: 30px;
  width: 500px;
  min-width: 250px;
  background: #00000014;
}
.display-off {
  display: none;
}
.display-on {
  display: block;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.footer {
  display: flex;
  flex-direction: row;
  height: 300px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  color: white;
}
.search-select {
  border: 2px solid;
}
.copyright {
  margin: 10px;
}
.sidebar {
}
.footerlinks {
  margin: 32px;
  color: #5e6b8e;
}
.footercontent {
  align-items: center;
}
.copyright {
  margin: 10px;
  color: #6e6e6e;
}
.footerlink {
  margin: 10px;
  color: #6e6e6e;
  cursor: pointer;
}
.sidebar-panel-nav {
  padding-left: 30px;
  list-style-type: none;
  text-align: left;
  cursor: default;
}
.nav-h2 {
  cursor: pointer;
  color: #fdfdfd;
  font-size: 1em;
  cursor: default;
}
.nav-circle-icon {
  display: flex;
  cursor: pointer;
  width: 22px;
  height: 22px;
  background: white;
  border-radius: 50%;
  padding: 5px;
  margin-top: 5px;
  border: 2px solid #fff;
}
.chain-icon {
  width: 22px;
  height: 26px;
}
.chain-icon-container {
  display: flex;
  height: 50px;
  justify-content: center;
}
.wallet-advice {
  color: #8f81bd;
  font-size: 0.8em;
  text-align: left;
  margin-top: 20px;
}
.submit-button {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
  width: fit-content;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: white;
  height: 50px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 15px 20px 2px rgb(139 106 224 / 33%);
  cursor: pointer;
  background: linear-gradient(338deg,#ff7c88,#ff46a4)
}
.change-chain-modal {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  overflow: hidden;
}
.change-chain-modal-icon {
  width: 48px;
  height: 48px;
}
.navbar-item-light {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
  color: black;
}
.navbar-item-dark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
  color: white;
}
.desktop-compact {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.desktop-expand-item {
  margin: 20px;
  align-items: center;
}
.expand-text-title {
  margin-left: 10px;
  font-size: 1em;
  margin-left: 10px;
  font-weight: bold;
  color: #000;
  text-align: left;
}
.expand-text {
  margin-left: 10px;
  color: #808191;
  font-size: 1em;
  margin-left: 10px;
  font-weight: bold;
  text-align: left;
}
.compact-icon {
  display: flex;
  width: 48px;
  height: 48px;
  margin: 10px;
}
.expand-icon {
  resize: 'horizontal';
  overflow: 'hidden';
  width: '100px';
  height: 'auto';
}
.sidebar-panel-nav > li > a {
  color: #fff;
  text-decoration: none;
  text-align: left;
  font-size: 1.5rem;
  display: block;
  padding-bottom: 0.5em;
  cursor: default;
}
.top-bar-flex {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.top-bar-icon {
  margin-right: 10px;
  margin-left: 20px;
}
.top-bar-icon-op {
  margin-right: 20px;
  margin-left: 10px;
}
.connect-img-container {
  color: #333333;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  flex-grow: 1;
}
.wallet-text-desktop {
  font-size: 0.9em;
  color: #FFF;
  padding: 10px;
}
.wallet-text-desktop:hover {
  text-decoratiton: underline;
}
.wallet-text-mobile {
  font-size: 0.8em;
  color: #FFF;
  padding: 10px;
}
.wallet-text-mobile:hover {
  text-decoratiton: underline;
}
img.connect-img {
  width: 50px;
  height: 50px;
}
.connect-wallets {
  border: 2px solid #a046ff;
  justify-content: center;
  border-radius: 10px;
  font-size: 1em;
  padding: 20px;
}
.connect-text {
  position: absolute;
  margin: -17px 0px 0px 10px;
  padding: 10px;
  font-size: .8em;
  color: #a046ff;
  background-color: #333333;
}
.or-login {
  margin: 20px;
  color: #a046ff;
  font-size: 1em;
  font-weight: bold;
}
body {
  position: relative;
  width: 100%;
  margin: 0px;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  background-color: #F8FAFF;
  font-size: 1em;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.animate-spin {
  animation: spin 1s linear infinite;
}
.route-enter-from {
  opacity: 0;
  transform: translateY(100px);
}
.route-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}
.route-enter-active {
  transition: all 0.2s ease-out;
}
.route-leave-active {
  transition: all 0.2s ease-in;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.nav-icon {
  cursor: pointer;
}
.helper-video-container {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: end;
  border: 2px solid #fff;
  border-radius: 50%;
  margin: 20px;
  width: 100px;
  height: 100px;
  right: 0px;
  bottom: 0px;
  box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 33%);
  overflow: hidden;
  cursor: pointer;
  background: white;
  transition: all 0.5s ease-out;
  animation: HoverEffect 2s linear infinite;
  z-index: 10;
}
.helper-video-container:hover {
  border-radius: 10%;
}
.helper-video-container-max {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 100px;
  left: 0;
  z-index: 100;
  border-radius: 0;
  border: 2px solid #fff;
  margin: 20px;
  box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 33%);
  background: white;
  transition: all 0.5s ease-out;
}
.helper-video-container-gone {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: end;
  border: 2px solid #fff;
  border-radius: 50%;
  margin: 20px;
  width: 100px;
  height: 100px;
  right: 0px;
  bottom: 0px;
  bottom: -120px;
  box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 33%);
  overflow: hidden;
  cursor: pointer;
  background: white;
  transition: all 0.5s ease-out;
  z-index: 10;
}
.helper-video-container-gone:hover {
  bottom: 0px;
}
@keyframes HoverEffect {
  50% {
    transform: skewY(-2deg);
    bottom: 12px;
  }
}
.video-helper {
  background: white;
  width: 25px;
  height: 25px;
  object-fit: cover;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}
.video-helper-max {
  object-fit: cover;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
.close-video {
  margin-right: 5px;
  margin-top: 2px;
  background: white;
  font-size: 1em;
  font-weight: bold;
  color: red;
  z-index: 10;
  position: absolute;
}
.close-video-max {
  position: absolute;
  dsplay: flex;
  justify-conent: center;
  margin-right: 5px;
  margin-top: 5px;
  background: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  font-size: 2em;
  font-weight: bold;
  color: red;
  z-index: 22;
}
.lineitem-enter-active, .lineitem-leave-active {
	transition: all 0.3s ease-in-out;
}
/* delay leave of parent element */
.lineitem-leave-active {
  transition-delay: 0.25s;
}

.lineitem-enter-from,
.lineitem-leave-to {
  transform: translateX(50px);
  opacity: 0;
}

.app-background-container {
  position: relative;
}
.app-footer {
  margin-top: auto;
}
.app-flex {
  min-height: calc(100vh + 300px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pointer {
  cursor: pointer;
}
.menu-items {
  display: flex;
  position: relative;
  margin-bottom: 5px;
}
.menu-item {
  margin-left: 10px;
}
.menu-item:hover {
  text-decoration-color: #46b6ff;
  text-decoration: underline;
  cursor: pointer;
}
.bg-mask {
  position: absolute;
  top: 600px;
  left: 10px;
}
.bg-mask-rect-outer {
  position: absolute;
  width: 122px;
  height: 115px;
  top: 1px;
  border-radius: 12%;
  transform: matrix(.71,-.75,.67,.71,0,0);
  overflow: hidden;
  transform: rotate(323deg);
  background: linear-gradient(338deg, #f5dc51, #57c12b);
  z-index: -1;
}
.bg-mask-rect-inner {
  position: absolute;
  width: 1022px;
  height: 1015px;
  top: 1px;
  margin-left: 100px;
  margin-top: 100px;
  border-radius: 12%;
  background: white;
  overflow: hidden;
  z-index: -1;
}
.bg-mask-exchange {
  position: absolute;
  right: 35%;
  top: -600px;
}
.bg-mask-exchange-outer {
  position: absolute;
  width: 1024px;
  height: 1024px;
  top: 1px;
  margin-left: 100px;
  border-radius: 35%;
  transform: matrix(.71,-.75,.67,.71,0,0);
  overflow: hidden;
  transform: rotate(323deg);
  background: linear-gradient(338deg,#ff7cf5,#46b6ff);
  z-index: -1;
  transition:all 1s ease-out;
  -webkit-animation:spin 10s linear infinite;
  -moz-animation:spin 10s linear infinite;
  animation:spin 300s linear infinite;
}
.bg-mask-exchange-inner {
  position: absolute;
  width: 720px;
  height: 720px;
  top: 1px;
  margin-left: 100px;
  margin-top: 100px;
  border-radius: 35%;
  background: white;
  overflow: hidden;
  z-index: -1;
}
.bg-mask-exchange-inner-inner {
  position: absolute;
  width: 640px;
  height: 640px;
  top: 1px;
  margin-left: 100px;
  margin-top: 100px;
  border-radius: 50%;
  background: white;
  overflow: hidden;
  z-index: -1;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(90deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(90deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(90deg); 
        transform:rotate(90deg); 
    } 
}
.fill {
  width: 100%;
}
.uchan-logo {
  cursor: pointer;
  width: 100px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("./assets/thumpr256.png");
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-end {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.profile-img-small {
  width: 28px;
  height: 28px;
}
.profile-img {
  width: 100%;
}
.menu-container {
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  justify-content: space-between;
  align-items: start;
}
.create-button {
    display: flex;
    height: 15px;
    padding: 10px;
    margin: 10px;
    background: linear-gradient(145deg, #f6db76, #ec6914, #ed9467, #ffd72a);
    border: 1px solid #f6c88d;
    border-radius: 8px;
    text-wrap: nowrap;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    font-family: Arial, sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    align-items: center;
    transition: box-shadow 0.3s ease;
}
.create-button-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    padding: 10px;
    margin: 10px;
    background: linear-gradient(145deg, #f6db76, #ec6914, #ed9467, #ffd72a);
    border: 1px solid #f6c88d;
    border-radius: 8px;
    text-wrap: nowrap;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    font-family: Arial, sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    align-items: center;
    transition: box-shadow 0.3s ease;
}

.create-button:hover {
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.3);
}

.create-button:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at top left, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 50%);
    border-radius: 8px;
    pointer-events: none;
    z-index: 1;
}

.create-button:active {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.claimcarrot {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("./assets/claimcarrots.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

.center-banner-desktop {
  position: relative;
  width: 200px;
  height: 100px;
}

.center-banner-mobile {
  margin-top: 100px;
  width: 200px;
  height: 100px;
  position: absolute;
  float: right;
  margin-right: 10px;
  right: 0px;
}
.center-banner-mobile:hover {
  cursor: pointer;
}
.center-banner-desktop:hover {
  cursor: pointer;
}
.orangebutton {
  position: absolute;
  left: 65%;
  top: 40%;
  width: 20px;
  height: 20px;
  background: linear-gradient(#ffb945, #d48002);
  border-radius: 50%;
  transform: translate(-50%, 0) scale(0);
  transition: transform 0.25s ease-out;
}

.center-banner-mobile:hover .orangebutton {
  transform: translate(-50%, 0) scale(10);
}

.center-banner-desktop:hover .orangebutton {
  transform: translate(-50%, 0) scale(10);
}

.pretty-text {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 18px;
  line-height: 1.6;
  color: #333;
  margin: 20px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}
.accept-button {
  margin: 10px;
  border-radius: 10px;
  border: 1px solid #efefef;
  background: linear-gradient(145deg, #c4ae5e, #f18742, #ed9467, #edd17e);
  text-align: center;
  font-weight: 700;
  line-height: 35px;
  color: #fff;
  font-size: .9em;
  cursor: pointer;
}
.decline-button {
  margin: 10px;
  border-radius: 10px;
  border: 1px solid #efefef;
  background-color: #a4a4a4;
  text-align: center;
  font-weight: 700;
  line-height: 35px;
  color: #fff;
  font-size: .9em;
  cursor: pointer;
}
.header-design {
}
.main-banner-desktop {
  width: 100%;
  height: 200px;
  background-position: center;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: -20px;
  transition: background-image 0.5s ease-in-out;
}
.main-banner-mobile {
  width: 100%;
  height: 111px;
  background-position: center;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: -20px;
  transition: background-image 0.5s ease-in-out;
}
.profile-banner {
  width: 100%;
  height: 200px;
  background-position: center;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: -20px;
}
.banner-text {
  margin: 10px 0px;
  color: white;
  font-size: 2em;
}
.banner-text-mobile {
  margin: 10px 0px;
  color: white;
  font-size: 1.8em;
}
.normal-text {
  margin: 10px 0px;
  color: white;
  font-size: 1.1em;
}
.menu-item-text {
  display: flex;
  cursor: pointer;
  font-size: 0.9em;
  font-family: cursive;
  align-items: flex-end;
}
.menu-icon {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}
.menu-icon-mobile {
  width: 32px;
  height: 32px;
  margin: 10px;
}
.wallet-select-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.top-mobile-border {
  height: 80px;
  width: 100%;
}
.center-carrot {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.show-menu {
  display: flex;
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  height: 50px;
  flex-direction: column;
  justify-content: space-evenly;
  background: white;
  border: 10px;
  padding: 10px;
  width: 50px;
  border-radius: 10px;
}
.profile-menu {
  position: absolute;
  left: 105px;
  margin-top: 65px;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
}
.component-margin {
  min-height: 100vh;
  margin-left: 10px;
  margin-right: 10px;
}
.profile-menu.show-menu {
  opacity: 1;
  transform: translateX(-111px);
  visibility: visible;
}
.profile-menu p {
  background-color: #f1f1f1;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0;
}

.profile-menu p:hover {
  background-color: #e2e2e2;
}

.mid-bottom-mask {
  z-index: 1;
}

svg.dashboard-svg-desktop {
  z-index: -2;
  position: absolute;
  background: radial-gradient(ellipse at 150% 10%, #ffe96f, #ff8145);
  padding-top: 150px;
  left: 0;
}
svg.dashboard-svg-mobile {
  z-index: -2;
  position: absolute;
  background: radial-gradient(ellipse at 150% 10%, #ffe96f, #ff8145);
  padding-top: 450px;
  left: 0;
}
.navbar-search-light {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin: 10px;
  height: 60px;
  border-radius: 30px;
  max-width: 550px;
  min-width: 250px;
  background: #0000000d;
}
.navbar-search-dark {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin: 10px;
  height: 60px;
  border-radius: 30px;
  max-width: 550px;
  min-width: 250px;
  background: #00000040;
}
.flex-col-app-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 15px;
  cursor: pointer;
}
.base-wallet-icon {
  width: 28px;
  height: 28px;
  background-image: url("./assets/coinbase-wallet-small.png");
  background-size: cover;
}
.messages-icon {
  width: 28px;
  height: 28px;
}
.orders-icon {
  width: 28px;
  height: 28px;
}
.flex-row-grow {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.flex-row-grow .uchan-logo {
  cursor: pointer;
  width: 80px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("./assets/thumpr256.png");
}

img.query-result-image {
  width: 38px;
  height: 38px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 20px;
  border-radius: 50%;
}
.query-response-container {
  top: 60px;
  color: black;
  position: absolute;
  margin-left: 10px;
  width: 100%;
  padding: 5px;
  max-width: 480px;
  background: #ffffffb5;
  border-radius: 0px 0px 20px 20px;
  font-size: 0.9em;
}
.query-result {
  display: flex;
  align-items: center;
  background: #ffffffc4;
  transition: all;
  border-radius: 20px;
}
.query-result:hover {
  cursor: pointer;
  background: white;
  transform: all;
}
.query-response-line {
  height: 52px;
}
.query-topic {
  margin-left: 10px;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

</style>
