<template>

  <div :class="orderCardContainer">

    <div
      class="order-service-preview"
      @click="gotoPath('services/' + order.service_id)"
    >
      <img class="order-image" :src="'/'+order.image" />
      <div class="service-preview-title">
        <b>{{ order.title }}</b>
      </div>
    </div>

    <div class="order-profile-container" :alt="order.seller_title">
      <div class="seller-buyer">{{localize('TEXT_SELLER')}}</div>
      <img class="profile-image" :src="'/' + order.seller_image" />
      <div
        class="service-card-profile-name"
        @click="gotoPath('profile/' + order.seller_id)"
      >
        {{ order.seller_name }}
      </div>
      <div
        @click="gotoUrl('https://basescan.org/address/' + order.seller)"
        class="order-address"
      >
        {{sellerAddress}}
      </div>
    </div>

    <div class="order-profile-container" :alt="order.buyer_title">
      <div class="seller-buyer">{{localize('TEXT_BUYER')}}</div>
      <img class="profile-image" :src="'/' + order.buyer_image" />
      <div
        @click="gotoPath('profile/' + order.buyer_id)"
        class="service-card-profile-name"
      >
        {{ order.buyer_name }}
      </div>
      <div
        @click="gotoUrl('https://basescan.org/address/' + order.buyer)"
        class="order-address"
      >
        {{buyerAddress}}
      </div>
    </div>

    <div class="order-description">
      <div class="order-item flex-row">
        {{localize('TEXT_ORDER_NO')}}  <b>{{order.order_id}}</b>
      </div>
      <div class="order-item flex-row">
        <div>{{localize('TEXT_PRICE')}}  </div>
        <div class="price-container">
          <div
            v-html="getTokenIcon(order.token)"
            class="token-icon"
          />
          <div class="token-price-container">
            <div class="token-price">{{price}}</div> <div class="token-dollar-price">${{dollarPrice}}</div>
          </div>
        </div>
      </div>
      <div class="order-item flex-row">
        {{localize('TEXT_DELIVERY')}}  <b>{{deliveryDate}}</b>
      </div>
      <div class="order-item flex-row">
        <div>{{localize('TEXT_CHAIN')}}  </div>
        <div class="chains">
          <div
            v-html="getChainIcon(order.chain_id)"
            class="token-icon"
          />
          <div>
            <b>Base</b>
          </div>
        </div>
      </div>
    </div>

    <div class="order-action">
      <div
        class="order-open-button"
        @click="openOrder(order.order_id)"
      >
        <b>{{localize('TEXT_OPEN')}}</b>
      </div>
      <div class="flex-col-center">
        <div class="order-status">
          <b>{{orderStatus_}}</b>  &nbsp; &nbsp;  $ {{dollarPrice}}
        </div>
        <div
          v-if="order.status_ == 0"
          class="flex-row"
        >
          <div v-if="order.seller == userInfo.accounts[0]"
            @click="acceptOrder"
            class="order-status-accept"
          >
            {{localize('TEXT_ACCEPT')}}
          </div>
          <div v-if="order.seller == userInfo.accounts[0]"
            @click="rejectOrder"
            class="order-status-reject"
          >
            {{localize('TEXT_REJECT')}}
          </div>
          <div v-if="order.buyer == userInfo.accounts[0]"
            @click="cancelOrder"
            class="order-status-cancel"
          >
            {{localize('TEXT_CANCEL')}}
          </div>
        </div>
        <div
          v-else-if="order.status_ == 1"
          class="flex-row"
        >
          <div v-if="order.seller == userInfo.accounts[0]"
            @click="rejectOrder"
            class="order-status-reject"
          >
            {{localize('TEXT_REJECT')}}
          </div>
          <div v-if="order.seller == userInfo.accounts[0]"
            class="order-status-cancel"
            @click="deliverOrder"
          >
            {{localize('TEXT_DELIVER')}}
          </div>
        </div>
        <div
          v-else-if="order.status_ == 4"
          class="flex-row"
        >
          <div v-if="order.buyer == userInfo.accounts[0]"
            @click="completeOrder"
            class="order-status-accept"
          >
            {{localize('TEXT_COMPLETE')}}
          </div>
          <div v-if="order.buyer == userInfo.accounts[0]"
            class="order-status-dispute"
            @click="showDisputeModal = true"
          >
            {{localize('TEXT_DISPUTE')}}
          </div>
        </div>
        <div
          v-else-if="order.status_ == 6"
          class="flex-row"
        >
          <div v-if="order.seller == userInfo.accounts[0]"
            @click="openDisputeModal"
            class="order-status-dispute"
          >
            {{localize('TEXT_DISPUTED')}}
          </div>
          <div v-if="order.buyer == userInfo.accounts[0]"
            class="order-status-dispute"
            @click="showDisputeUpdateModal = true"
          >
            {{localize('TEXT_DISPUTE')}}
          </div>
        </div>
      </div>
    </div>

    <Transition>
      <CModal
        v-if="showNoEthModal"
        color="#f9b115"
        title="Please Wait"
        @close="showNoEthModal = false"
      >
        <template #header>
          <div> {{ localize('TEXT_NO_ETH') }} </div>
        </template>
        <template #body>
          <div class="dispute-container">
             {{ localize('NO_ETH_1') }}
              <a href="https://wallet.coinbase.com/assets">{{localize('NO_ETH_2')}}</a>
             {{ localize('NO_ETH_3') }}
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

    <Transition>
      <CModal
        v-if="showDisputedModal"
        color="#f9b115"
        title="Please Wait"
        @close="showDisputedModal = false"
      >
        <template #header>
          <div> {{ localize('TEXT_DISPUTE_ORDER') }} </div>
        </template>
        <template #body>
          <div class="dispute-container">
            <div class="dispute-amount">
              <div> {{ localize('RAISE_DISPUTE_MESSAGE_3') }} </div>
              <br />
              <div class="dispute-button" @click="acceptDispute">
                <div>
                  {{localize('TEXT_ACCEPT')}}
                </div>
                <div v-if="disputeAmount" class="dispute-in-amount-subscript">
                  you get {{100-disputeAmount}}% &nbsp; $ {{ price - (price / 100) * disputeAmount}} &nbsp; 
                  *platform carges apply
                </div>
              </div>
              <div class="dispute-in-amount-subscript">
                Disputes incur extra platform fee's, up to 20%
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

    <Transition>
      <CModal
        v-if="showDisputeModal"
        color="#f9b115"
        title="Please Wait"
        @close="showDisputeModal = false"
      >
        <template #header>
          <div> {{ localize('TEXT_DISPUTE_ORDER') }} </div>
        </template>
        <template #body>
          <div class="dispute-container">
            <div class="dispute-amount">
              <div> {{ localize('RAISE_DISPUTE_MESSAGE_1') }} </div>
              <br />
              <div> {{ localize('RAISE_DISPUTE_MESSAGE_2') }} </div>
              <div class="dispute-percentage-container">
                <div class="percentage-button" @click="disputeAmount = 25">
                    25%
                </div>
                <div class="percentage-button" @click="disputeAmount = 50">
                    50%
                </div>
                <div class="percentage-button" @click="disputeAmount = 75">
                    75%
                </div>
                <div class="percentage-button" @click="disputeAmount = 100">
                    100%
                </div>
              </div>
              <br />
              <div class="dispute-button" @click="disputeOrder">
                <div class="flex-row">
                  <div>
                    {{ localize('TEXT_DISPUTE_ORDER_FOR') }}
                  </div>
                  <div v-if="disputeAmount">
                    &nbsp; {{disputeAmount}} %
                  </div>
                </div>
                <div v-if="disputeAmount" class="dispute-in-amount-subscript">
                  you receive back $ {{(price / 100) * disputeAmount}} &nbsp; 
                  *platform carges apply
                </div>
              </div>
              <div class="dispute-in-amount-subscript">
                Disputes incur extra platform fee's, up to 20%
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

    <Transition>
      <CModal
        v-if="showDisputeUpdateModal"
        color="#f9b115"
        title="Please Wait"
        @close="showDisputeUpdateModal = false"
      >
        <template #header>
          <div> {{ localize('TEXT_UPDATE_DISPUTE') }} </div>
        </template>
        <template #body>
          <div class="dispute-container">
            <div class="dispute-amount">
              <div v-if="order.buyer == userInfo.accounts[0]">
                <div> {{ localize('RAISE_DISPUTE_MESSAGE_4') }} </div>
                <div class="dispute-percentage-container">
                  <div class="percentage-button" @click="disputeAmount = 25">
                      25%
                  </div>
                  <div class="percentage-button" @click="disputeAmount = 50">
                      50%
                  </div>
                  <div class="percentage-button" @click="disputeAmount = 75">
                      75%
                  </div>
                  <div class="percentage-button" @click="disputeAmount = 100">
                      100%
                  </div>
                </div>
                <div class="dispute-button" @click="updateDispute">
                  <div class="flex-row">
                    <div>
                      {{ localize('TEXT_DISPUTE_ORDER_FOR') }}
                    </div>
                    <br />
                    <div v-if="disputeAmount">
                      &nbsp; {{disputeAmount}} %
                    </div>
                    <br />
                  </div>
                  <div v-if="disputeAmount" class="dispute-in-amount-subscript">
                    you receive back $ {{(price / 100) * disputeAmount}} &nbsp; 
                    *platform carges apply
                  </div>
                </div>
                <div class="dispute-in-amount-subscript">
                  Disputes incur extra platform fee's, up to 20%
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

  </div>

</template>

<script>

import {iconStore} from "@/stores/icon";
import {translationStore} from "@/stores/translation";
import {inject, onMounted, reactive, ref, toRaw, toRefs, unref, watch, watchEffect, defineProps} from "vue";
import {orderBookABI} from "../abi.js";
import Axios from "axios";

export default {
    "name": "ServiceCard",
    "components": {
    },
    "props": {
        "order": Object
    },
    "emits": ['reload-orders'],
    setup (props, {emit}) {

        const translation = translationStore(),
            router = inject("router"),
            userInfo = inject("userInfo"),
            web3 = inject("web3"),
            profile = ref(props.profile),
            layout = ref(props.layout),
            orderCardContainer = ref('order-card-container-horizontal'),
            price = ref(),
            dollarPrice = ref(),
            order = ref(props.order),
            orderStr = ref(""),
            orderStatus_ = ref(""),
            orderStatus = ref([
              "Proposed", // requested
              "Accepted", // open
              "Cancelled", // completed
              "Rejected", // completed
              "Delivered", // open
              "Completed", // completed
              "Disputed" // disputed
            ]),
            disputeAmount = ref(),
            orderMedia = reactive(props.orderMedia),
            deliveryDate = ref(),
            sellerAddress = ref(),
            buyerAddress = ref(),
            serverConfig = toRefs(inject("serverConfig")),
            showNoEthModal = ref(false),
            showDisputedModal = ref(false),
            showDisputeModal = ref(false),
            showDisputeUpdateModal = ref(false),
            icon = iconStore(),
            chains = ref({
                "8453":{
                    icon: "BASE_LOGO_LIGHT",
                    symbol: "BASE",
                    name: "Base",
                    chainId: "8453"
                }
            }),
            tokens = ref({
                "0xfde4C96c8593536E31F229EA8f37b2ADa2699bb2":{
                    icon: "USDT_LOGO_LIGHT",
                    symbol: "USDT",
                    name: "Tether",
                    address: "0xfde4C96c8593536E31F229EA8f37b2ADa2699bb2",
                    chain: "Base",
                    chainId: "8453",
                    balance: 0,
                    dollarBalance: 0,
                    price: 1
                },
                "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913":{
                    icon: "USDC_LOGO_LIGHT",
                    symbol: "USDC",
                    name: "Circle",
                    address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
                    chain: "Base",
                    chainId: "8453",
                    balance: 0,
                    dollarBalance: 0,
                    price: 1
                },
                "0":{
                    icon: "ETHEREUM_LIGHT",
                    symbol: "ETH",
                    name: "Ethereum",
                    address: "0", // 0 for native
                    chain: "Base",
                    chainId: "8453",
                    balance: 0,
                    dollarBalance: 0,
                    price: 1
                }
            }),
            getPrice = function () {

                if (order.value.token != 0) {

                    price.value = order.value.price / (10**6);
                    dollarPrice.value = order.value.price / (10**6);

                } else {

                    price.value = order.value.price;

                    Axios.get(
                        "https://price-api.crypto.com/price/v1/exchange/ethereum",
                        {

                            "params": {

                            }

                        }
                    ).
                        then((response) => {

                            if (response.data) {

                                dollarPrice.value = order.value.price / (10**6);

                            }

                        });

                }

            },
            getTokenIcon = function (i) {

                i = tokens.value[i].icon;

                return icon.get(i);

            },
            getChainIcon = function (i) {

                i = chains.value[i].icon;

                return icon.get(i);

            },
            getAccount = async function () {

                try {

                    const accounts = await web3.value.getAccounts();
                    if (accounts) {

                        return accounts[0];

                    } else {

                        gotoPath('/login');

                    }

                } catch (e) {

                    console.log(e)
                    gotoPath('/login');

                }

/*
                const acc = await web3.value.eth.getAccounts();
                if (acc.length > 0) {

                    return acc[0];

                } else if (window.eth) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"});
                    return accounts[0];

                }

                console.log("window.open(https://metamask.io");
                window.open("https://metamask.io");
*/
            },
            // this function is similar to openChat in the profile/messages component
            openOrder = function() {

                const params = {

                    orderId: order.value.order_id,
                    users: [order.value.buyer_id, order.value.seller_id]

                }

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}openChat`,
                    {

                        params

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                              if (response.data.roomId) {

                                  gotoPath('orders/' + order.value.order_id + '/' + response.data.roomId);

                              }

                        }

                })

            },
            acceptOrder = async function () {

                console.log('accept order')

                const addr = await getAccount(),
                    chainId = await web3.value.eth.getChainId(),
                    obc = new web3.value.eth.Contract( // order book contract
                        orderBookABI,
                        serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value
                    );

                const balance = await web3.value.eth.getBalance(addr);
                const balanceInEther = web3.value.utils.fromWei(balance, 'ether');

                console.log("balanceInEther")
                console.log(balanceInEther)
                if (balanceInEther == 0) {

                    showNoEthModal.value = true;

                }

                // make sure that this is a valid contract (prevent spend to non contract)
                const code = await web3.value.eth.getCode(serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value);
                if (code === "0x") {

                    console.log("Invalid Orderbook Contract")
                    return;

                }

                // first run to estimate gas
                let gasLimit = await obc.methods.acceptOrder(
                    order.value.order_id
                ).estimateGas(
                    {
                        "from": addr
                    },
                    (error, estimatedGas) => {
                        console.log("error in estimategas")
                        console.log(error)
                    }
                );

                // get a more accurate gas limit
                gasLimit = gasLimit <= 50000
                    ? gasLimit * 3
                    : gasLimit * 1.2;
                let gasPrice = await web3.value.eth.getGasPrice();

                gasLimit = Math.floor(gasLimit);
                gasPrice = BigInt(gasPrice) * 12n / 10n;
                gasPrice = web3.value.utils.toHex(Number(gasPrice));

                await obc.methods.acceptOrder(
                    order.value.order_id
                ).send({
                    "from": addr,
                    gasPrice,
                    gasLimit
                }).
                    then((jsonRpcResult) => {

                        console.log("jsonRpcResult")
                        console.log(jsonRpcResult)

                        setTimeout(()=>{

                            console.log("jsonRpcResult")
                            console.log(jsonRpcResult)

                            reloadShort();
                            openOrder();

                        }, 300);

                });

            },
            rejectOrder = async function () {

                const addr = await getAccount(),
                    chainId = await web3.value.eth.getChainId(),
                    obc = new web3.value.eth.Contract( // order book contract
                        orderBookABI,
                        serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value
                    );

                // make sure that this is a valid contract (prevent spend to non contract)
                const code = await web3.value.eth.getCode(serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value);
                if (code === "0x") {

                    console.log("Invalid Orderbook Contract")
                    return;

                }

                // first run to estimate gas
                let gasLimit = await obc.methods.rejectOrder(
                    order.value.order_id
                ).estimateGas(
                    {
                        "from": addr
                    },
                    (error, estimatedGas) => {
                        console.log("error in estimategas")
                        console.log(error)
                    }
                );

                // get a more accurate gas limit
                gasLimit = gasLimit <= 50000
                    ? gasLimit * 3
                    : gasLimit * 1.2;
                let gasPrice = await web3.value.eth.getGasPrice();

                gasLimit = Math.floor(gasLimit);
                gasPrice = BigInt(gasPrice) * 12n / 10n;
                gasPrice = web3.value.utils.toHex(Number(gasPrice));

                await obc.methods.rejectOrder(
                    order.value.order_id
                ).send({
                    "from": addr,
                    gasPrice,
                    gasLimit
                }).
                    then((jsonRpcResult) => {

                        console.log("jsonRpcResult")
                        console.log(jsonRpcResult)

                        setTimeout(()=>{

                            console.log("jsonRpcResult")
                            console.log(jsonRpcResult)

                            reloadShort();

                        }, 300);

                });

            },
            cancelOrder = async function () {

                const addr = await getAccount(),
                    chainId = await web3.value.eth.getChainId(),
                    obc = new web3.value.eth.Contract( // order book contract
                        orderBookABI,
                        serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value
                    );

                // make sure that this is a valid contract (prevent spend to non contract)
                const code = await web3.value.eth.getCode(serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value);
                if (code === "0x") {

                    console.log("Invalid Orderbook Contract")
                    return;

                }

                // first run to estimate gas
                let gasLimit = await obc.methods.cancelOrder(
                    order.value.order_id
                ).estimateGas(
                    {
                        "from": addr
                    },
                    (error, estimatedGas) => {
                        console.log("error in estimategas")
                        console.log(error)
                    }
                );

                // get a more accurate gas limit
                gasLimit = gasLimit <= 50000
                    ? gasLimit * 3
                    : gasLimit * 1.2;
                let gasPrice = await web3.value.eth.getGasPrice();

                gasLimit = Math.floor(gasLimit);
                gasPrice = BigInt(gasPrice) * 12n / 10n;
                gasPrice = web3.value.utils.toHex(Number(gasPrice));

                await obc.methods.cancelOrder(
                    order.value.order_id
                ).send({
                    "from": addr,
                    gasPrice,
                    gasLimit
                }).
                    then((jsonRpcResult) => {

                        setTimeout(()=>{

                            console.log("jsonRpcResult")
                            console.log(jsonRpcResult)

                            reloadShort();

                        }, 300);

                });

            },
            deliverOrder = async function () {

                const addr = await getAccount(),
                    chainId = await web3.value.eth.getChainId(),
                    obc = new web3.value.eth.Contract( // order book contract
                        orderBookABI,
                        serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value
                    );

                // make sure that this is a valid contract (prevent spend to non contract)
                const code = await web3.value.eth.getCode(serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value);
                if (code === "0x") {

                    console.log("Invalid Orderbook Contract")
                    return;

                }

                // first run to estimate gas
                let gasLimit = await obc.methods.deliverOrder(
                    order.value.order_id
                ).estimateGas(
                    {
                        "from": addr
                    },
                    (error, estimatedGas) => {
                        console.log("error in estimategas")
                        console.log(error)
                    }
                );

                // get a more accurate gas limit
                gasLimit = gasLimit <= 50000
                    ? gasLimit * 3
                    : gasLimit * 1.2;
                let gasPrice = await web3.value.eth.getGasPrice();

                gasLimit = Math.floor(gasLimit);
                gasPrice = BigInt(gasPrice) * 12n / 10n;
                gasPrice = web3.value.utils.toHex(Number(gasPrice));

                await obc.methods.deliverOrder(
                    order.value.order_id
                ).send({
                    "from": addr,
                    gasPrice,
                    gasLimit
                }).
                    then((jsonRpcResult) => {

                        console.log("jsonRpcResult")
                        console.log(jsonRpcResult)

                        setTimeout(()=>{

                            console.log("jsonRpcResult")
                            console.log(jsonRpcResult)

                            reloadShort();

                        }, 300);

                });

            },
            completeOrder = async function () {

                const addr = await getAccount(),
                    chainId = await web3.value.eth.getChainId(),
                    obc = new web3.value.eth.Contract( // order book contract
                        orderBookABI,
                        serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value
                    );

                // make sure that this is a valid contract (prevent spend to non contract)
                const code = await web3.value.eth.getCode(serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value);
                if (code === "0x") {

                    console.log("Invalid Orderbook Contract")
                    return;

                }

                // first run to estimate gas
                let gasLimit = await obc.methods.completeOrder(
                    order.value.order_id
                ).estimateGas(
                    {
                        "from": addr
                    },
                    (error, estimatedGas) => {
                        console.log("error in estimategas")
                        console.log(error)
                    }
                );

                // get a more accurate gas limit
                gasLimit = gasLimit <= 50000
                    ? gasLimit * 3
                    : gasLimit * 1.2;
                let gasPrice = await web3.value.eth.getGasPrice();

                gasLimit = Math.floor(gasLimit);
                gasPrice = BigInt(gasPrice) * 12n / 10n;
                gasPrice = web3.value.utils.toHex(Number(gasPrice));

                await obc.methods.completeOrder(
                    order.value.order_id
                ).send({
                    "from": addr,
                    gasPrice,
                    gasLimit
                }).
                    then((jsonRpcResult) => {

                        console.log("jsonRpcResult")
                        console.log(jsonRpcResult)

                        setTimeout(()=>{

                            console.log("jsonRpcResult")
                            console.log(jsonRpcResult)

                            reloadShort();

                        }, 300);


                });

            },
            openDisputeModal = function() {

                showDisputedModal.value = true

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getDispute`,
                    {

                        "params": {

                            orderId: order.value.order_id,

                        }

                    }
                ).
                    then((response) => {

                        if (response.data && response.status == 200) {

                            disputeAmount.value = response.data.amount/100;

                        }

                    });

            },
            disputeOrder = async function () {

                var da = 0;

                if (disputeAmount.value <= 1 || disputeAmount.value > 100) {

                    return;

                }

                da = disputeAmount.value * 100;

                const addr = await getAccount(),
                    chainId = await web3.value.eth.getChainId(),
                    obc = new web3.value.eth.Contract( // order book contract
                        orderBookABI,
                        serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value
                    );

                // make sure that this is a valid contract (prevent spend to non contract)
                const code = await web3.value.eth.getCode(serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value);
                if (code === "0x") {

                    console.log("Invalid Orderbook Contract")
                    return;

                }

                // first run to estimate gas
                let gasLimit = await obc.methods.disputeOrder(
                    order.value.order_id,
                    da
                ).estimateGas(
                    {
                        "from": addr
                    },
                    (error, estimatedGas) => {
                        console.log("error in estimategas")
                        console.log(error)
                    }
                );

                // get a more accurate gas limit
                gasLimit = gasLimit <= 50000
                    ? gasLimit * 3
                    : gasLimit * 1.2;
                let gasPrice = await web3.value.eth.getGasPrice();

                gasLimit = Math.floor(gasLimit);
                gasPrice = BigInt(gasPrice) * 12n / 10n;
                gasPrice = web3.value.utils.toHex(Number(gasPrice));

                await obc.methods.disputeOrder(
                    order.value.order_id,
                    da
                ).send({
                    "from": addr,
                    gasPrice,
                    gasLimit
                }).
                    then((jsonRpcResult) => {

                        console.log("jsonRpcResult")
                        console.log(jsonRpcResult)

                        setTimeout(()=>{

                            console.log("jsonRpcResult")
                            console.log(jsonRpcResult)

                            reloadShort();
                            showDisputeModal.value = false;

                        }, 300);

                });

            },
            updateDispute = async function () {

                var da = 0;

                if (disputeAmount.value <= 1 || disputeAmount.value > 100) {

                    return;

                }

                da = disputeAmount.value * 100;

                const addr = await getAccount(),
                    chainId = await web3.value.eth.getChainId(),
                    obc = new web3.value.eth.Contract( // order book contract
                        orderBookABI,
                        serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value
                    );

                // make sure that this is a valid contract (prevent spend to non contract)
                const code = await web3.value.eth.getCode(serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value);
                if (code === "0x") {

                    console.log("Invalid Orderbook Contract")
                    return;

                }

                // first run to estimate gas
                let gasLimit = await obc.methods.updateDispute(
                    order.value.order_id,
                    da
                ).estimateGas(
                    {
                        "from": addr
                    },
                    (error, estimatedGas) => {
                        console.log("error in estimategas")
                        console.log(error)
                    }
                );

                // get a more accurate gas limit
                gasLimit = gasLimit <= 50000
                    ? gasLimit * 3
                    : gasLimit * 1.2;
                let gasPrice = await web3.value.eth.getGasPrice();

                gasLimit = Math.floor(gasLimit);
                gasPrice = BigInt(gasPrice) * 12n / 10n;
                gasPrice = web3.value.utils.toHex(Number(gasPrice));

                await obc.methods.updateDispute(
                    order.value.order_id,
                    da
                ).send({
                    "from": addr,
                    gasPrice,
                    gasLimit
                }).
                    then((jsonRpcResult) => {

                        console.log("jsonRpcResult")
                        console.log(jsonRpcResult)

                        setTimeout(()=>{

                            console.log("jsonRpcResult")
                            console.log(jsonRpcResult)

                            reloadShort();
                            showDisputeUpdateModal.value = false;

                        }, 300);

                });

            },
            acceptDispute = async function () {

                const addr = await getAccount(),
                    chainId = await web3.value.eth.getChainId(),
                    obc = new web3.value.eth.Contract( // order book contract
                        orderBookABI,
                        serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value
                    );

                // make sure that this is a valid contract (prevent spend to non contract)
                const code = await web3.value.eth.getCode(serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value);
                if (code === "0x") {

                    console.log("Invalid Orderbook Contract")
                    return;

                }

                // first run to estimate gas
                let gasLimit = await obc.methods.acceptDispute(
                    order.value.order_id
                ).estimateGas(
                    {
                        "from": addr
                    },
                    (error, estimatedGas) => {
                        console.log("error in estimategas")
                        console.log(error)
                    }
                );

                // get a more accurate gas limit
                gasLimit = gasLimit <= 50000
                    ? gasLimit * 3
                    : gasLimit * 1.2;
                let gasPrice = await web3.value.eth.getGasPrice();

                gasLimit = Math.floor(gasLimit);
                gasPrice = BigInt(gasPrice) * 12n / 10n;
                gasPrice = web3.value.utils.toHex(Number(gasPrice));

                await obc.methods.acceptDispute(
                    order.value.order_id
                ).send({
                    "from": addr,
                    gasPrice,
                    gasLimit
                }).
                    then((jsonRpcResult) => {

                        console.log("jsonRpcResult")
                        console.log(jsonRpcResult)

                        setTimeout(()=>{

                            reloadShort();
                            showDisputedModal.value = false;

                        }, 300);

                });

            },
            genRandStr = function () {

                return (new Date().getSeconds() * Math.random()).toString(36).replace(
                    ".",
                    ""
                );

            },
            getURL = function (endpoint) {

                console.log(`${process.env.VUE_APP_SERVER_URI + endpoint}`);

                return `${process.env.VUE_APP_SERVER_URI + endpoint}`;

            },
            gotoPath = function (t) {

                router.push({"path": `/${t}`}).catch((err) => {

                    throw err;

                });

            },
            reloadShort = function (i) {

                const duration = 15000;
                const interval = 3000;
                const endTime = Date.now() + duration;

                const intervalId = setInterval(() => {
                    if (Date.now() >= endTime) {
                        clearInterval(intervalId);
                    } else {
                        emit('reload-orders');
                    }
                }, interval);

            },
            gotoUrl = function (url) {

                window.open(url);

            },
            updateView = function (i) {

                setTimeout(

                    () => {

                        const b = document.getElementById(`${orderStr.value}-${order.value.id}-${i}`);

                        if (orderMedia.length === 0) {
                            
                            return;

                        }

                        if (b) {

                            if (orderMedia[i].media_filename_preview) {

                                b.style.backgroundImage =
                                    `url('${process.env.VUE_APP_SERVER_URI}` + orderMedia[i].media_filename_preview + "')";

                            } else {

                                b.style.backgroundImage =
                                    `url('${process.env.VUE_APP_SERVER_URI}` + orderMedia[i].media_filename + "')";

                            }

                        }

                    },
                    300

                );

                if (serverConfig.view === "desktop") {


                } else if (serverConfig.view === "tablet") {

                } else {

                }
            };

        onMounted(() => {

            sellerAddress.value =
              `${order.value.seller.slice(0, 6)}...${order.value.seller.slice(-4)}`;
            buyerAddress.value =
              `${order.value.buyer.slice(0, 6)}...${order.value.buyer.slice(-4)}`;
            deliveryDate.value = order.value.delivery_timestamp == 0 ? translation.localize('TEXT_PENDING') : order.value.delivery_timestamp;
            orderStatus_.value = orderStatus.value[order.value.status_];

            if (props.layout == 'vertical') {

                orderCardContainer.value = 'order-card-container-vertical';

            } else if (props.layout == 'horizontal') {

                orderCardContainer.value = 'order-card-container-horizontal';

            } else {

                orderCardContainer.value = 'order-card-container-horizontal';

            }

            setTimeout(()=> {

              getPrice();

            },300);
            
        });

        return {"localize": translation.localize,
            web3,
            userInfo,
            showNoEthModal,
            showDisputedModal,
            showDisputeModal,
            showDisputeUpdateModal,
            layout,
            orderCardContainer,
            profile,
            price,
            gotoUrl,
            dollarPrice,
            order,
            orderStr,
            reloadShort,
            openDisputeModal,
            disputeAmount,
            genRandStr,
            gotoPath,
            deliveryDate,
            tokens,
            chains,
            openOrder,
            acceptOrder,
            rejectOrder,
            cancelOrder,
            rejectOrder,
            deliverOrder,
            completeOrder,
            disputeOrder,
            acceptDispute,
            updateDispute,
            getAccount,
            getPrice,
            getTokenIcon,
            getChainIcon,
            orderStatus,
            orderStatus_,
            sellerAddress,
            buyerAddress,
            updateView,
            orderMedia,
            getURL,
            icon};
    }

};

</script>

<style scoped>
.order-card-container-horizontal {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: left;
  flex-wrap: wrap;
}
.order-card-container-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: left;
}
.order-service-preview {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 10px;
  height: 135px;
  width: 200px;
  overflow: hidden;
}
.order-service-preview {
  cursor: pointer;
}
img.order-image {
  width: 192px;
  height: 96px;
  border-radius: 15px;
  object-fit: cover;
}
img.profile-image {
  margin: 5px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}
.service-card-profile-name {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  max-width: 150px;
  text-overflow: ellipsis;
}
.service-card-profile-name:hover {
  cursor: pointer;
  text-decoration: underline;
}
.order-profile-container {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.order-address {
  font-family: monospace;
  font-size: 1rem;
  padding: 0px 5px;
  color: #FFF;
  background: #333;
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.order-address:hover {
  cursor:pointer;
}
.seller-buyer {
  font-weight: bold;
  font-size: 0.9em;
  color: #a4d15e;
}
.seller-buyer:hover {
}
.token-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
.order-description {
  display: flex;
  flex-direction: column;
  border: 1px solid #efefef;
  margin: 10px;
  padding: 10px;
  border-radius: 7px;
  min-width: 150px;
  justify-content: space-between;
}
.order-item {
  display: flex;
  flex-direction: row;
  margin: 3px;
  align-items: center;
  justify-content: space-between;
}
.service-preview-title {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}
.order-action {
  display: flex;
  flex-direction: column;
  margin: 10px;
  min-width: 150px;
  justify-content: space-evenly;
}
.order-open-button {
  text-align: center;
  border: 1px solid #efefef;
  border-radius: 7px;
  color: #777;
  background-color: #fff;
  font-weight: bold;
  padding: 7px;
  margin: 5px;
}
.order-open-button:hover {
  color: #fff;
  background-color: #777;
  cursor: pointer;
}
.order-status-complete {
  text-align: center;
  border: 1px solid #efefef;
  border-radius: 7px;
  color: #fff;
  background-color: #black;
  font-weight: bold;
  padding: 7px;
  margin: 5px;
  cursor: pointer;
}
.order-status-complete:hover {
  color: #fff;
  background-color: #a4d15e;
}
.order-status-accept {
  text-align: center;
  border: 1px solid #efefef;
  border-radius: 7px;
  color: #a4d15e;
  background-color: #fff;
  font-weight: bold;
  padding: 7px;
  margin: 5px;
  cursor: pointer;
  flex-grow: 1;
}
.order-status-accept:hover {
  color: #fff;
  background-color: #a4d15e;
}
.order-status-reject {
  text-align: center;
  border: 1px solid #efefef;
  border-radius: 7px;
  color: #ed9730;
  background-color: #fff;
  font-weight: bold;
  padding: 7px;
  margin: 5px;
  cursor: pointer;
  flex-grow: 1;
}
.order-status-reject:hover {
  color: #fff;
  background-color: #ed9730;
}
.order-status-cancel {
  text-align: center;
  border: 1px solid #efefef;
  border-radius: 7px;
  color: #ed9730;
  background-color: #fff;
  font-weight: bold;
  padding: 7px;
  margin: 5px;
  cursor: pointer;
  flex-grow: 1;
}
.order-status-cancel:hover {
  background-color: #ed9730;
  color: #fff;
}
.order-status-dispute {
  text-align: center;
  border: 1px solid #efefef;
  border-radius: 7px;
  color: red;
  background-color: #fff;
  font-weight: bold;
  padding: 7px;
  margin: 5px;
  cursor: pointer;
  flex-grow: 1;
}
.order-status-dispute:hover {
  background-color: red;
  color: white;
}
.order-status-resolve {
  text-align: center;
  border: 1px solid #efefef;
  border-radius: 7px;
  color: #15bed9;
  background-color: #fff;
  font-weight: bold;
  padding: 7px;
  margin: 5px;
  cursor: pointer;
  flex-grow: 1;
}
.order-status-resolve:hover {
  background-color: #15bed9;
  color: #fff;
}
.order-status-completed {
  border: 1px solid #efefef;
  border-radius: 7px;
  color: #fff;
  background-color: #a4d15e;
  font-weight: bold;
  padding: 7px;
  margin: 5px;
  cursor: pointer;
  flex-grow: 1;
}
.order-status {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  color: #fff;
  background-color: #000;
  font-weight: bold;
  padding: 7px;
  margin: 5px;
  cursor: pointer;
  flex-grow: 1;
}
.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: center;
}
.token-price-container {
  position: relative;
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.token-price {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}
.token-dollar-price {
  position: absolute;
  right: 0;
  margin-top: 20px;
  font-size: 0.77em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chains {
  display: flex;
}
.price-container {
  display: flex;
}
.dispute-amount {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}
.dispute-in-amount-subscript {
  color: #999;
  font-size: 0.75em;
  font-weight: bold;
  margin-right: 14px;
}
.dispute-amount-input {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: none;
  background: inherit;
  font-size: 1.44em;
  color: white;
  max-width: 250px;
  height: 30px;
  margin: 0px 10px;
  color: #777;
}
.dispute-percentage-container {
  display: flex;
  flex-direction: row;
}
.percentage-button {
  border: 1px solid #eee;
  border-radius: 15px;
  padding: 5px;
  color: white;
  margin-right: 3px;
  background: -webkit-repeating-linear-gradient(46deg,#46b6ff,#ff7cf5);
  cursor: pointer;
}
.dispute-button {
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
  border: 1px solid #eee;
  border-radius: 15px;
  padding: 10px;
  color: white;
}
.dispute-button:hover {
  cursor: pointer;
  background-color: white;
  color: black;
}
</style>
