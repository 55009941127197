import Web3 from "web3";
import { WalletConnectModal } from '@walletconnect/modal'
import { createCoinbaseWalletSDK, CoinbaseWalletSDK } from '@coinbase/wallet-sdk';
import { createPublicClient, createWalletClient, custom, http } from 'viem';
import { mainnet,base } from 'viem/chains'

//import { EIP1193Provider } from "@mobile-wallet-protocol/client";

const getWeb3 = (config) => new Promise((resolve) => {

    const selectedProvider = localStorage.getItem("selectedProvider");

    if (selectedProvider === "metamask") {

        console.log("web3.js metamask")
      
        if (window.ethereum && window.ethereum.isMetaMask) {

            const currentWeb3 = new Web3(window.ethereum);

            currentWeb3.getAccounts = currentWeb3.eth.getAccounts;

            const walletClient = createWalletClient({
                transport: custom(window.ethereum)
            });
            currentWeb3.walletClient = walletClient;

            resolve({w3:currentWeb3, ethereum:window.ethereum});

        } else {

    //        window.location.href = "https://metamask.app.link/dapp/www.rareium.io";

        }

    } else if (selectedProvider === "phantom") {

        console.log("web3.js phantom")

        if (window.phantom.ethereum) {

            const currentWeb3 = new Web3(window.phantom.ethereum);
            currentWeb3.getAccounts = currentWeb3.eth.getAccounts;

            const walletClient = createWalletClient({
                transport: custom(window.phantom.ethereum)
            });
            currentWeb3.walletClient = walletClient;

            console.log("phantom currentWeb3")
            resolve({w3:currentWeb3, ethereum:window.phantom.ethereum});

        } else {

            window.location.href = "https://phantom.app/";

        }

    } else if (selectedProvider === "coinbase") {

        console.log("web3.js coinbase")

        const coinbaseWallet = new CoinbaseWalletSDK({
            appName: "Thumpr",
            appChainIds: [8453]
        });

        const sdk = createCoinbaseWalletSDK({
            appName: "Thumpr",
            appLogoUrl: "https://www.thumpr.xyz/public/images/thumpr256.png",
            appChainIds: [8453],
            preference: {
                options: "smartWalletOnly",
                attribution: {
                    auto: true,
                }
            },
        });

        const cbProvider = sdk.getProvider();
        const ethereum = coinbaseWallet.makeWeb3Provider()

        const currentWeb3 = new Web3(cbProvider);

        const walletClient = createWalletClient({
            transport: custom(ethereum)
        });
        currentWeb3.walletClient = walletClient;

        const publicClient = createPublicClient({
            chain: base,
            transport: http()
        })
        currentWeb3.publicClient = publicClient;

        currentWeb3.getAccounts = walletClient.getAddresses;

        (async ()=>{
          const getAccounts = await eth.value
            .request({ method: "eth_requestAccounts" })
            .then((response) => {console.log(response)})
            .catch((err) => {
              console.log(err);
            });
        });

        resolve({w3:currentWeb3, ethereum});

    } else if (selectedProvider === "walletconnect") {

        window.eth = window.ethereum;
        console.log('web3.js walletconnect')

        const wcProvider = new WalletConnectModal({

            projectId: 'YOUR_PROJECT_ID',
            chains: ['eip155:1']


          /*
            "infuraId": config.VUE_APP_INFURA_ID,
            "qrcodeModal": QRCodeModal,
            "bridge": "https://bridge.walletconnect.org",
            "qrcodeModalOptions": {
                "desktopLinks": [
                    "ledger",
                    "coinbase",
                    "metamask"
                ],
                "mobileLinks": [
                    "metamask",
                    "ledger",
                    "coinbase"
                ]

            }
*/
        })

        const walletClient = createWalletClient({
            transport: custom(window.ethereum)
        });

        const currentWeb3 = new Web3(wcProvider);
        currentWeb3.walletClient = walletClient;

        resolve({w3:currentWeb3, ethereum:window.ethereum});

    } else if (window.ethereum) {

        console.log('web3.js using default window.ethereum provider')
        const currentWeb3 = new Web3(window.ethereum);

        const walletClient = createWalletClient({
            transport: custom(window.ethereum)
        });
        currentWeb3.walletClient = walletClient;
        // currentWeb3.providerName = 'browser';

        resolve({w3:currentWeb3, ethereum:window.ethereum});

    } else {

        console.log('web3.js using unknown ethereum provider')
        console.log("config.VUE_APP_PROVIDER");
        console.log(config.VUE_APP_PROVIDER);

        const walletClient = createWalletClient({
            transport: custom(window.ethereum)
        });

        const currentWeb3 = new Web3(new Web3.providers.HttpProvider(config.VUE_APP_PROVIDER));
        currentWeb3.walletClient = walletClient;

        resolve({w3:currentWeb3, ethereum:window.ethereum});

    }

});

export {getWeb3};

