import "./assets/fonts/fonts.css";
import "vue3-swatches/dist/style.css";
import "vue3-carousel/dist/carousel.css";
import 'vue3-emoji-picker/css'
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "@vueup/vue-quill/dist/vue-quill.core.css";
import {SiweMessage} from "siwe";
import {CButton, CDropdown, CDropdownItem, CInput, CModal, CSearchBar, CSwitch, CTextArea} from "./components/celements/celements.ts";
import {ShareNetwork, VueSocialSharing} from "vue-social-sharing";
import {createApp, h, nextTick, onBeforeUnmount, onMounted, provide, reactive, ref, watch} from "vue";
import {createRouter, createWebHistory, useRoute} from "vue-router";
import About from "./components/About.vue";
import Admin from "./components/Admin.vue";
import App from "./App.vue";
import Axios from "axios";
import Collection from "./components/Collection.vue";
import List from "./components/List.vue";
import Search from "./components/Search.vue";
import Creator from "./components/Creator.vue";
import CreateProduct from "./components/CreateProduct.vue";
import ServiceCard from "./components/ServiceCard.vue";
import Services from "./components/Services.vue";
import Orders from "./components/Orders.vue";
import Store from "./components/Store.vue";
import CreatorSignUp from "./components/CreatorSignUp.vue";
import Home from "./components/Home.vue";
import Faq from "./components/Faq.vue";
import NFTCard from "./components/NFTCard.vue";
import NFTLineItem from "./components/NFTLineItem.vue";
import NFTToken from "./components/NFTToken.vue";
import Privacy from "./components/Privacy.vue";
import Profile from "./components/Profile.vue";
import Terms from "./components/Terms.vue";
import Messenger from "./components/Messenger.vue";
import Tokuteishotorihiki from "./components/Tokuteishotorihiki.vue";
import VSwatches from "vue3-swatches";
import VueClickAway from "vue3-click-away";
import {createPinia} from "pinia";
import {getWeb3} from "./web3.js";
import mitt from "mitt";

window.USER_INFO = new Object();

const routes = [
        {"component": Home,
            "name": "home",
            "path": "/"},
        {"component": Profile,
            "name": "profile",
            "path": "/profile/:id?"},
        {"component": Admin,
            "name": "admin",
            "path": "/admin"},
        {"path": "/collection/",
            "children": [
                {
                    "path": ":address",
                    "name": "collection",
                    "component": Collection
                },
                {
                    "path": "/collection/:address/:token_id",
                    "name": "token",
                    "component": NFTToken
                }
            ]},
        //{"component": Services,
       //     "name": "services",
       //     "path": "/services/:category?/:id?"},
        {"component": Services,
            "name": "services",
            "path": "/services/:id?"},
        {"component": Orders,
            "name": "orders",
            "path": "/orders/:id?/:room?"},
        {"component": Search,
            "name": "search",
            "path": "/search/:query"},
        {"component": List,
            "name": "list",
            "path": "/list/:listType?/:id?"},
        {"component": Faq,
            "name": "faq",
            "path": "/faq/:topic?"},
        {"component": CreatorSignUp,
            "name": "creator-sign-up",
            "path": "/creator-sign-up"},
        {"component": Messenger,
            "name": "messenger",
            "path": "/messenger/:room?"},
        {"component": Store,
            "name": "store",
            "path": "/stores/:page"},
        {"component": CreateProduct,
            "name": "create-product",
            "path": "/product/:id?"},
        {"component": Privacy,
            "name": "privacy",
            "path": "/privacy"},
        {"component": Terms,
            "name": "terms",
            "path": "/terms"},
        {"component": About,
            "name": "about",
            "path": "/about"},
        {"component": Tokuteishotorihiki,
            "name": "tokuteishotorihiki",
            "path": "/tokuteishotorihiki"}
    ],

    router = createRouter({
        "history": createWebHistory(),
        "mode": "history",
        scrollBehavior () {

            return {"top": 0};

        },
        routes
    }),

    pinia = createPinia();

createApp({

    /*
     * In vue3 you need to explicity state wheter things are reactive.
     * Since usually we want everything to be reactive we need to add boilder plate on everything
     * Secondly since there is isolation in vue3 we need to provide anything that we want to be
     * Made available in other components (child components will inject these if they want to access)
     */
    setup () {

        document.title = "thumpr.xyz";

        const serverConfig = reactive({
                "view": "",
                "windowWidth": "",
                "visualMode": "light",
                "chainId": "",
                "priceFeed": {
                    "ethPriceUSD": "",
                    "ethPriceYEN": "",
                    "maticPriceUSD": "",
                    "maticPriceYEN": ""
                }
            }),
            userInfo = reactive({
                "accounts": [],
                "authorized": false,
                "clientid": "",
                "color_code": "",
                "defaultview": "",
                "email": "",
                "engagement": "",
                "language": "",
                "networks": [],
                "user_id": "",
                "username": ""
            }),
            eBus = mitt(),
            web3 = ref({}),
            eth = ref({}),
            route = useRoute(),
            profile = ref({}),
            getAccounts = function (callback) {

                if (!web3.value.eth) {

                    return;

                }

                web3.value.eth.getAccounts().
                    then(callback).
                    catch((err) => {

                        if (err.code === 4001) {

                            /*
                             * EIP-1193 userRejectedRequest error
                             * If this happens, the user rejected the connection request.
                             */

                        } else {

                            throw err;

                        }

                    });

            },
            // go to create (list) contents
            requestAccounts = async function () {

                const selectedProvider = localStorage.getItem("selectedProvider");
                if (selectedProvider === "coinbase") {

                    const address = await web3.value.walletClient.requestAddresses();
                    updateAccount(address);

                } else {

                    getAccounts(updateAccount);

                }

            },
            updateAccount = async function (accounts) {

                const selectedProvider = localStorage.getItem("selectedProvider");
                if (accounts.length > 0) {

                    try {

                        const accounts = await eth.value.request({ method: 'eth_accounts' });

                    } catch(e) {

                        console.log(e)

                    }
                    const account = web3.value.utils.toChecksumAddress(accounts[0]);

  //                  if (selectedProvider == "coinbase") {

                        // coinbase smart wallet uses signin with etherum.
                        // implimenting becasue on mobile I'm getting failed auth
                      //

                        const domain = process.env.VUE_APP_DOMAIN;
                        const origin = process.env.VUE_APP_SERVER_URI;
                        const siweMessage = new SiweMessage({
                          domain,
                          address: account,
                          statement: userInfo.engagement,
                          uri: origin,
                          version: '1',
                          chainId: '8453'
                        });
                        const message = siweMessage.prepareMessage();

                        console.log("web3.value")
                        console.log(web3.value)

                        let signature = await web3.value.walletClient.signMessage({
                            account,
                            message
                        })

                        console.log("signature")
                        console.log(signature)

                      /*
                        const buffer = Buffer.from(signature.replace(/^0x/, ''), 'hex');
                        const decoded = buffer.toString('utf8');
                        const metaStart = decoded.indexOf('{');
                        console.log("metaStart " + metaStart);

                        // on mobile or some devices the signature contains extra metadata, like webauthN and veim verify fails ? 
                        // so attempt to strip it out, Signature is 144 hex chars (72 bytes) before JSON
                        if (signature.length > 150 && metaStart > 1) {

                            const sigEnd = metaStart*2 + 2 // two hex chars for one utf8 + 0x
                            const sigStart = sigEnd - 144;
                            console.log(sigStart + " - " + sigEnd);
                            //signature = signature.substring(sigStart, sigEnd);
                            signature = signature.substring(sigStart, sigEnd);
                            signature = `0x${signature}`;
                          console.log("new signature")
                          console.log(signature)

                        }
*/

                        signInAccount(
                            signature,
                            account,
                            message,
                            selectedProvider
                        );

                      /*
                    } else {

                        const accounts = await eth.value.request({ method: 'eth_accounts' });

                        await web3.value.eth.personal.sign(
                            web3.value.utils.utf8ToHex(userInfo.engagement),
                            account,
                            ""
                        ).then((signature) => {

                            signInAccount(
                                signature,
                                account
                            );

                        });
                    }
*/

                } else if (selectedProvider === "metamask") {

                    window.location.href = "https://metamask.app.link/dapp/www.thumpr.xyz/";

                }

            },
            signInAccount = async function (signature, account, message, provider) {

             //   const accounts = await eth.value.request({ method: 'eth_accounts' });
             //   const address = accounts[0];

                let ep = "signIn";
                if (userInfo.authorized === true) {

                    ep = "addAccount";

                }

                Axios.get(

                    process.env.VUE_APP_SERVER_URI + ep,
                    {
                        "params": {
                            account,
                            signature,
                            message,
                            provider
                        }
                    }

                ).
                    then((response) => {

                        if (response.status === 200) {

                            getUserInfo();

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getUserInfo = function () {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getUserInfo`,
                    {
                    }
                ).
                    then((response) => {

                        if (response.data) {

                            for (const i in response.data) {

                                userInfo[i] = response.data[i];

                            }

                        }

                        if (route.query.hl) {

                            userInfo.language = route.query.hl;

                        }

                        if (parseInt(userInfo.user_id)) {

                            getProfile(userInfo.user_id);

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getNetwork = function () {

                web3.value.eth.getChainId().
                    then((chainId) => {

                      console.log("chainId")
                      console.log(chainId)

                        serverConfig.chainId = parseInt(chainId);

                      console.log('attempt to switch chain to ' + chainId)

                        eth.value.request({
                            "method": "wallet_switchEthereumChain",
                            "params": [{"chainId": web3.value.utils.toHex(serverConfig.chainId)}]
                        });

                        setServerConfig(chainId);

                    });

            },
            setNetwork = async function (id) {

                if (!id) {

                    id = localStorage.getItem("selectedChainId");

                    if (!id) {

                        id = '8453';

                    }

                } else {

                    localStorage.setItem(
                        "selectedChainId",
                        id
                    );

                }

                setServerConfig(id);

                const selectedProvider = localStorage.getItem("selectedProvider");

                //if ((selectedProvider === "metamask" ||
                //     selectedProvider === "phantom" ||
                //     selectedProvider === "walletconnect") && window.eth) {
                if (eth.value) {

                    try {
                      console.log('twy to switch ' + id)

                        console.log(web3.value.utils.toHex(id));

                        eth.value.request({
                            "method": "wallet_switchEthereumChain",
                            "params": [{"chainId": web3.value.utils.toHex(parseInt(id))}]
                        });

                    } catch (e) {

                        if (id == "8453") {

                            await eth.value.request({
                                "method": "wallet_addEthereumChain",
                                "params": [
                                    {"chainId": web3.value.utils.toHex("8453"),
                                        "rpcUrls": ["https://mainnet.base.org"],
                                        "chainName": "Base Mainnet",
                                        "nativeCurrency": {
                                            "name": "Base",
                                            "symbol": "ETH",
                                            "decimals": 18
                                        },
                                        "blockExplorerUrls": ["https://basescan.org/"]}
                                ]
                            });

                        } else if (id == "1") {

                            await eth.value.request({
                                "method": "wallet_addEthereumChain",
                                "params": [
                                    {"chainId": web3.value.utils.toHex("1"),
                                        "rpcUrls": ["https://polygon-rpc.com"],
                                        "chainName": "Ethereum Mainnet",
                                        "nativeCurrency": {
                                            "name": "Ethereum",
                                            "symbol": "ETH",
                                            "decimals": 18
                                        },
                                        "blockExplorerUrls": ["https://etherscan.com/"]}
                                ]
                            });

                        }

                    }

                    getNetwork();

                } else {

                    // handled by provider
                    serverConfig.chainId = id;

                }

            },
            setServerConfig = function (chainId) {

                if (chainId == 8453) {

                    serverConfig.VUE_APP_CHAIN_ID = process.env.VUE_APP_BASE_CHAIN_ID;
                    serverConfig.VUE_APP_PROVIDER = process.env.VUE_APP_BASE_PROVIDER;
                    serverConfig.VUE_APP_ORDERBOOK_ADDRESS = process.env.VUE_APP_BASE_ORDERBOOK_ADDRESS;

                } else if (chainId == 80001 || chainId == 137) {

                    serverConfig.VUE_APP_CHAIN_ID = process.env.VUE_APP_MATIC_CHAIN_ID;
                    serverConfig.VUE_APP_PROVIDER = process.env.VUE_APP_MATIC_PROVIDER;
                    serverConfig.VUE_APP_ORDERBOOK_ADDRESS = process.env.VUE_APP_MATIC_ORDERBOOK_ADDRESS;

                } else {

                    serverConfig.VUE_APP_CHAIN_ID = process.env.VUE_APP_BASE_CHAIN_ID;
                    serverConfig.VUE_APP_PROVIDER = process.env.VUE_APP_BASE_PROVIDER;
                    serverConfig.VUE_APP_ORDERBOOK_ADDRESS = process.env.VUE_APP_BASE_ORDERBOOK_ADDRESS;

                }

            },
            getProfile = function (user_id) {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getProfile`,
                    {

                        "params": {

                            userId:user_id

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            if (response.data) {

                                profile.value = response.data;

                            }

                        }

                    });

            },
            getPrice = function () {

                Axios.get(
                    "https://price-api.crypto.com/price/v1/exchange/ethereum",
                    {

                        "params": {

                        }

                    }
                ).
                    then((response) => {

                        if (response.data) {

                            serverConfig.priceFeed.ethPriceUSD = response.data.fiat.usd;
                            serverConfig.priceFeed.ethPriceYEN = Math.ceil(response.data.fiat.usd * 149.42);

                        }

                    });

            },
            onResize = function () {

                // Let windowHeight = window.innerHeight
                const windowWidth = window.innerWidth;
                serverConfig.windowWidth = windowWidth;

                if (windowWidth >= 1024) {

                    serverConfig.view = "desktop";

                } else if (windowWidth >= 750) {

                    serverConfig.view = "tablet";

                } else {

                    serverConfig.view = "mobile";

                }

            };

        onMounted(() => {

            window.open = (function (open) {

                return function (url, name, features) {

                    name ||= "default_window_name";
                    return open.call(
                        window,
                        url,
                        name,
                        features
                    );

                };

            }(window.open));

            watch(
                () => userInfo.networks,
                () => {

                    getNetwork();

                }
            );

            const selectedProvider = localStorage.getItem("selectedProvider");

            // set a default provider to metamask if unset and installed.
            /*
            if (!selectedProvider && window.eth) {

                localStorage.setItem(
                    "selectedProvider",
                    "metamask"
                );

            }
            */

            getWeb3({
                "VUE_APP_INFURA_ID": process.env.VUE_APP_INFURA_ID,
                "VUE_APP_PROVIDER": serverConfig.VUE_APP_PROVIDER
            }).then(async ({w3,ethereum}) => {

                web3.value = w3;
                eth.value = ethereum;

                const getAccounts = await ethereum
                    .request({ method: "eth_requestAccounts" })
                    .then((response) => {console.log(response)})
                    .catch((err) => {
                      console.log(err);
                    });

                eth.value.on(
                    "accountsChanged",
                    (accounts) => {

                        userInfo.accounts = accounts;

                    }
                );
                eth.value.on(
                    "chainChanged",
                    (networks) => {

                        userInfo.networks = networks;

                    }
                );

                setNetwork();

            });

            getPrice();

            /*
             * We need to use 3rd party tools to do things that were native in vue2
             * provide an event bus here to listen to events emitted in child components
             * I'm defining a simple generic event schema as follows
             * {
             *  action: some-task-to-do
             *  data : some-data-to-process
             * }
             */
            eBus.on(
                "app-event",
                (e) => {

                    switch (e.action) {

                    case "getUserInfo":
                        getUserInfo();
                        break;
                    case "setNetwork":
                        setNetwork(e.data);
                        break;
                    case "getWeb3":
                        getWeb3({
                            "VUE_APP_INFURA_ID": process.env.VUE_APP_INFURA_ID,
                            "VUE_APP_PROVIDER": serverConfig.VUE_APP_PROVIDER
                        }).then(async ({w3,ethereum}) => {

                            const getAccounts = await ethereum
                                .request({ method: "eth_requestAccounts" })
                                .then((response) => {console.log(response)})
                                .catch((err) => {
                                  console.log(err);
                                });

                            web3.value = w3;
                            eth.value = ethereum;

                            console.log("getweb3 e.data")
                            console.log(e.data)

                            if (e.data == "login") {

                                requestAccounts();

                            }

                        });
                        break;
                    default:
                        break;

                    }

                }
            );
            nextTick(() => {

                window.addEventListener(
                    "resize",
                    onResize
                );
                onResize();
                getUserInfo();

            });

        });
        onBeforeUnmount(() => {

            window.removeEventListener(
                "resize",
                onResize
            );

        });
        provide(
            "eBus",
            eBus
        );
        provide(
            "router",
            router
        );
        provide(
            "route",
            route
        );
        provide(
            "web3",
            web3
        );
        provide(
            "eth",
            eth
        );
        provide(
            "userInfo",
            userInfo
        );
        provide(
            "profile",
            profile
        );
        provide(
            "serverConfig",
            serverConfig
        );
        return {eBus,
            getUserInfo,
            getAccounts,
            requestAccounts,
            updateAccount,
            signInAccount,
            onResize,
            getPrice,
            route,
            serverConfig,
            userInfo,
            profile,
            getProfile,
            getNetwork,
            setNetwork,
            setServerConfig,
            web3,
            eth};

    },
    render () {

        return h(App);

    }
}).
    component(
        "CButton",
        CButton
    ).
    component(
        "CSwitch",
        CSwitch
    ).
    component(
        "CInput",
        CInput
    ).
    component(
        "CTextArea",
        CTextArea
    ).
    component(
        "NFTCard",
        NFTCard
    ).
    component(
        "Search",
        Search
    ).
    component(
        "Profile",
        Profile
    ).
    component(
        "NFTLineItem",
        NFTLineItem
    ).
    component(
        "CDropdown",
        CDropdown
    ).
    component(
        "CDropdownItem",
        CDropdownItem
    ).
    component(
        "CSearchBar",
        CSearchBar
    ).
    component(
        "CModal",
        CModal
    ).
    component(
        "ShareNetwork",
        ShareNetwork
    ).
    use(pinia).
    use(VueSocialSharing).
    use(VueClickAway).
    use(VSwatches).
    use(ServiceCard).
    use(router).
    mount("#app");

