<template>
  <div>
    <Dashboard>
      <div class="search-container">
        <div class="search-list-container">

          <div v-for="(k,i) in results">

            <ServiceCard
              :profile="k.profile"
              :product="k"
              :product-media="k.media"
            >
            </ServiceCard>

          </div>
        </div>

        <CModal
          v-if="showProfileModal"
          width="medium"
          color="#f9b115"
          title="Please Wait"
          @close="showProfileModal = false"
        >
          <template #header>
            <div> {{ localize('TEXT_PROFILE') }} </div>
          </template>
          <template #body>
            <div>
            </div>
          </template>
          <template #footer>
            <div class="hidden" />
          </template>
        </CModal>
      </div>
    </Dashboard>
  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {translationStore} from "@/stores/translation";
import {inject, onMounted, reactive, ref, toRaw, toRefs, unref, watch, watchEffect} from "vue";
import Axios from "axios";
import FormData from "form-data";
import {ERC20FactoryABI} from "../abi.js";
import ServiceCard from "./ServiceCard.vue";

export default {
    "name": "Search",
    "components": {
        ServiceCard
    },
    "props": {
        "text": {
            "type": String,
            "default": ""
        },
        "userId": {
            "type": String,
            "default": ""
        },
        "category": {
            "type": String,
            "default": ""
        },
        "subcategory": {
            "type": String,
            "default": ""
        },
        "geo": {
            "type": String,
            "default": ""
        }
    },
    setup (props) {

        const translation = translationStore(),
            serverConfig = toRefs(inject("serverConfig")),
            route = inject("route"),
            router = inject("router"),
            userInfo = inject("userInfo"),
            icon = iconStore(),
            web3 = inject("web3"),
            showProfileModal = ref(false),
            results = ref([]),
            query = toRefs(props),
            runSearch = function () {

                if (route.query) {

                    query.value = route.query;

                }

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}query`,
                    {

                        "params": {

                            text:query.value.text,
                            userId:query.value.userId,
                            category:query.value.category,
                            subcategory:query.value.subcategory,
                            geo:query.value.geo,

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            if (response.data) {

                                console.log('profile response.data');
                                console.log(response.data.services);
                                results.value.splice(0);
                                results.value = response.data.services;

                            }

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            };

        onMounted(() => {

            runSearch();

            setTimeout(()=> {

            }, 1000);

            if (route.params.id) {

            }

            watch(
                serverConfig.chainId,
                () => {

                }
            );

            watch(
                () => route.query,
                () => {

                    runSearch()

                }
            );

        });

        return {"localize": translation.localize,
            icon,
            web3,
            query,
            results,
            showProfileModal,
            runSearch,
            serverConfig,
            "icon": icon.get};

    }
};

</script>
<style scoped>
.define-left {
}
.margin-top {
  margin-top: 20px;
}
.titlecolor {
  color: #22255C;
}
.container {
  text-align: left;
}
.paragraph {
  margin: 10px;
}
.title-mobile {
  margin-left: 0px;
}
.title-desktop {
  margin-left: 10px;
}
.text {
  color: #908ab9;
}
.createtitletext {
  color: #000;
}
.sub-title {
  color: #000;
  font-size: 1.2em;
}
.create-sub-header {
  margin-top: 20px;
  text-align: left;
  font-weight: bold;
  line-height: 35px;
  color: #22255C;
  font-size: 0.9em;
}
.sub-script {
  text-align: left;
  font-weight: bold;
  color: #afafaf;
  line-height: 30px;
  font-size: 0.8em;
}
.search-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.search-list-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.create-upload-box {
  height: 100px;
  border: 1px solid #efefef;
  border-radius: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0.5;
  background: url("../assets/upload-icon.svg") #efefef;
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}
.create-upload-box:hover {
  opacity: 1.0;
  cursor: pointer;
}
.file-items {
  padding: 10px;
  background-color: #eeebff;
  border-radius: 10px;
  font-size: 0.8em;
  margin-top: 10px;
  background: linear-gradient(1deg,#e5e5e5,transparent);
}
.file-name {
  color: #22255c;
  font-weight: bold;
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  white-space: nowrap;
}
.file-item {
  font-family: sans-serif;
  color: #a0a0a0;
  margin-right: 10px;
}
.remove-button {
  cursor: pointer;
  margin-right: 10px;
  font-weight: bold;
  color: #f7608a;
}
.create-token-preview-bg {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  border: 3px dashed #d295ff;
}
.create-token-preview-bg:hover {
  opacity: 0.5;
}
.create-token-container {
  padding: 20px;
  width: 33%;
}
.create-token-preview {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding-left: 20px;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 40px;
  justify-content: center;
}
.square {
  padding-bottom: 100%;
}
.smartphone-banner {
  padding-bottom: 16.667%;
}
/*
.ad191-1 {
  padding-bottom: 52.356%;
}
*/
.landscape {
  padding-bottom: 56.25%;
}
.portrait {
  padding-bottom: 177.78%;
}
.ad4-5 {
  padding-bottom: 125%;
}
.billboard {
  padding-bottom: 25.7%;
}
.leaderboard {
  padding-bottom: 12.36%;
}
.medium-rectangle {
  padding-bottom: 83.33%;
}
.halfpage {
  padding-bottom: 200%;
}
.wide-skyscraper {
  padding-bottom: 266%;
}
.skyscraper {
  padding-bottom: 375%;
}
.upload-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.token-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
h2.createtitletext {
  text-align: left;
  color: #22255C;
}
.vspace {
  width: 100%;
  height: 20px;
}
.nowrap {
  flex-wrap: nowrap;
}
.create-item {
  margin-top: 20px;
}
.spend-item {
  margin-top: 20px;
  width: 150px;
}
.create-token-unit {
  color: #22255c;
  margin-left: 20px;
  font-weight: bold;
  font-size: 1.0em;
  margin-right: 10px;
  flex-wrap: nowrap;
}
.pointer {
  cursor: pointer;
}
.create-save-token-button {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
  width: fit-content;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: white;
  height: 50px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 15px 20px 2px rgb(139 106 224 / 33%);
  cursor: pointer;
  background: linear-gradient(338deg,#ff7c88,#ff46a4)
}
.create-token-title {
  font-weight: 600;
  font-family: sans-serif;
  text-decoration: none;
  color: var(--msft-card-font-color);
  font-size: var(--msft-article-heading-font-size, 1.2em);
  line-height: var(--msft-article-heading-line-height, 24px);
  -webkit-line-clamp: var(--heading-max-lines, 3);
  text-align: center;
  margin: 10px;
  outline: 0px;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.step-1-container {
  display: flex;
  flex-direction: row;
  width: 1200px;
}
.step-2-3-container {
  display: flex;
  flex-direction: row;
  justify-content: inherit;
}
.step-2-3-container-left {
  width: 50%;
}
.contract-items {
  display: flex;
  margin-top: 18px;
}
.flex-row-reverse {
  display: flex;
}
.list-type {
  display: flex;
}
.create-dropdown-item {
  margin-right: 10px;
}
input#create-file-upload {
  display: none;
}
label.create-file-upload {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  padding: 0;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
}
.do-edit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 13px 10px 0px 20px;
  font-size: 0.8em;
  font-weight: bold;
  color: #505050;
}
.do-edit:hover {
  cursor: pointer;
}
</style>
