<template>

  <div :class="homeContainer">

    <div class="home-services-bar">

      <div class="flex-row-center-home">

        <div class="home-services-text">
          <h2>{{ localize('TEXT_SERVICES') }}</h2>
        </div>
        <div
          class="home-menu-icon"
          v-html="getIconRaw('ONLINE')"
        />
        <div
          class="menu-text"
        >
          {{ localize('TEXT_ONLINE') }}
        </div>
        <div
          class="home-menu-icon"
          v-html="getIconRaw('MAP')"
        />
        <div
          class="menu-text"
        >
          {{ localize('TEXT_LOCALS') }}
        </div>
        <div
          v-if="homeContainer == 'home-container-desktop'"
          class="menu-text faq-link"
          @click="gotoPath('faq/')"
        >
          <div class="faq-link-icon">?</div>
          {{ localize('FAQ') }}
        </div>
      </div>

<!--
      <div
        @click="sponsorModal = true"
        class="sponsor-widget"
      >
        <img
          src="../assets/usagiU.png"
          class="sponsor-icon"
        />
        <div class="sponsor-text"> {{localize('TEXT_SPONSOR')}} </div>
      </div>
-->

      <div class="flex-row join-us">
        <div v-if="homeContainer == 'home-container-desktop'">{{ localize("JOIN_US_SOCIAL") }} </div>
        <div @click="gotoURL('https://x.com/Thumpr915396')" class="x-icon-small" />
        <div @click="gotoURL('https://t.me/thumprz')" class="telegram-icon-small" />
        <div @click="gotoURL('https://discord.com/invite/hhz7ZkfZe8')" class="discord-icon-small" />
      </div>

    </div>

    <Search
        userId:17
    />

    <div class="why-thumpr-container" v-if="pageLoaded">
      <div :class="whyThumprImg">
        <img src="../assets/whythumpr.jpg" class="why-thumpr-img">
      </div>
      <div :class="whyThumprText">

        <h2>Why Thumpr Rocks for Freelancers</h2>

        <p>Thumpr is the ultimate hub for freelancers who want to break free and work on their own terms. Earning in crypto makes sense, it gives you the power, putting you in charge of your own payments without the fear of being locked out or stuck with bad payment masters. <br/><br/> We’re built on Base, a blockchain that keeps everything out in the open—no gatekeepers, just you and your services, anywhere in the world! Our community runs the show, so freelancers and clients decide what’s fair, from fees to fixing any hiccups. Thumpr orders are powered by a smart contract Finite State Machine (FSM), sounds complcated ? Not really, it just means that manages orders automatically and fairly: it keeps your money safe until the work’s done, sorts out any disagreements smoothly, and sends your payment straight to you when everyone’s agreed, so you know it’s legit.</p>
        <h3>What Makes Thumpr So Awesome:</h3>
        <ul>
            <li>Totally Open: Jump in anytime, anywhere—no permission needed.</li>
            <li>Community Driven: We decide together on fees and rules, keeping it real.</li>
            <li>Low Fees: Our fees are 10% flat, so you keep more cash.</li>
            <li>Secure: Smart contract driven order ensure security.</li>
            <li>Smooth and Safe: The FSM makes sure your money’s secure and payments are automatic.</li>
            <li>Global Cash, Fast: Get paid in crypto instantly, no borders, no waiting.</li>
        </ul>

      </div>
    </div>

<!--
    <Carousel
      id="gallery"
      v-model="currentSlide"
      wrap-around="true"
      items-to-show="1.0"
      autoplay="8000"
      translition="1000"
      pause-autoplay-on-hover="true"
    >
      <Slide
        v-for="(i,j) in tokens"
        :key="j"
      >
        <div
          v-if="i.symbol != 'REI'"
          class="flex-col"
        >
          <div :class="metalContainer">
            <div class="metal-image">
              <img :src="i.image" class="metal-img" >
            </div>
            <div class="metal-text-container">
              <h1 class="home-header-text">             
                {{i.name}} ({{i.pts}}.)
              </h1>
              <div class="flex-row-center">
                <div
                  class="symbolicon"
                  v-html="getIconRaw(i.icon)"
                />
                <div>
                  <b>{{i.symbol}}</b>
                </div>
              </div>
            </div>
          </div>
          <div class="metal-text">
            {{i.text}}
          </div>
        </div>
      </Slide>

      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>

-->

    <Transition>
      <CModal
        v-if="sponsorModal"
        width="small"
        color="#efefef"
        backgroundColor="#333"
        secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        @close="sponsorModal = false"
      >
        <template #header>
          <div> {{ localize("TEXT_COMING_SOON") }} </div>
        </template>
        <template #body>
          <div class="normal-text">
            We are working to enable online sponsorshops for tuition for children via the marketplace.
            <br />
            <br />
            <br />
            Donations to support will be accepted soon, these funds will pay teachers on thumpr to provide classes for free to teach young students a variety of services, such as programming, AI and design to help their future.
            <br />
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

  </div>

</template>

<script>
import {Carousel, Navigation, Pagination, Slide, toRaw} from "vue3-carousel";
import {emit, inject, onMounted, reactive, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {translationStore} from "@/stores/translation";
import Axios from "axios";
import {iconStore} from "@/stores/icon";
import Search from "./Search.vue";

export default {
    "name": "Home",
    "components": {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        Search
    },
    setup (props, {emit}) {

        const translation = translationStore(),
            eBus = inject("eBus"),
            serverConfig = inject("serverConfig"),
            userInfo = inject("userInfo"),
            route = useRoute(),
            router = useRouter(),
            d3GraphContainer = ref(),
            selectedSymbol = ref(),
            symbols = ref({}),
            currentSlide = ref(),
            whyThumprText = ref("why-thumpr-text-desktop"),
            whyThumprImg = ref("why-thumpr-img-desktop"),
            sponsorModal = ref(false),
            icon = iconStore(),
            currentIndex = ref(0),
            pageLoaded = ref(false),
            email = ref(""),
            chartData = ref({}),
            fiveYearChange = ref(),
            homeContainer = ref(""),
            metalContainer = ref(""),
            gotoURL = function (url) {

                window.open(url, '_blank');

            },
            openLogin = async function (symbol) {

                    eBus.emit(
                        "app-event",
                        {
                            "action": "showLoginModal",
                            "data": ""
                        }
                    );

            },
            slideTo = function (v) {

                currentSlide.value = v;

            },
            getIcon = function (i) {

                if (serverConfig.visualMode.value === "dark") {

                    return icon.get(`${i}_DARK`);

                }
                return icon.get(`${i}_LIGHT`);

            },
            getIconRaw = function (i) {

                return icon.get(`${i}`);

            },
            gotoTab = function (t) {

                router.push({"path": `/${t}/`}).catch((err) => {

                    throw err;

                });

            },
            getNews = function () {

                news.splice(0);
                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getNews`,
                    {
                    }
                ).
                    then((response) => {

                        for (const n in response.data) {

                            news.push(response.data[n]);

                        }

                    });

            },
            gotoPath = function (t) {

                router.push({"path": `/${t}`}).catch((err) => {

                    throw err;

                });

            },
            updateView = function () {

                if (serverConfig.view === "desktop") {

                    whyThumprImg.value = "why-thumpr-img-desktop"
                    whyThumprText.value = "why-thumpr-text-desktop"
                    homeContainer.value = "home-container-desktop"

                } else if (serverConfig.view === "laptop") {

                    whyThumprImg.value = "why-thumpr-img-desktop"
                    whyThumprText.value = "why-thumpr-text-desktop"
                    homeContainer.value = "home-container-desktop"

                } else if (serverConfig.view === "tablet") {

                    whyThumprImg.value = "why-thumpr-img-desktop"
                    whyThumprText.value = "why-thumpr-text-desktop"
                    homeContainer.value = "home-container-desktop"

                } else if (serverConfig.view === "mobile") {

                    whyThumprImg.value = "why-thumpr-img-mobile"
                    whyThumprText.value = "why-thumpr-text-mobile"
                    homeContainer.value = "home-container-mobile"

                } else {

                    homeContainer.value = "home-container-mobile"

                }

            };

        onMounted(() => {

            updateView();

            let run = function() {

              setInterval(() => {

                currentIndex.value++;

                if (currentIndex.value == 7) {

                  currentIndex.value = 0;

                }

              }, 2500);

            }();

            watch(
                () => email.value,

                (first, second) => {

                    console.log(email.value)

                }

            );

            setTimeout(
                () => {

                    pageLoaded.value = true;

                },
                1000
            );

            const runNews = function () {

                setTimeout(
                    () => {

                    },
                    5000
                );

            };
            runNews();

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

        });

        return {"localize": translation.localize,
            gotoPath,
            openLogin,
            userInfo,
            email,
            gotoURL,
            sponsorModal,
            currentSlide,
            currentIndex,
            fiveYearChange,
            selectedSymbol,
            chartData,
            symbols,
            pageLoaded,
            whyThumprText,
            whyThumprImg,
            getIcon,
            getIconRaw,
            gotoTab,
            homeContainer,
            serverConfig};

    }
};

</script>
<style scoped>
.separado {
  width: 20px;
  height: 20px;
}
.subtitle {
  max-width: 500px;
  padding: 20px;
}
.carousel-item {
}
.home-menu-text {
  color: white;
}
.home-menu-icon {
  margin: 20px;
  color: white;
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.rare-earth-technology {
  font-size: 5em;
  margin: 100px;
  background: -webkit-repeating-linear-gradient(46deg,#2fb2ff,#11245e,#2fb2ff,#11245e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.simple-content-text {
  font-size: 1em;
  font-family: Helvetica,"system-ui";
  font-size: 1em;
  padding: 20px;
  color: #5b702c;
  max-width: 800px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}
.normal-content-text {
  font-size: 1em;
  font-family: Helvetica,"system-ui";
  font-size: 1em;
  margin: 20px;
  padding: 20px;
  color: #5b702c;
  border-radius: 20px;
  background: linear-gradient(1deg,#e2e5db63,transparent);
}
.cursive-content-text {
  max-width: 1000px;
  font-size: 4vh;
  margin-left: auto;
  margin-right: auto;
}
.forty-cent {
  width: 40%;
  margin: 10px;
  align-items: center;
  display: flex;
}
.home-header-container {
  position: relative;
}
video.eq-bg-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1.1;
  width: 100%;
}
.home-mission-text-container {
  padding-top: 100px;
}
.home-carousel-container {
  min-height: 820px;
}
.metal-container-desktop {
  display: flex;
  flex-direction: flex-row;
  width: 100%;
  height: 100%;
}
.metal-container-mobile {
  display: flex;
  flex-direction: flex-column-reverse;
  width: 100%;
  height: 100%;
}
.metal-image {
  width: 70%;
}
img.metal-img {
  width: 100%;
  background-size: contain;
}
.metal-text-container {
  width: 30%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.metal-text {
  font-size: 1.1em;
  padding: 20px;
  display: flex;
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
h1.home-content-text {
  display: flex;
  align-items: flex-end;
  color: #4e4e4e;
  margin-top: 25px;
  font-size: 5em;
  margin: 0px;
  padding-top: 30px;
}
.home-content-text {
  display: flex;
  align-items: flex-end;
  color: #4e4e4e;
  margin-top: 25px;
  font-size: 2em;
  font-size: 6vh;
  margin: 0px;
}
h1 .home-header-text {
  color: #4d4d4d;
}
h2 .home-header-text {
  color: #4d4d4d;
}
.home-title-text {
  margin-left: -20px;
  font-weight: normal;
  color: #4d4d4d;
}
.home-header-subtext {
  font-weight: normal;
  color: #4d4d4d;
  font-size: 1.1em;
}
.home-content-text-mobile {
  color: white;
  font-size: 1.2em;
  font-size: 4vh;
  margin: 0px 50px;
}
h1.home-header-text-mobile {
  margin: 50px;
  color: white;
  font-size: 3em;
  font-size: 10vh;
}
.home-content-text-black {
  color: #5f7237;
  font-size: 2em;
  font-size: 6vh;
  margin: 50px;
}
h1.home-header-text-black {
  margin: 50px;
  color: #000;
  font-size: 5em;
  font-size: 15vh;
}
.home-content-text-mobile-black {
  margin: 30px;
  color: #5f7237;
  font-size: 1.2em;
  font-size: 4vh;
}
h1.home-header-text-mobile-black {
  color: #000; 
  font-size: 3em;
  font-size: 10vh;
}
.home-platform-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
img.large-platform-img {
  margin: 10px;
  width: 90%;
}
img.platform-img {
  margin: 10px;
  width: 100%;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.home-curve-container {
}
svg.home-mission-curve {
  position: absolute;
  background: transparent;
  left: 0;
  bottom: 0;
}
svg.home-curve {
  z-index: -2;
  position: absolute;
  background: radial-gradient(ellipse at 50% 70%,#7d5cff,rgba(255,92,244,.47000000000000003));
  left: 0;
}
.colorstroke {
  width: 100%;
  height: 100%;
  max-height: 100px;
  max-width: 800px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}
.governance-backdrop {
  background-size: auto;
  background-position: center;
  margin-left: 10px;
  margin-right: 10px;
}
.subtitle {
  max-width: 500px;
  padding: 20px;
  font-size: 3em;
  font-size: 1.0em;
  line-height: 30px;
  text-align: left;
}
a.contactus {
  color: #322e6a;
  text-decoration: underline;
  cursor: pointer;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.good-link {
  color: #3783bb;
}
.home-roadmap-container {
  font-size: 1.1em;
  width: 90%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}
.home-roadmap-box-left-a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-bottom: 10px solid #ced4c2;
  border-right: 10px solid #ced4c2;
  background-color: #fafafa;
}
.home-roadmap-box-right-a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  background-color: #fafafa;
}
.home-roadmap-box-left-b {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  background-color: #fafafa;
}
.home-roadmap-box-right-b {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  border-left: 10px solid #ced4c2;
  border-bottom: 10px solid #ced4c2;
  background-color: #fafafa;
}
.founders-container {
  max-width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.compdeep-logo {
  cursor: pointer;
  width: 100%;
  height: 100px;
  background-image: url('../assets/compdeep-banner-plain.png');
  margin: 20px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: contain;
  max-width: 300px;
}
.home-footer {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.voxels-container {
  position: relative;
  padding: 0;
  margin-top: 100px;
  width: 100%;
  display: grid;
  place-content: center;
  grid-template-columns: repeat(33,3%);
}
.voxels {
  background: #53b2fe;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  scale: 0;
  opacity: 0;
  animation: box 5s linear infinite;
}
@keyframes box {
  0%, 50%, 90% { scale: 1; opacity: 1 }
  25%, 75%, 100% { scale: 0; opacity: 0 }
}
.home-container-desktop {
  display: flex;
  flex-direction: column;
}
.home-container-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 10px;
  max-width: 1600px;
}
.home-container-a-left-mobile {
  width: 100%;
}
.home-container-a-right-mobile {
  width: 100%;
}
.home-container-a-left-desktop {
  width: 50%;
}
.home-container-a-right-desktop {
}
.home-container-a-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 10px;
  padding: 10px;
  min-width: 300px;
  -webkit-flex: 1;
  -ms-flex: 1;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.symbolicon {
  display: flex;
  width: 36px;
  height: 36px;
}
.home-container-a-price {
  background-color: none;
  text-align: left;
  -webkit-flex: unset;
  -ms-flex: unset;
  flex: unset;
}
.home-container-metal-name {
  color: #505050;
  font-size: 0.8em;
}
.home-container-a-symbol {
  color: #505050;
  font-weight: bold;
  font-size: 0.6em;
  margin-left: 5px;
  margin-right: 5px;
}
.home-container-a-price {
  color: #505050;
  font-size: 0.8em;
  font-weigth: bold;
}
.home-container-a-ytd {
  font-size: 0.8em;
  color: grey; 
  font-weight: bold;
}
.home-container-a-ytd-neg {
  font-size: 0.8em;
  color: #ff7cf5;
  font-weight: bold;
}
.home-container-a-ytd-pos {
  font-size: 0.8em;
  color: #46b6ff;
  font-weight: bold;
}
.home-container-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-height: 70vh;
  margin: 5px;
  cursor: pointer;
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex-row-center-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}
.voxels-text-container {
  position: absolute;
  color: #232b3f;
  font-weight: 700;
  width: 100%;
  text-align: center;
  top: 150px;
}
.svg-logo {
  display: flex;
}
.home-header-text-container {
  margin: 10px;
}
.home-register-container {
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
}
.bottom-register-container {
  display: flex;
  align-items: center; 
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.graph-container-desktop {
  height: 50vh;
  width: 50%;
  margin: 10px;
  padding: 10px;
}
.graph-container-mobile {
  height: 80vh;
  width: 100%;
  margin-left: -40px;
}
.diamond-container {
  height: 500px;
  min-width: 300px;
  width: 50%;
}
.five-year-text {
  min-height: 300px;
  margin: 10px;
}
.five-year-percent-change {
  font-weight: bold;
  color: rgb(47, 178, 255);
}
.five-year-percent-change-text {
  color: #061d2b;
}
.appreciation-container {
  max-width: 500px;
  font-size: 1.1em;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.slide-fade-enter-active {
    transition: all 1.5s ease-out;
}

.slide-fade-leave-active {
    transition: all 2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}

/*
@keyframes rotateDiamond {
    from {
        transform: rotateX(0deg) rotateY(360deg) rotateZ(360deg);
    }
    to {
        transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
    }
}
*/
.banner-text {
  font-size: 2em;
}
.home-services-text {
  color: white;
}

.flex-row-center-home {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  color: white;
}

.home-services-bar {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sponsor-widget {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  justify-content: center;
  align-items: end;
}
.sponsor-widget:hover {
  text-decoration: underline;
  cursor: pointer;
}

.sponsor-icon {
  width: 42px;
  height: 42px;
}
.sponsor-text {
  font-size: 0.9em;
  font-family: cursive;
  width: 150px;
  text-align: center;
  color: black;
}
.join-us {
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  justify-content: center;
  align-items: center;
}
.telegram-icon-small {
  margin-left: 8px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 50%;
  background-color: white;
  background-image: url("../assets/telegram-icon-white.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.discord-icon-small {
  margin-left: 8px;
  width: 34px;
  height: 34px;
  cursor: pointer;
  background-image: url("../assets/discord-icon.webp");
  background-repeat: no-repeat;
  background-size: contain;
}
.x-icon-small {
  margin-left: 8px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-image: url("../assets/x-icon-small.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.social-link {
  text-decoration: underline;
  cursor: pointer;
}
.faq-link-icon {
  display: flex;
  font-size: 18px;
  flex-direction: row;
  margin-left: 16px;
  margin-right: 5px;
  rotate: 15deg;
  font-weight: 700;
  border-radius: 50%;
  text-align: center;
  width: 24px;
  border: 1px solid white;
  justify-content: center;
}
.faq-link {
  cursor:pointer;
  padding-bottom: 5px;
}
.faq-link:hover {
  text-decoration: underline;
}

</style>

<style lang="scss" scoped>

.why-thumpr-container {
  display: flex;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.why-thumpr-text-desktop {
  max-width: 500px;
}

.why-thumpr-text-mobile {
  margin-top: 700px;
  background: linear-gradient(1deg, #ffffff, #ffffff00);
}

.why-thumpr-img-mobile {
  position: absolute;
  z-index: -1;
}

.why-thumpr-img-desktop {
  width: 100%;
}

.why-thumpr-img {
  width: 100%;
}

.thumpr-section {
  max-width: 600px;
  background-color: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  font-family: 'Arial', sans-serif;
}
.thumpr-section h2 {
  color: #2c3e50;
  font-size: 28px;
  margin-bottom: 15px;
  text-align: center;
}
.thumpr-section h3 {
  color: #2c3e50;
  font-size: 22px;
  margin-bottom: 15px;
  text-align: center;
}
.thumpr-section p {
  color: #34495e;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}
.thumpr-section ul {
  list-style: none;
  padding: 0;
}
.thumpr-section li {
  color: #34495e;
  font-size: 16px;
  margin-bottom: 10px;
  position: relative;
  padding-left: 30px;
}
.thumpr-section li:before {
  content: '🥕';
  position: absolute;
  left: 0;
  font-size: 20px;
}
@media (max-width: 480px) {
  .thumpr-section {
      padding: 20px;
  }
  .thumpr-section h2 {
      font-size: 24px;
  }
  .thumpr-section h3 {
      font-size: 20px;
  }
  .thumpr-section p, .thumpr-section li {
      font-size: 14px;
  }
}
</style>
