import {defineStore} from "pinia";

const getLang = function () {

        if (navigator.userLanguage) {

            return navigator.userLanguage;

        } else if (navigator.language) {

            return navigator.language;

        }

        return "en-US";

    },

    getBrowserLanguage = function () {

        switch (getLang()) {

        case "en-US":
            return "en";
        case "en-AU":
            return "en";
        case "en-CA":
            return "en";
        case "en-IE":
            return "en";
        case "en-GB":
            return "en";
        case "en-JM":
            return "en";
        case "en-ZA":
            return "en";
        case "en-PH":
            return "en";
        case "ja":
            return "ja";
        case "ja-jp":
            return "ja";
        case "ja-JP":
            return "ja";
        default:
            return "en";

        }

    },

    translationStore = defineStore(
        "translation",
        {
            "actions": {
                "setLanguage": (state) => function (lang) {

                    state.VUE_APP_USER_LANGUAGE = lang;

                }
            },
            "getters": {
                "getLang": () => function () {

                    return getLang();

                },
                "localize": (state) => function (...args) {

                    const args_ = Array.prototype.slice.call(args),
                        i = args_[0],
                        r = [];
                    for (let j = 1; j < args_.length; j++) {

                        r.push(args_[j]);

                    }
                    if (!state.VUE_APP_USER_LANGUAGE) {

                        state.VUE_APP_USER_LANGUAGE = getBrowserLanguage();

                    }
                    if (!state[i]) {

                        return i;

                    }
                    if (state[i] && state.VUE_APP_USER_LANGUAGE) {

                        let localized = state[i][state.VUE_APP_USER_LANGUAGE];
                        if (!localized) {

                            return "";

                        }
                        if (r.length > 0) {

                            let l = 0;
                            for (const k in r) {

                                if (r[k]) {

                                    localized = localized.replace(
                                        `{${l}}`,
                                        r[k]
                                    );

                                } else {

                                    localized = localized.replace(
                                        `{${l}}`,
                                        ""
                                    );

                                }
                                l++;

                            }
                            return localized;

                        }
                        return localized;

                    }
                    return i;

                }
            },
            "state": () => ({
                "ACCOUNT_DATA_FOR_REWARDS": {
                    "en": "Share your data to increase your reward multiplier.",
                    "ja": "データを共有することで、報酬の倍率を上げることができます。"
                },
                "APP_COPYRIGHT": {
                    "en": "© 2022 equityDAO  Copyright, All Rights Reserved.",
                    "ja": "© 2022 equityDAO . 無断複写・転載を禁じます。"
                },
                "APP_SET_EMAIL": {
                    "en": "Set e-mail",
                    "ja": "メールアドレスを入力"
                },
                "APP_SET_EMAIL_PLACEHOLDER": {
                    "en": "enter email",
                    "ja": "メールアドレスを入力"
                },
                "APP_SET_EMAIL_TEXT": {
                    "en": "Set an email to receive offer notifications and updates.",
                    "ja": "電子メールを入力し、オファーの通知と更新を受け取ります。"
                },
                "APP_GET_STARTED": {
                    "en": "Get Started !",
                    "ja": "スタート"
                },
                "APP_INSUFFICIENT_BALANCE_A" : {
                    "en": "The connected wallet has insufficient balance to execute this transaction. To complete the transaction please top up your wallet with the listed tokens on the correct chain. Cryptocurrnecy can be purchased on an exchange or via a payment portal in your wallet, for example via 'Transak' on Metamask.",
                    "ja": "接続されているウォレットには、このトランザクションを実行するための十分な残高がありません。 トランザクションを完了するには、正しいチェーンにリストされているトークンをウォレットに補充してください。 暗号通貨は取引所で購入することも、ウォレット内の支払いポータル (メタマスクの「Transak」など) 経由で購入することもできます。"
                },
                "APP_INSUFFICIENT_BALANCE_B" : {
                    "en": "If you use magic wallet it is possible to purchace cryptocurrency by opening the wallet under the profile icon on the top right and click on wallet. NB. Please make sure to purchase tokens on the correct chain, for example, if the Payment is with 'MATIC', please make sure that the connected wallet is on the 'polygon chain' (Not Ethereum). If the payment is in 'ETH' please make sure you are on the Ethereum Chain.",
                    "ja": "マジックウォレットを使用している場合は、右上のプロフィールアイコンの下でウォレットを開き、ウォレットをクリックすることで暗号通貨を購入することができます。 注意。 必ず正しいチェーンでトークンを購入してください。たとえば、支払いが「MATIC」の場合は、接続されたウォレットが「ポリゴン チェーン」(イーサリアムではない) 上にあることを確認してください。 支払いが「ETH」である場合は、イーサリアム チェーン上にいることを確認してください。"
                },
                "APP_INSUFFICIENT_BALANCE_C" : {
                    "en": "For additional support please contact the equityDAO team on discord.",
                    "ja": "追加のサポートが必要な場合は、discord の equityDAO チームにお問い合わせください。"
                },
                "APP_SIGNUP_DETAILS": {
                    "en": "Sign Up for more details",
                    "ja": "登録して詳細を確認する"
                },
                "APP_SIGN_UP": {
                    "en": "Sign Up !",
                    "ja": "サインアップ"
                },
                "APP_SIGN_UP_COMPLETE": {
                    "en": "Congrats, sign up complete !",
                    "ja": "サインアップ完了"
                },
                "APP_SIGN_UP_TITLE": {
                    "en": "Sign up.",
                    "ja": "登録する。"
                },
                "APP_USE_WALLET": {
                    "en": "equityDAO Requires a web3 wallet to use. Please install a wallet like metamask below to continue.",
                    "ja": "を使用するには、web3ウォレットが必要です。下記リンク先のmetamaskのようなウォレットをインストールしてください。"
                },
                "WHAT_IS_THUMPR": {
                  "en": "What is Thumpr.xyz?",
                  "ja": "Thumpr.xyzとは何ですか？"
                },
                "WHAT_IS_THUMPR_DESC": {
                  "en": "Thumpr is a decentralized marketplace built on the Base blockchain where you can create and find gigs or services. Whether you’re offering a skill or hiring someone, Thumpr connects you directly with others in a secure and transparent way.",
                  "ja": "Thumprは、Baseブロックチェーン上に構築された分散型マーケットプレイスで、サービスのギグを作成したり見つけたりできます。スキルを提供する場合でも、誰かを雇う場合でも、Thumprは安全で透明性のある方法であなたと他人を直接つなぎます。"
                },
                "NAV_WHAT_IS_THUMPR": {
                  "en": "What is Thumpr?",
                  "ja": "Thumprとは？"
                },

                "HOW_DO_I_SIGN_UP": {
                  "en": "How do I sign up?",
                  "ja": "どうやって登録しますか？"
                },
                "HOW_DO_I_SIGN_UP_DESC": {
                  "en": "Signing up is free! We recommend using a Base Smart Wallet to get the best experience. Just connect your wallet, and you’re ready to go. You can see your account details by clicking the wallet icon at the top of the screen.",
                  "ja": "登録は無料です！最高の体験を得るために、Baseスマートウォレットの使用をおすすめします。ウォレットを接続するだけで、すぐに始められます。アカウントの詳細は、画面上部のウォレットアイコンをクリックして確認できます。"
                },
                "NAV_HOW_DO_I_SIGN_UP": {
                  "en": "How do I sign up?",
                  "ja": "登録方法は？"
                },

                "HOW_DO_I_CREATE_GIG": {
                  "en": "How do I create a gig?",
                  "ja": "ギグはどうやって作りますか？"
                },
                "HOW_DO_I_CREATE_GIG_DESC": {
                  "en": "Creating a gig is free and doesn’t require any ETH gas fees. Simply describe your service, set your price, and post it. Anyone can browse and order your gig.",
                  "ja": "ギグの作成は無料で、ETHガス代も必要ありません。サービスを説明し、価格を設定して投稿するだけです。誰でもあなたのギグを閲覧して注文できます。"
                },
                "NAV_HOW_DO_I_CREATE_GIG": {
                  "en": "How do I create a gig?",
                  "ja": "ギグの作り方は？"
                },

                "DO_I_NEED_ETH_GAS": {
                  "en": "Do I need ETH gas to use Thumpr?",
                  "ja": "Thumprを使うのにETHガスが必要ですか？"
                },
                "DO_I_NEED_ETH_GAS_DESC": {
                  "en": "You don’t need ETH gas to create gigs or sign up. However, you’ll need a small amount of ETH gas to place an order or accept an order. This covers the blockchain transaction costs on Base.",
                  "ja": "ギグを作成したり登録したりするのにETHガスは必要ありません。ただし、注文を出すか受け入れるには少量のETHガスが必要です。これはBaseブロックチェーンのトランザクションコストをカバーします。"
                },
                "NAV_DO_I_NEED_ETH_GAS": {
                  "en": "Do I need ETH gas?",
                  "ja": "ETHガスは必要？"
                },
                "TEXT_NO_ETH": {
                  "en": "No ETH",
                  "ja": "NO ETH"
                },
                "NO_ETH_1": {
                  "en": "Thumpr is powered by the Base blockchain and requires Ethereum Token to pay for all transactions. But don't worry we can sponsor you some free ETH if this is a new account, just jump onto discord and share us your account address and we will send you enough ETH to cover your first batch of orders. You can alternatively buy a small around of ethereum",
                  "ja": "ThumprはBaseブロックチェーンを利用しており、すべての取引の支払いにEthereumトークンが必要です。しかし、心配しないでください。新しいアカウントの場合は、無料のETHをスポンサーできます。Discordにジャンプしてアカウントアドレスを共有するだけで、最初の注文をカバーするのに十分なETHをお送りします。代わりに、"
                },
                "NO_ETH_2": {
                  "en": "here",
                  "ja": "ここ"
                },
                "NO_ETH_3": {
                  "en": "or any other crypto company you choose. Feeds on Base are typically very cheap just one or two cents so just having one or two dollars of ETH in your account will be enough to process many orders.",
                  "ja": "少量のEthereumを購入すること>もできます。Base のフィードは通常 1 セントか 2 セントと非常に安価なので、アカウントに 1 ドルか 2 ドルの ETH があれば、多くの注文を処理するのに十分です。"
                },
                "WHAT_IF_NO_GAS": {
                  "en": "What if I don’t have ETH gas?",
                  "ja": "ETHガスがない場合はどうなりますか？"
                },
                "WHAT_IF_NO_GAS_DESC": {
                  "en": "No worries! If you signed up with a Base Smart Wallet and need gas to accept an order, hop on our Discord. We’ll send you some free ETH gas to get started. (Note: Free gas is only for Base Smart Wallet users.)",
                  "ja": "心配いりません！Baseスマートウォレットで登録していて、注文を受け入れるためにガスが必要な場合、Discordに参加してください。無料のETHガスをお送りして、始められるようにします。（注：無料ガスはBaseスマートウォレットユーザー向けのみです。）"
                },
                "NAV_WHAT_IF_NO_GAS": {
                  "en": "What if I don’t have gas?",
                  "ja": "ガスがない場合は？"
                },

                "HOW_DOES_ORDERING_WORK": {
                  "en": "How does ordering work?",
                  "ja": "注文はどうやって機能しますか？"
                },
                "HOW_DOES_ORDERING_WORK_DESC": {
                  "en": "Here’s the simple breakdown: 1. Place an Order: Find a gig you like and order it. You’ll need a tiny bit of ETH gas to lock your payment securely in escrow (a safe holding spot). 2. Work Happens: The seller completes the gig. 3. Finish Up: When you’re happy with the work, you confirm it’s done. The payment moves from escrow to the seller. If the seller thinks it’s complete first, they can confirm too. 4. All Good: Once both sides agree, the deal is done, and the funds are released.",
                  "ja": "簡単な流れはこちら：1. 注文を出す：気に入ったギグを見つけて注文します。支払いをエスクロー（安全な保管場所）にロックするには少量のETHガスが必要です。2. 作業が進む：売り手がギグを完了します。3. 終了：仕事に満足したら、完了を確認します。支払いがエスクローから売り手に移動します。売り手が先に完了したと思う場合も確認できます。4. 完了：双方が同意すれば取引が終わり、資金が解放されます。"
                },
                "NAV_HOW_DOES_ORDERING_WORK": {
                  "en": "How does ordering work?",
                  "ja": "注文の仕組みは？"
                },

                "WHAT_IF_WRONG": {
                  "en": "What if something goes wrong?",
                  "ja": "何か問題が起きたらどうなりますか？"
                },
                "WHAT_IF_WRONG_DESC": {
                  "en": "If you and the seller don’t agree (like if the work isn’t what you expected), you can start a dispute: The payment stays locked in escrow until it’s sorted out. You can work it out together, or if needed, bring in a third-party helper (arbitrator) to decide. Sometimes, paying a small optional fee can speed things up or settle it faster. Once resolved, the payment goes to the right person, and the order wraps up.",
                  "ja": "売り手と意見が合わない場合（例えば仕事が期待通りでない場合）、紛争を開始できます：解決するまで支払いはエスクローにロックされます。一緒に解決するか、必要なら第三者（仲裁者）を入れて決めてもらいます。オプションで少額の料金を払うと、早く解決できる場合もあります。解決後、支払いは正しい人に渡り、注文が完了します。"
                },
                "NAV_WHAT_IF_WRONG": {
                  "en": "What if something goes wrong?",
                  "ja": "問題が起きたら？"
                },

                "WHY_BASE_WALLET": {
                  "en": "Why use a Base Smart Wallet?",
                  "ja": "なぜBaseスマートウォレットを使うのですか？"
                },
                "WHY_BASE_WALLET_DESC": {
                  "en": "It’s the easiest way to use Thumpr! It works smoothly with the Base blockchain, lets you see your account details with one click, and qualifies you for free ETH gas if you need it to accept orders.",
                  "ja": "Thumprを使う最も簡単な方法です！Baseブロックチェーンとスムーズに連携し、ワンクリックでアカウント詳細を確認でき、注文を受け入れるために必要な場合に無料のETHガスがもらえます。"
                },
                "NAV_WHY_BASE_WALLET": {
                  "en": "Why Base Wallet?",
                  "ja": "Baseウォレットの利点は？"
                },

                "IS_MONEY_SAFE": {
                  "en": "Is my money safe?",
                  "ja": "お金は安全ですか？"
                },
                "IS_MONEY_SAFE_DESC": {
                  "en": "Yes! Thumpr uses the Base blockchain to keep everything secure. Your payment sits in escrow until you’re happy with the gig—or until a dispute is settled fairly. No one can touch it until the rules are followed.",
                  "ja": "はい！ThumprはBaseブロックチェーンを使ってすべてを安全に保ちます。支払いはギグに満足するまで、または紛争が公平に解決されるまでエスクローに保管されます。ルールが守られるまで誰も触れません。"
                },
                "NAV_IS_MONEY_SAFE": {
                  "en": "Is my money safe?",
                  "ja": "お金は安全？"
                },

                "TECH_DETAILS": {
                  "en": "Where can I see the tech details?",
                  "ja": "技術的な詳細はどこで見られますか？"
                },
                "TECH_DETAILS_DESC": {
                  "en": "If you’re curious about the smart contract powering Thumpr, check it out on Basescan: ",
                  "ja": "Thumprを支えるスマートコントラクトに興味があるなら、Basescanで確認できます："
                },
                "NAV_TECH_DETAILS": {
                  "en": "Tech details?",
                  "ja": "技術詳細は？"
                },

                "MORE_QUESTIONS": {
                  "en": "Have more questions?",
                  "ja": "他に質問がありますか？"
                },
                "MORE_QUESTIONS_DESC": {
                  "en": "Join our Discord community or reach out—we’re here to help!",
                  "ja": "Discordコミュニティに参加するか、連絡してください—お手伝いします！"
                },
                "NAV_MORE_QUESTIONS": {
                  "en": "More questions?",
                  "ja": "さらに質問？"
                },
                "BECOME_A_CREATOR_DETAILS": {
                    "en": "Create NFTs, Grow your audience, Build your brand.",
                    "ja": "equityDAOにクリエイター登録をしてNFTの販売を行います。"
                },
                "BECOME_A_CREATOR_RESPONSE_SUCCESS": {
                    "en": "Thank you for your interest in equityDAO, we will be in contact shortly.",
                    "ja": "equityDAOにご興味をお持ちいただき、ありがとうございます。"
                },
                "BECOME_A_CREATOR_RESPONSE_FAILED": {
                    "en": "An error was encountered processing the request, please check all fields and contact support@equityDAO.io",
                    "ja": "リクエストの処理中にエラーが発生しました。すべてのフィールドを確認して、support@equityDAO.io までご連絡ください。"
                },
                "CONTACT_WILL_CONTACT": {
                    "en": "Thank you for your interest, please check your inbox to complete registration.",
                    "ja": "ご興味をお持ちいただきありがとうございます。メールが送信されましたので、登録作業をご確認ください。"
                },
                "CREATE_TOKENS_MODAL_TITLE": {
                    "en": "Create Tokens and NFT's (ERC-20, ERC-721, ERC-1155)",
                    "ja": ""
                },
                "CREATE_ERC20": {
                    "en": "The standard crypto currency token to represent anything in the ethereum and EVM ecosystem. Fungible, easily transferable and supported by every platform. Choose this for your standard token utility.",
                    "ja": "イーサリアムおよびEVMエコシステム内のあらゆるものを表す標準の暗号通貨トークン。 代替可能で、簡単に譲渡可能で、あらゆるプラットフォームでサポートされています。 標準のトークン ユーティリティにはこれを選択してください。"
                },
                "CREATE_ERC721": {
                    "en": "The standard Non Fungive Token (NFT), used to represent unique assets in the ethereum and EVM ecosystem. Supported by all EVM marketplaces. Choose this when each item needs a truly unique representation.",
                    "ja": "標準の非菌類トークン (NFT)。イーサリアムおよび EVM エコシステム内の固有の資産を表すために使用されます。 すべての EVM マーケットプレイスでサポートされています。 各アイテムに真にユニークな表現が必要な場合は、これを選択します。"
                },
                "CREATE_ERC1155": {
                    "en": "A Hybrid Non Fungible Token that has unique top level tokens with fungible divisibility. Choose this if you want unique assets that can also have currency supply features. Supported by most major platforms.",
                    "ja": "代替可能な可分性を持つ独自のトップレベルのトークンを備えたハイブリッド非代替トークン。 通貨供給機能も備えた独自の資産が必要な場合は、これを選択します。 ほとんどの主要なプラットフォームでサポートされています。"
                },
                "CREATE_CHOOSE_FILE": {
                    "en": "Choose your creative media to upload.",
                    "ja": "アップロードするクリエイティブメディアを選択する。"
                },
                "CREATE_STEP_1": {
                    "en": "Step 1 - Upload Collection Image and Banner.",
                    "ja": "1 - コレクション画像とバナーをアップロードする。"
                },
                "CREATE_STEP_2": {
                    "en": "Step 2 - Define Collection.",
                    "ja": "2 - コレクションを定義します。"
                },
                "CREATE_STEP_3": {
                    "en": "Step 3 - Upload Creative Media.",
                    "ja": "3 - クリエイティブ メディアをアップロードする。"
                },
                "CREATE_STEP_4": {
                    "en": "Step 4 - Define NFT Properties.",
                    "ja": "4 - NFT プロパティを定義する。"
                },
                "CREATE_SPEND": {
                    "en": "Max Daily Spend",
                    "ja": "1日の最大支出"
                },
                "COLLECTION_NFTS_HEADER": {
                    "en": "NFT's in this Collection",
                    "ja": "このコレクションのNFT"
                },
                "VIEW_PROFILE": {
                    "en": "View profile.",
                    "ja": "プロフィールを見る"
                },
                "PROFILE_BANNER_TEXT": {
                    "en": "Showcase.",
                    "ja": "ショーケース"
                },
                "PROFILE_COLLECTED_NFTS": {
                    "en": "Collected NFT's",
                    "ja": "購入したNFT"
                },
                "CATEGORY_STORES": {
                    "en": "Stores",
                    "ja": "店舗"
                },
                "CATEGORY_SERVICES": {
                    "en": "Services",
                    "ja": "ギグ"
                },
                "SERVICE_ADDED_SUCCESS": {
                    "en": "Service Added Successfully !",
                    "ja": "ギグが正常に追加されました!"
                },
                "SERVICE_UPDATED_SUCCESS": {
                    "en": "Service Updated Successfully !",
                    "ja": "ギグが正常に更新されました!"
                },
                "SERVICE_AI_SERVICES": {
                    "en": "AI Services",
                    "ja": "AIサービス"
                },
                "SERVICE_AI_DATA_SCIENCE": {
                    "en": "AI & Data Science",
                    "ja": "AIとデータサイエンス"
                },
                "SERVICE_SOFTWARE_SERVICES": {
                    "en": "Software Services",
                    "ja": "ソフトウェアサービス"
                },
                "SERVICE_TECHNOLOGY_SERVICES": {
                    "en": "Technology Services",
                    "ja": "テクノロジーサービス"
                },
                "SERVICE_MARKETING_SALES": {
                    "en": "Marketing & Sales",
                    "ja": "マーケティングと販売"
                },
                "SERVICE_CAD_ARCHITECTURE": {
                    "en": "CAD & Architecture",
                    "ja": "CADと建築"
                },
                "SERVICE_MEDIA_DESIGN": {
                    "en": "Media & Design",
                    "ja": "メディアとデザイン"
                },
                "SERVICE_MUSIC_AUDIO": {
                    "en": "Music & Audio",
                    "ja": "音楽とオーディオ"
                },
                "SERVICE_FILM_TV": {
                    "en": "Film & TV",
                    "ja": "映画とテレビ"
                },
                "SERVICE_CONTENT_CREATION": {
                    "en": "Content Creation",
                    "ja": "コンテンツ作成"
                },
                "SERVICE_LEARNING_EDUCATION": {
                    "en": "Learning & Education",
                    "ja": "学習と教育"
                },
                "SERVICE_LANGUAGES_TRANSLATION": {
                    "en": "Languages & Translation",
                    "ja": "言語と翻訳"
                },
                "SERVICE_BUSINESS_FINANCE": {
                    "en": "Business & Finance",
                    "ja": "ビジネスと金融"
                },
                "SERVICE_LEGAL_ACCOUNTING": {
                    "en": "Legal & Accounting",
                    "ja": "法律と会計"
                },
                "SERVICE_OTHER": {
                    "en": "Other Services",
                    "ja": "その他のギグ"
                },
                "CATEGORY_JOBS": {
                    "en": "Jobs",
                    "ja": "仕事"
                },
                "CATEGORY_LOCALS": {
                    "en": "Locals",
                    "ja": "ローカルサービス"
                },
                "ONLINE_GIG_WHAT": {
                    "en": "Online services that can be done from anywhere (remote).",
                    "ja": "どこからでもできるオンラインサービス（リモート）"
                },
                "LOCAL_GIG_WHAT": {
                    "en": "Local services are provided in a specific location (in person).",
                    "ja": "特定の場所で提供されるローカルサービス（対面）"
                },
                "LOCALS_HOME_PROPERTY": {
                    "en": "Home & Property Services",
                    "ja": "住宅と物件サービス"
                },
                "PLUMBING": {
                    "en": "Plumbing",
                    "ja": "配管工事"
                },
                "ELECTRICAL_WORK": {
                    "en": "Electrical Work",
                    "ja": "電気工事"
                },
                "CARPENTRY": {
                    "en": "Carpentry",
                    "ja": "大工仕事"
                },
                "PAINTING": {
                    "en": "Painting",
                    "ja": "ペイント作業"
                },
                "ROOFING_SOLAR": {
                    "en": "Roofing & Solar",
                    "ja": "屋根工事とソーラー"
                },
                "CONSTRUCTION": {
                    "en": "Construction",
                    "ja": "建設"
                },
                "INTERIOR_DESIGN": {
                    "en": "Interior Design",
                    "ja": "インテリアデザイン"
                },
                "LANDSCAPING_GARDENING": {
                    "en": "Landscaping & Gardening",
                    "ja": "造園とガーデニング"
                },
                "HANDYMAN_SERVICES": {
                    "en": "Handyman Services",
                    "ja": "便利屋サービス"
                },
                "HVAC": {
                    "en": "HVAC",
                    "ja": "空調システム"
                },
                "SKILLED_TRADE": {
                    "en": "Skilled Trade",
                    "ja": "専門技術者"
                },
                "RESIDENTIAL_CLEANING": {
                    "en": "Residential Cleaning",
                    "ja": "住宅クリーニング"
                },
                "COMMERCIAL_CLEANING": {
                    "en": "Commercial Cleaning",
                    "ja": "商業クリーニング"
                },
                "WINDOW_CLEANING": {
                    "en": "Window Cleaning",
                    "ja": "窓清掃"
                },
                "FABRIC_CLEANING": {
                    "en": "Fabric Cleaning",
                    "ja": "布製品のクリーニング"
                },
                "MANAGEMENT_LEGAL": {
                    "en": "Management & Legal",
                    "ja": "管理と法律"
                },
                "LOCALS_AUTO": {
                    "en": "Auto Services",
                    "ja": "自動車サービス"
                },
                "AUTO_SALES": {
                    "en": "Auto Sales",
                    "ja": "自動車販売"
                },
                "AUTO_PARTS": {
                    "en": "Auto Parts",
                    "ja": "自動車部品"
                },
                "AUTO_CLEANING": {
                    "en": "Auto Cleaning",
                    "ja": "自動車清掃"
                },
                "AUTO_REPAIR": {
                    "en": "Auto Repair",
                    "ja": "自動車修理"
                },
                "AUTO_SERVICES": {
                    "en": "Other Auto Services",
                    "ja": "その他の自動車サービス"
                },
                "LOCALS_FOR_SALE": {
                    "en": "Items for Sale",
                    "ja": "販売中の商品"
                },
                "APPLIANCES": {
                    "en": "Appliances",
                    "ja": "家電"
                },
                "FURNITURE": {
                    "en": "Furniture",
                    "ja": "家具"
                },
                "ELECTRONICS": {
                    "en": "Electronics",
                    "ja": "電子機器"
                },
                "CLOTHING": {
                    "en": "Clothing",
                    "ja": "衣料品"
                },
                "SPORTING_GOODS": {
                    "en": "Sporting Goods",
                    "ja": "スポーツ用品"
                },
                "TOOLS": {
                    "en": "Tools",
                    "ja": "工具"
                },
                "BOOKS": {
                    "en": "Books",
                    "ja": "本"
                },
                "MISCELLANEOUS": {
                    "en": "Miscellaneous",
                    "ja": "その他"
                },
                "LOCALS_HOSPITALITY": {
                    "en": "Hospitality",
                    "ja": "ホスピタリティ"
                },
                "HOTELS": {
                    "en": "Hotels",
                    "ja": "ホテル"
                },
                "RESTAURANTS": {
                    "en": "Restaurants",
                    "ja": "レストラン"
                },
                "CAFES": {
                    "en": "Cafes",
                    "ja": "カフェ"
                },
                "BARS": {
                    "en": "Bars",
                    "ja": "バー"
                },
                "EVENT_VENUES": {
                    "en": "Event Venues",
                    "ja": "イベント会場"
                },
                "HOSTING_SERVICES": {
                    "en": "Hosting Services",
                    "ja": "ホスティングサービス"
                },
                "LOCALS_FOOD_AND_CATERING": {
                    "en": "Food & Catering",
                    "ja": "フードとケータリング"
                },
                "CATERING": {
                    "en": "Catering",
                    "ja": "ケータリング"
                },
                "BAKERIES": {
                    "en": "Bakeries",
                    "ja": "ベーカリー"
                },
                "FOOD_TRUCKS": {
                    "en": "Food Trucks",
                    "ja": "フードトラック"
                },
                "HOME_CHEFS": {
                    "en": "Home Chefs",
                    "ja": "ホームシェフ"
                },
                "MEAL_PREP": {
                    "en": "Meal Prep",
                    "ja": "ミールプレップ"
                },
                "SPECIALTY_FOOD": {
                    "en": "Specialty Food",
                    "ja": "特産食品"
                },
                "EVENT_CATERING": {
                    "en": "Event Catering",
                    "ja": "イベントケータリング"
                },
                "LOCALS_TRANSPORTATION": {
                    "en": "Transportation",
                    "ja": "交通サービス"
                },
                "TAXI_SERVICES": {
                    "en": "Taxi Services",
                    "ja": "タクシーサービス"
                },
                "RIDE_SHARING": {
                    "en": "Ride Sharing",
                    "ja": "ライドシェア"
                },
                "MOVING_SERVICES": {
                    "en": "Moving Services",
                    "ja": "引っ越しサービス"
                },
                "COURIER_DELIVERY": {
                    "en": "Courier & Delivery",
                    "ja": "宅配サービス"
                },
                "CAR_RENTAL": {
                    "en": "Car Rental",
                    "ja": "レンタカー"
                },
                "LOCALS_HEALTH_FITNESS": {
                    "en": "Health & Fitness",
                    "ja": "健康とフィットネス"
                },
                "PERSONAL_TRAINING": {
                    "en": "Personal Training",
                    "ja": "パーソナルトレーニング"
                },
                "GYMS": {
                    "en": "Gyms",
                    "ja": "ジム"
                },
                "NUTRITIONISTS": {
                    "en": "Nutritionists",
                    "ja": "栄養士"
                },
                "YOGA_INSTRUCTORS": {
                    "en": "Yoga Instructors",
                    "ja": "ヨガインストラクター"
                },
                "PHYSICAL_THERAPY": {
                    "en": "Physical Therapy",
                    "ja": "理学療法"
                },
                "MASSAGE_THERAPY": {
                    "en": "Massage Therapy",
                    "ja": "マッサージ療法"
                },
                "LOCALS_EVENTS": {
                    "en": "Events",
                    "ja": "イベント"
                },
                "WEDDINGS": {
                    "en": "Weddings",
                    "ja": "結婚式"
                },
                "BIRTHDAY_PARTIES": {
                    "en": "Birthday Parties",
                    "ja": "誕生日パーティー"
                },
                "CORPORATE_EVENTS": {
                    "en": "Corporate Events",
                    "ja": "企業イベント"
                },
                "CONFERENCES": {
                    "en": "Conferences",
                    "ja": "会議"
                },
                "FUNDRAISERS": {
                    "en": "Fundraisers",
                    "ja": "資金調達イベント"
                },
                "ENTERTAINMENT_BOOKING": {
                    "en": "Entertainment Booking",
                    "ja": "エンターテインメントブッキング"
                },
                "EVENT_PLANNING": {
                    "en": "Event Planning",
                    "ja": "イベントプランニング"
                },
                "LOCALS_LOCAL_MARKETING": {
                    "en": "Local Marketing",
                    "ja": "ローカルマーケティング"
                },
                "SEO_SERVICES": {
                    "en": "SEO Services",
                    "ja": "SEOサービス"
                },
                "SOCIAL_MEDIA_MANAGEMENT": {
                    "en": "Social Media Management",
                    "ja": "ソーシャルメディア管理"
                },
                "EMAIL_MARKETING": {
                    "en": "Email Marketing",
                    "ja": "Eメールマーケティング"
                },
                "CONTENT_WRITING": {
                    "en": "Content Writing",
                    "ja": "コンテンツライティング"
                },
                "AD_CAMPAIGN_MANAGEMENT": {
                    "en": "Ad Campaign Management",
                    "ja": "広告キャンペーン管理"
                },
                "LOCALS_EDUCATION_TUTORING": {
                    "en": "Education & Tutoring",
                    "ja": "教育とチュータリング"
                },
                "TUTORING": {
                    "en": "Tutoring",
                    "ja": "家庭教師"
                },
                "LANGUAGE_CLASSES": {
                    "en": "Language Classes",
                    "ja": "語学教室"
                },
                "MUSIC_CLASSES": {
                    "en": "Music Classes",
                    "ja": "音楽教室"
                },
                "ART_CLASSES": {
                    "en": "Art Classes",
                    "ja": "美術教室"
                },
                "MATH_SCIENCE_TUTORING": {
                    "en": "Math & Science Tutoring",
                    "ja": "数学と科学の家庭教師"
                },
                "ONLINE_COURSES": {
                    "en": "Online Courses",
                    "ja": "オンラインコース"
                },
                "TEST_PREPARATION": {
                    "en": "Test Preparation",
                    "ja": "試験準備"
                },
                "LOCALS_SOCIAL_SERVICES": {
                    "en": "Social Services",
                    "ja": "ソーシャルサービス"
                },
                "ELDERLY_CARE": {
                    "en": "Elderly Care",
                    "ja": "高齢者介護"
                },
                "CHILDCARE": {
                    "en": "Childcare",
                    "ja": "保育"
                },
                "PET_CARE": {
                    "en": "Pet Care",
                    "ja": "ペットケア"
                },
                "MENTAL_HEALTH_SUPPORT": {
                    "en": "Mental Health Support",
                    "ja": "メンタルヘルスサポート"
                },
                "DISABILITY_SERVICES": {
                    "en": "Disability Services",
                    "ja": "障害者サービス"
                },
                "COMMUNITY_OUTREACH": {
                    "en": "Community Outreach",
                    "ja": "地域アウトリーチ"
                },
                "LOCALS_TECHNOLOGY_SERVICES": {
                    "en": "Technology Services",
                    "ja": "技術サービス"
                },
                "IT_SUPPORT": {
                    "en": "IT Support",
                    "ja": "ITサポート"
                },
                "NETWORK_SETUP": {
                    "en": "Network Setup",
                    "ja": "ネットワーク設定"
                },
                "SOFTWARE_INSTALLATION": {
                    "en": "Software Installation",
                    "ja": "ソフトウェアのインストール"
                },
                "HARDWARE_REPAIR": {
                    "en": "Hardware Repair",
                    "ja": "ハードウェア修理"
                },
                "CYBERSECURITY": {
                    "en": "Cybersecurity",
                    "ja": "サイバーセキュリティ"
                },
                "TECH_CONSULTING": {
                    "en": "Tech Consulting",
                    "ja": "技術コンサルティング"
                },
                "JOBS_AI_ENGINEER": {
                    "en": "AI Engineer",
                    "ja": "AIエンジニア"
                },
                "JOBS_DATA_SCIENTIST": {
                    "en": "Data Scientist",
                    "ja": "データサイエンティスト"
                },
                "JOBS_SOFTWARE_ENGINEERING": {
                    "en": "Software Engineering",
                    "ja": "ソフトウェアエンジニアリング"
                },
                "JOBS_ENGINEERING_ARCHITECTURE": {
                    "en": "Engineering & Architecture",
                    "ja": "エンジニアリングと建築"
                },
                "JOBS_DIGITAL_MARKETING": {
                    "en": "Digital Marketing",
                    "ja": "デジタルマーケティング"
                },
                "JOBS_GRAPHIC_DESIGN": {
                    "en": "Graphic Design",
                    "ja": "グラフィックデザイン"
                },
                "JOBS_PRODUCT_DESIGN": {
                    "en": "Product Design",
                    "ja": "製品デザイン"
                },
                "JOBS_MUSIC_AUDIO": {
                    "en": "Music & Audio",
                    "ja": "音楽とオーディオ"
                },
                "JOBS_FILM_TV": {
                    "en": "Film & TV",
                    "ja": "映画とテレビ"
                },
                "JOBS_CONTENT_CREATION": {
                    "en": "Content Creation",
                    "ja": "コンテンツ作成"
                },
                "JOBS_SALES": {
                    "en": "Sales",
                    "ja": "営業"
                },
                "JOBS_EDUCATION_ACADEMIA": {
                    "en": "Education & Academia",
                    "ja": "教育と学問"
                },
                "JOBS_BUSINESS_DEVELOPMENT": {
                    "en": "Business Development",
                    "ja": "ビジネス開発"
                },
                "JOBS_PROJECT_MANAGEMENT": {
                    "en": "Project Management",
                    "ja": "プロジェクト管理"
                },
                "JOBS_CUSTOMER_SERVICE": {
                    "en": "Customer Service",
                    "ja": "カスタマーサービス"
                },
                "JOBS_C_LEVEL_SVP": {
                    "en": "C-Level & SVP",
                    "ja": "CレベルとSVP"
                },
                "JOBS_HR_RECRUITING": {
                    "en": "HR & Recruiting",
                    "ja": "人事と採用"
                },
                "JOBS_LANGUAGES_TRANSLATION": {
                    "en": "Languages & Translation",
                    "ja": "言語と翻訳"
                },
                "JOBS_LEGAL": {
                    "en": "Legal",
                    "ja": "法務"
                },
                "JOBS_FINANCE_ACCOUNTING": {
                    "en": "Finance & Accounting",
                    "ja": "財務と会計"
                },
                "JOBS_OTHER": {
                    "en": "Other Jobs",
                    "ja": "その他の仕事"
                },
                "STORES_WOMENS_CLOTHING": {
                    "en": "Women's Clothing",
                    "ja": "婦人服"
                },
                "STORES_WOMENS_CLOTHING_DRESSES": {
                    "en": "Dresses",
                    "ja": "ドレス"
                },
                "STORES_WOMENS_CLOTHING_TOPS": {
                    "en": "Tops",
                    "ja": "トップス"
                },
                "STORES_WOMENS_CLOTHING_BOTTOMS": {
                    "en": "Bottoms",
                    "ja": "ボトムス"
                },
                "STORES_WOMENS_CLOTHING_OUTERWEAR": {
                    "en": "Outerwear",
                    "ja": "アウター"
                },
                "STORES_WOMENS_CLOTHING_ACCESSORIES": {
                    "en": "Accessories",
                    "ja": "アクセサリー"
                },
                "STORES_WOMENS_CLOTHING_SHOES": {
                    "en": "Shoes",
                    "ja": "靴"
                },
                "STORES_WOMENS_CLOTHING_BAGS": {
                    "en": "Bags",
                    "ja": "バッグ"
                },
                "STORES_WOMENS_CLOTHING_LINGERIE": {
                    "en": "Lingerie",
                    "ja": "ランジェリー"
                },
                "STORES_MENS_CLOTHING": {
                    "en": "Men's Clothing",
                    "ja": "紳士服"
                },
                "STORES_MENS_CLOTHING_SHIRTS": {
                    "en": "Shirts",
                    "ja": "シャツ"
                },
                "STORES_MENS_CLOTHING_PANTS": {
                    "en": "Pants",
                    "ja": "ズボン"
                },
                "STORES_MENS_CLOTHING_SUITS": {
                    "en": "Suits",
                    "ja": "スーツ"
                },
                "STORES_MENS_CLOTHING_JACKETS": {
                    "en": "Jackets",
                    "ja": "ジャケット"
                },
                "STORES_MENS_CLOTHING_ACCESSORIES": {
                    "en": "Accessories",
                    "ja": "アクセサリー"
                },
                "STORES_MENS_CLOTHING_SHOES": {
                    "en": "Shoes",
                    "ja": "靴"
                },
                "STORES_MENS_CLOTHING_SPORTSWEAR": {
                    "en": "Sportswear",
                    "ja": "スポーツウェア"
                },
                "STORES_MENS_CLOTHING_UNDERWEAR": {
                    "en": "Underwear",
                    "ja": "下着"
                },
                "STORES_JEWELRY": {
                    "en": "Jewelry",
                    "ja": "ジュエリー"
                },
                "STORES_JEWELRY_NECKLACES": {
                    "en": "Necklaces",
                    "ja": "ネックレス"
                },
                "STORES_JEWELRY_BRACELETS": {
                    "en": "Bracelets",
                    "ja": "ブレスレット"
                },
                "STORES_JEWELRY_EARRINGS": {
                    "en": "Earrings",
                    "ja": "イヤリング"
                },
                "STORES_JEWELRY_RINGS": {
                    "en": "Rings",
                    "ja": "指輪"
                },
                "STORES_JEWELRY_WATCHES": {
                    "en": "Watches",
                    "ja": "時計"
                },
                "STORES_JEWELRY_BROOCHES": {
                    "en": "Brooches",
                    "ja": "ブローチ"
                },
                "STORES_JEWELRY_CUSTOM_JEWELRY": {
                    "en": "Custom Jewelry",
                    "ja": "カスタムジュエリー"
                },
                "STORES_JEWELRY_WEDDING_JEWELRY": {
                    "en": "Wedding Jewelry",
                    "ja": "結婚指輪"
                },
                "STORES_COMPUTERS_LAPTOPS": {
                    "en": "Computers & Laptops",
                    "ja": "コンピュータとノートパソコン"
                },
                "STORES_COMPUTERS_LAPTOPS_DESKTOP_COMPUTERS": {
                    "en": "Desktop Computers",
                    "ja": "デスクトップコンピュータ"
                },
                "STORES_COMPUTERS_LAPTOPS_LAPTOPS": {
                    "en": "Laptops",
                    "ja": "ノートパソコン"
                },
                "STORES_COMPUTERS_LAPTOPS_TABLETS": {
                    "en": "Tablets",
                    "ja": "タブレット"
                },
                "STORES_COMPUTERS_LAPTOPS_MONITORS": {
                    "en": "Monitors",
                    "ja": "モニター"
                },
                "STORES_COMPUTERS_LAPTOPS_ACCESSORIES": {
                    "en": "Accessories",
                    "ja": "アクセサリー"
                },
                "STORES_COMPUTERS_LAPTOPS_PRINTERS": {
                    "en": "Printers",
                    "ja": "プリンター"
                },
                "STORES_COMPUTERS_LAPTOPS_COMPUTER_PARTS": {
                    "en": "Computer Parts",
                    "ja": "コンピュータ部品"
                },
                "STORES_ELECTRONICS": {
                    "en": "Electronics",
                    "ja": "電子機器"
                },
                "STORES_ELECTRONICS_SMARTPHONES": {
                    "en": "Smartphones",
                    "ja": "スマートフォン"
                },
                "STORES_ELECTRONICS_HOME_ENTERTAINMENT": {
                    "en": "Home Entertainment",
                    "ja": "ホームエンターテイメント"
                },
                "STORES_ELECTRONICS_CAMERAS": {
                    "en": "Cameras",
                    "ja": "カメラ"
                },
                "STORES_ELECTRONICS_DRONES": {
                    "en": "Drones",
                    "ja": "ドローン"
                },
                "STORES_ELECTRONICS_AUDIO_EQUIPMENT": {
                    "en": "Audio Equipment",
                    "ja": "オーディオ機器"
                },
                "STORES_ELECTRONICS_VIDEO_EQUIPMENT": {
                    "en": "Video Equipment",
                    "ja": "ビデオ機器"
                },
                "STORES_ELECTRONICS_GAMING_CONSOLES": {
                    "en": "Gaming Consoles",
                    "ja": "ゲーム機"
                },
                "STORES_ELECTRONICS_SMART_HOME_DEVICES": {
                    "en": "Smart Home Devices",
                    "ja": "スマートホームデバイス"
                },
                "HOME_ABOUT_HEADING": {
                    "en": "About",
                    "ja": "について"
                },
                "HOME_ABOUT_HEADING_1": {
                    "en": "equityDAO ",
                    "ja": "インヴォーク・ネットワーク"
                },
                "HOME_HEADER_1": {
                    "en": "Join us in building a fair ads network that respects user privacy and rewards all.",
                    "ja": "ユーザーのプライバシーを尊重し、すべての人に報酬を与える公正な広告ネットワークを構築します。"
                },
                "HOME_HEADER_2": {
                    "en": "Protect your users privacy and monetize your platform with high performance ads.",
                    "ja": "ユーザーのプライバシーを保護し、高いパフォーマンスの広告でプラットフォームを収益化します。"
                },
                "HOME_HEADING_1_1": {
                    "en": "beyond.",
                    "ja": "未来を拓く。"
                },
                "LOGIN_ALREADY_REGISTERED_EMAIL": {
                    "en": "email already registered",
                    "ja": "メールがすでに登録されています。"
                },
                "LOGIN_CONNECT": {
                    "en": "Login / Connect",
                    "ja": "ログイン / 接続"
                },
                "LOGIN_CONNECT_WALLET": {
                    "en": "Connect a Wallet",
                    "ja": "ウォレットを接続する"
                },
                "LOGIN_EMAIL_AVAILABLE": {
                    "en": "Email Available",
                    "ja": "メール利用可能"
                },
                "LOGIN_FAILED": {
                    "en": "Login Failed.",
                    "ja": "ログインできない"
                },
                "LOGIN_INVALID_EMAIL": {
                    "en": "Please enter a valid email address",
                    "ja": "有効なEメールアドレスを入力してください。"
                },
                "LOGIN_INVALID_PASSWORD": {
                    "en": "Please enter more than 6 password characters.",
                    "ja": "6文字以上のパスワードを入力してください。"
                },
                "LOGIN_OR_LOGIN": {
                    "en": "Or Login",
                    "ja": "またはログイン"
                },
                "SIGN_UP_WALLET_ADDRESS": {
                    "en": "Your Wallet Address",
                    "ja": "作成者（サインイン）のウォレットアドレス"
                },
                "SIGN_UP_WALLET_EXPLAIN": {
                    "en": "Please Login to set your wallet address.",
                    "ja": "ログインしてウォレットアドレスを設定してください"
                },
                "SIGN_UP_DOMAIN_EXPLAIN": {
                    "en": "Please enter alpha numeric characters between 2 and 20 characters.",
                    "ja": "（2文字以上20文字以内）※「\\」「?」「&」「%」などの記号は使用不可。"
                },
                "SIGN_UP_CHECK_ALL": {
                    "en": "Please ensure all fields are entered correctly.",
                    "ja": "すべてのフィールドが正しく入力されていることを確認してください。"
                },
                "LAZY_MINT_SIGN_ALL_NFTS": {
                    "en": "Lazy Mint In progress, please wait and sign ALL transactions. Each item needs to be signed.",
                    "ja": "Lazy Mint 進行中です。待ってからすべてのトランザクションに署名してください。 各項目には署名が必要です。"
                },
                "ADD_TO_CART" : {
                    "en": "Add to cart.",
                    "ja": "買い物かごに追加します."
                },
                "VAT_INCLUDED" : {
                    "en": "VAT included in selected territories.",
                    "ja": "消費税込み."
                },
                "SOLD_BY" : {
                    "en": "Sold by",
                    "ja": "選択した地域では消費税が含まれています。"
                },
                "STOCK_LEFT" : {
                    "en": "Stock left",
                    "ja": "在庫量"
                }, 
                "MAIN_BANNER_TEXT": {
                    "en": "Work, Learn and Earn with Crypto.",
                    "ja": "働くか雇うか、暗号通貨で稼ぐ。"
                },
                "MAIN_BANNER_SUBTEXT": {
                    "en": "The decentralized marketplace for freelancers and services.",
                    "ja": "オンラインでの仕事と教師のための分散型マーケットプレイス。"
                },
                "JOIN_US_SOCIAL": {
                    "en": "Join us on social media.",
                    "ja": "ソーシャルメディアに参加しましょう。"
                },
                "TEXT_P_SERVICES": {
                    "en": "'s Services",
                    "ja": "のサービス"
                },
                "TEXT_CARROTS": {
                    "en": "Carrots",
                    "ja": "ニンジン"
                },
                "CLAIM_CARROTS": {
                    "en": "Claim your carrots.",
                    "ja": "ニンジンを主張する"
                },
                "ABOUT_CLAIM_CARROTS": {
                    "en": "Thumpr token will be coming soon enabling free transactions, collect carrots to show participation by signing up ! List your services and use the platform get more !",
                    "ja": "Thumpr は無料取引を可能にするユーティリティ トークンをリリースする予定です。それまでは、サインアップしてニンジンを集めることで報酬を受け取ることができます。サービスをリストし、プラットフォームを活用するとニンジンが増えますので、ぜひ始めてください。"
                },
                "CLEAR_PRICING": {
                    "en": "please clear with seller",
                    "ja": "販売者に価格を確認してください"
                },
                "PLACE_ORDER_FAILED": {
                    "en": "Failed to place Order please contact @ contact@thumpr.xyz",
                    "ja": "注文に失敗しました。contact@thumpr.xyz までご連絡ください。"
                },
                "PLACE_ORDER_SUCCESS": {
                    "en": "Order request made successfully.",
                    "ja": "注文リクエストが正常に完了しました。"
                },
                "TEXT_DISABLED": {
                    "en": "Disabled",
                    "ja": "無効"
                },
                "TEXT_ENABLED": {
                    "en": "Enabled",
                    "ja": "有効"
                },
                "TEXT_HOURS": {
                    "en": "Hours",
                    "ja": "時間"
                },
                "TEXT_FIXED_RATE": {
                    "en": "Fixed Rate.",
                    "ja": "固定レート。"
                },
                "TEXT_HOUR": {
                    "en": "Hour.",
                    "ja": "1時間当たり"
                },
                "TEXT_HOURLY_RATE": {
                    "en": "Hourly Rate.",
                    "ja": "時間給。"
                },
                "TEXT_SPONSOR": {
                    "en": "Click to support a childs education.",
                    "ja": "子どもの教育を支援します。"
                },
                "TEXT_BACK_TO_ORDERS": {
                    "en": "Back to orders.",
                    "ja": "注文ページに戻る"
                },
                "TEXT_NO_OPEN_ORDERS": {
                    "en": "No Open Orders",
                    "ja": "見つかりません"
                },
                "TEXT_NO_ORDER_REQUESTS": {
                    "en": "No Order Requests",
                    "ja": "見つかりません"
                },
                "TEXT_NO_ORDER_DISPUTES": {
                    "en": "No Order Disputes",
                    "ja": "見つかりません"
                },
                "TEXT_DISPUTED": {
                    "en": "Disputed",
                    "ja": "論争"
                },
                "TEXT_DISPUTE_ORDER": {
                    "en": "Dispute Order",
                    "ja": "論争"
                },
                "RAISE_DISPUTE_MESSAGE_1": {
                    "en": "Before raising a dispute, please discuss with your client and agree on an dispute amount. The funds for this task are currently locked in the smart contract until both you and the seller accepts the dispute amount.",
                    "ja": "異議を申し立てる前に、クライアントと話し合い、異議申し立て金額について合意してください。このタスクの資金は、あなたと販売者の両方が異議申し立て金額を承認するまで、現在スマート コントラクトにロックされています。"
                },
                "RAISE_DISPUTE_MESSAGE_2": {
                    "en": "Set the percentage dispute amount below for this order.",
                    "ja": "この注文の紛争金額の割合を以下に設定します。"
                },
                "RAISE_DISPUTE_MESSAGE_3": {
                    "en": "A dispute has been raised for this order, please either accept or discuss with your client by opening the order. The funds for this job are currently frozen in the smart contract meaning neither you or the client has payment until the dispute is resolved.",
                    "ja": "この注文に関して紛争が発生しました。注文を開いて承認するか、クライアントと話し合ってください。このジョブの資金は現在スマート コントラクトで凍結されており、紛争が解決されるまではあなたまたはクライアントのいずれかが支払いを受けることになります。"
                },
                "RAISE_DISPUTE_MESSAGE_4": {
                    "en": "Please select a new dispute amount.",
                    "ja": "新しい異議申し立て金額を選択してください。"
                },
                "TEXT_DISPUTE_ORDER_FOR": {
                    "en": "Dispute order ",
                    "ja": "紛争率"
                },
                "TEXT_UPDATE_DISPUTE": {
                    "en": "Update Dispute",
                    "ja": "更新紛争"
                },
                "TEXT_ACCEPT_DISPUTE_FOR": {
                    "en": "Accept dispute ",
                    "ja": "異議を受け入れる"
                },
                "TEXT_DISPUTE_ORDER_FOR": {
                    "en": "Dispute order ",
                    "ja": "紛争命令"
                },
                "TEXT_NO_COMPLETED_ORDERS": {
                    "en": "No Completed Orders",
                    "ja": "見つかりません"
                },
                "TEXT_DELIVER": {
                    "en": "Deliver",
                    "ja": "届ける"
                },
                "TEXT_REJECT": {
                    "en": "Reject",
                    "ja": "拒否する"
                },
                "TEXT_CANCEL": {
                    "en": "Cancel",
                    "ja": "キャンセル"
                },
                "TEXT_SELLER": {
                    "en": "Seller",
                    "ja": "売り手"
                },
                "TEXT_BUYER": {
                    "en": "Buyer",
                    "ja": "買い手"
                },
                "TEXT_ORDERS": {
                    "en": "Orders",
                    "ja": "注文"
                },
                "TEXT_ORDER_NO": {
                    "en": "Order No.",
                    "ja": "注文番号"
                },
                "TEXT_DELIVERY": {
                    "en": "Delivery",
                    "ja": "配送"
                },
                "TEXT_PENDING": {
                    "en": "Pending",
                    "ja": "保留中"
                },
                "TEXT_CUSTOM_PRICING": {
                    "en": "Custom Pricing",
                    "ja": "カスタム価格注文"
                },
                "TEXT_PLACE_ORDER": {
                    "en": "Place Order",
                    "ja": "注文する"
                },
                "TEXT_ONLINE": {
                    "en": "Online",
                    "ja": "オンライン"
                },
                "TEXT_LOCAL": {
                    "en": "Local",
                    "ja": "ローカル"
                },
                "TEXT_OVERVIEW": {
                    "en": "Overview",
                    "ja": "作成する"
                },
                "TEXT_PURCHASE": {
                    "en": "Purchase",
                    "ja": "購入"
                },
                "TEXT_MY_LISTINGS": {
                    "en": "My Listings.",
                    "ja": "私のリスト"
                },
                "TEXT_VIEW_AS_MEMBER": {
                    "en": "View as Member.",
                    "ja": "顧客視点"
                },
                "SERVICE_BANNER_TEXT": {
                    "en": "Create Todays Inspiration!",
                    "ja": "今日のインスピレーションを創造しよう！"
                },
                "TEXT_MESSAGES": {
                    "en": "Messages",
                    "ja": "メッセージ"
                },
                "TEXT_CREATE": {
                    "en": "Create",
                    "ja": "作る"
                },
                "TEXT_COMPLETE": {
                    "en": "Complete",
                    "ja": "完了する"
                },
                "TEXT_DISPUTE": {
                    "en": "Dispute",
                    "ja": "論争する"
                },
                "TEXT_CONVERSATIONS": {
                    "en": "Conversations",
                    "ja": "チャット会話"
                },
                "TEXT_ABOUT_ME": {
                    "en": "About Me",
                    "ja": "いらっしゃいませ"
                },
                "TEXT_PROMOTIONAL_VIDEO": {
                    "en": "Promotional Video",
                    "ja": "プロモーションビデオ"
                },
                "TEXT_MAIN_DESCRIPTION": {
                    "en": "Service Description",
                    "ja": "ギグの説明"
                },
                "TEXT_DAYS_DUE": {
                    "en": "days for delivery",
                    "ja": "完了までの日数"
                },
                "TEXT_REVISIONS": {
                    "en": "Revisions",
                    "ja": "変更可能"
                },
                "TEXT_DAYS_REVISIONS": {
                    "en": "revisions",
                    "ja": "修正可能"
                },
                "TEXT_ADD": {
                    "en": "Add",
                    "ja": "する"
                },
                "TEXT_OFFERINGS": {
                    "en": "Offerings",
                    "ja": "オファー"
                },
                "TEXT_OFFERS": {
                    "en": "Offers",
                    "ja": "オファー"
                },
                "TEXT_PREVIEW": {
                    "en": "Preview",
                    "ja": "プレビュー"
                },
                "TEXT_PREVIEW_DESCRIPTION": {
                    "en": "Preview Description",
                    "ja": "プレビュー"
                },
                "TEXT_FEATURES": {
                    "en": "Offer Features",
                    "ja": "オファーの利点"
                },
                "TEXT_OFFER_DESCRIPTION": {
                    "en": "Offer Description",
                    "ja": "オファーの説明"
                },
                "TEXT_SERVICE_DESCRIPTION": {
                    "en": "What I provide ..",
                    "ja": "提供されるサービス"
                },
                "TEXT_SUCCESS": {
                    "en": "Success",
                    "ja": "完了です"
                },
                "TEXT_TITLE": {
                    "en": "Title",
                    "ja": ""
                },
                "TEXT_SELECT_TOKEN": {
                    "en": "Select Token",
                    "ja": "トークンを選択"
                },
                "TEXT_SELECT_METAL": {
                    "en": "Select Metal",
                    "ja": "金属を選択してください"
                },
                "TEXT_RECEIVE" : {
                    "en": "Receive",
                    "ja": "受け取る"
                },
                "TEXT_SET" : {
                    "en": "Set",
                    "ja": "セット"
                },
                "TEXT_SELL" : {
                    "en": "Sell",
                    "ja": "売る"
                },
                "TEXT_PAY" : {
                    "en": "Pay",
                    "ja": "支払う"
                },
                "TEXT_CHOOSE_TOKEN" : {
                    "en": "Choose Token",
                    "ja": "トークンを選択"
                },
                "TEXT_ON_CHAIN" : {
                    "en": "On Chain",
                    "ja": "オンチェーン"
                },
                "TEXT_SWAP" : {
                    "en": "Swap",
                    "ja": "スワップ"
                },
                "TEXT_ACCESS_NFT" : {
                    "en": "Access NFT",
                    "ja": "NFTにアクセスする"
                },
                "TEXT_MINT_MORE" : {
                    "en": "Mint Again",
                    "ja": "ミント・アゲイン"
                },
                "TEXT_TOKEN_MINTED" : {
                    "en": "Token Minted",
                    "ja": "トークン鋳造"
                },
                "TEXT_INSUFFICIENT_BALANCE" : {
                    "en": "Insufficient Balance",
                    "ja": "残高不足"
                },
                "TEXT_TOKEN_MINT_SUPPLY" : {
                    "en": "Token Mint Supply",
                    "ja": "トークンミントの供給"
                },
                "TEXT_NEXT" : {
                    "en": "Next",
                    "ja": "次"
                },
                "TEXT_PREV" : {
                    "en": "Previous",
                    "ja": "前の"
                },
                "TEXT_TOKEN_NAME" : {
                    "en": "Token Name",
                    "ja": "トークン名"
                },
                "TEXT_TOKEN_SYMBOL" : {
                    "en": "Symbol",
                    "ja": "シンボル"
                },
                "TEXT_ANOTHER_TOKEN" : {
                    "en": "Load Another Token",
                    "ja": "別のトークンをロードする"
                },
                "TEXT_TOKEN_SUPPLY" : {
                    "en": "Initial Supply",
                    "ja": "初期供給"
                },
                "TEXT_TOKEN_TO" : {
                    "en": "To Address",
                    "ja": "宛先アドレス"
                },
                "TEXT_TOKEN_ADDRESS" : {
                    "en": "Token Address",
                    "ja": "トークンアドレス"
                },
                "TEXT_TOKEN_AMOUNT" : {
                    "en": "Transfer Amount",
                    "ja": "払込金額"
                },
                "TEXT_SEND_TOKEN" : {
                    "en": "Send",
                    "ja": "トークンを送信する"
                },
                "TEXT_SENDING_FILE" : {
                    "en": "Sending File",
                    "ja": "ファイルのアップロード進行中"
                },
                "TEXT_TOKEN_DESCRIPTION" : {
                    "en": "Token Description",
                    "ja": "トークンの説明"
                },
                "CREATE_ERC20_DEFINE" : {
                    "en": "Define Token Properties.",
                    "ja": "トークンのプロパティを定義します。"
                },
                "TEXT_CREATE_CRYPTO_TOKEN" : {
                    "en": "Create Cryptocurrency Token",
                    "ja": "暗号通貨トークンを作成する"
                },
                "TEXT_CREATE_TOKEN" : {
                    "en": "Create Token",
                    "ja": "トークンの作成"
                },
                "TEXT_TOKEN_NAME_PLACEHOLDER" : {
                    "en": "My Token",
                    "ja": "私のトークン"
                },
                "TEXT_TOKEN_SYMBOL_PLACEHOLDER" : {
                    "en": "MYT",
                    "ja": "MYT"
                },
                "TEXT_TOKEN_SUPPLYPLACEHOLDER" : {
                    "en": "1000000000",
                    "ja": "1000000000"
                },
                "TEXT_TOKEN_TO_PLACEHOLDER" : {
                    "en": "0x...",
                    "ja": "0x..."
                },
                "TEXT_TOKEN_ADDRESS_PLACEHOLDER" : {
                    "en": "0x...",
                    "ja": "0x..."
                },
                "TEXT_TOKEN_AMOUNT_PLACEHOLDER" : {
                    "en": "...",
                    "ja": "..."
                },
                "TEXT_TOKEN_DESCRIPTION_PLACEHOLDER" : {
                    "en": "My Token Description",
                    "ja": "このトークンは.."
                },
                "TEXT_MINT_SUPPLY" : {
                    "en": "Token Mint Supply",
                    "ja": "トークンミントの供給"
                },
                "TEXT_LANGUAGES" : {
                    "en": "Languages",
                    "ja": "言語"
                },
                "TEXT_LANG_CODE_EN" : {
                    "en": "English",
                    "ja": "英語"
                },
                "TEXT_LANG_CODE_ES" : {
                    "en": "Spanish",
                    "ja": "スペイン語"
                },
                "TEXT_LANG_CODE_JA" : {
                    "en": "Japanese",
                    "ja": "日本語"
                },
                "TEXT_LANG_CODE_ZH" : {
                    "en": "Chinese",
                    "ja": "中国語"
                },
                "TEXT_LANG_CODE_KO" : {
                    "en": "Korean",
                    "ja": "韓国語"
                },
                "TEXT_LANG_CODE_DE" : {
                    "en": "German",
                    "ja": "ドイツ語"
                },
                "TEXT_LANG_CODE_FR" : {
                    "en": "French",
                    "ja": "フランス語"
                },
                "TEXT_LANG_CODE_RU" : {
                    "en": "Russian",
                    "ja": "ロシア語"
                },
                "TEXT_LANG_CODE_UA" : {
                    "en": "Ukranian",
                    "ja": "ウクライナ語"
                },
                "TEXT_CREATE_LISTING": {
                    "en": "Make a Listing",
                    "ja": ""
                },
                "TEXT_PRODUCTS" : {
                    "en": "Products",
                    "ja": "製品"
                },
                "TEXT_QUANTITY" : {
                    "en": "Quantity",
                    "ja": "製品の量"
                },
                "CREATE_PRODUCT_TITLE" : {
                    "en": "Define Product",
                    "ja": "製品を定義する"
                },
                "TEXT_PRODUCT_NAME" : {
                    "en": "Product Name",
                    "ja": "商品名"
                },
                "TEXT_PRODUCT_SYNOPSIS" : {
                    "en": "Product Synopsis.",
                    "ja": "製品概要"
                },
                "TEXT_PRODUCT_SYNOPSIS_PLACEHOLDER" : {
                    "en": "eg. Luxury Gold Necklace.",
                    "ja": "例えば。 高級感のあるゴールドのネックレス。"
                },
                "TEXT_PRODUCT_DESCRIPTION" : {
                    "en": "Product Description.",
                    "ja": "製品説明。"
                },
                "TEXT_CURRENCY" : {
                    "en": "Curreny.",
                    "ja": "通貨単位"
                },
                "TEXT_PRODUCT_PRICE" : {
                    "en": "Price",
                    "ja": "価格"
                },
                "TEXT_PRODUCT_STOCK" : {
                    "en": "Stock",
                    "ja": "在庫"
                },
                "TEXT_NEW_CUSTOMIZATION" : {
                    "en": "Customization Name.",
                    "ja": "属性名"
                },
                "TEXT_NEW_CUSTOMIZATION_PLACEHOLDER" : {
                    "en": "eg. size.",
                    "ja": "例えば。 サイズ"
                },
                "TEXT_ADD_CUSTOMIZATION" : {
                    "en": "Add",
                    "ja": "作成する"
                },
                "TEXT_PRICE_MOD" : {
                    "en": "Price Modification.",
                    "ja": "価格変更。"
                },
                "TEXT_PRICE_MOD_PLACEHOLDER" : {
                    "en": "0.00",
                    "ja": "0"
                },
                "TEXT_PRODUCT_VALUE_PLACEHOLDER" : {
                    "en": "22 inch (55cm)",
                    "ja": "22インチ(55cm)"
                },
                "TEXT_DECLINE" : {
                    "en": "Decline",
                    "ja": "拒否する"
                },
                "TEXT_DELETE_PRODUCT" : {
                    "en": "Delete Product",
                    "ja": "製品を削除する"
                },
                "TEXT_ANOTHER_PRODUCT" : {
                    "en": "Load Another Product.",
                    "ja": "別の製品をロードする"
                },
                "TEXT_NEW_PRODUCT" : {
                    "en": "Add a New Product",
                    "ja": "新しい製品を追加する"
                },
                "TEXT_DETAILS" : {
                    "en": "Details",
                    "ja": "ディテール"
                },
                "TEXT_PUBLIC_MINT_LINK" : {
                    "en": "public minting link",
                    "ja": "お客様のミントリンク"
                },
                "TEXT_MINTED" : {
                    "en": "Minted",
                    "ja": "鋳造されました"
                },
                "TEXT_VOUCHER" : {
                    "en": "Voucher",
                    "ja": "バウチャー"
                },
                "TEXT_VOUCHER_DETAILS" : {
                    "en": "Voucher Details",
                    "ja": "バウチャーの詳細"
                },
                "TEXT_LAZY_MINT": {
                    "en": "Lazy Mint",
                    "ja": "レイジーミント"
                },
                "TEXT_ADDRESS": {
                    "en": "0x..",
                    "ja": "0x.."
                },
                "TEXT_LAST_PRICE": {
                    "en": "last price",
                    "ja": "最後の販売"
                },
                "TEXT_CONTENT": {
                    "en": "Content",
                    "ja": "コンテンツ"
                },
                "TEXT_CONTINUE": {
                    "en": "Continue",
                    "ja": "購入する"
                },
                "TEXT_FULLSCREEN": {
                    "en": "Fullscreen",
                    "ja": "全画面表示"
                },
                "TEXT_RESET": {
                    "en": "Reset",
                    "ja": "リセット"
                },
                "TEXT_IMAGE": {
                    "en": "Image",
                    "ja": "画像"
                },
                "TEXT_DOWNLOAD": {
                    "en": "Download",
                    "ja": "ダウンロード"
                },
                "TEXT_LOADING": {
                    "en": "Loading",
                    "ja": "ロードング"
                },
                "TEXT_NFT_SUPPLY_PLACEHOLDER": {
                    "en": "eg. 1",
                    "ja": "たとえば 1"
                },
                "TEXT_NFT_TRAIT_PLACEHOLDER": {
                    "en": "eg. item color",
                    "ja": "たとえばアイテムの色"
                },
                "TEXT_NFT_VALUE_PLACEHOLDER": {
                    "en": "eg. red",
                    "ja": "赤"
                },
                "TEXT_NFT_MINTPRICE_PLACEHOLDER": {
                    "en": "eg. 0.1",
                    "ja": "例えば。 0.1"
                },
                "TEXT_DOMAIN_PLACEHOLDER": {
                    "en": "Creator Domain",
                    "ja": "クリエイタードメイン"
                },
                "TEXT_COLLECTION_NAME_PLACEHOLDER": {
                    "en": "Collection Name",
                    "ja": "コレクション名"
                },
                "TEXT_COLLECTION_SYMBOL_PLACEHOLDER": {
                    "en": "Collection Ticker Symbol",
                    "ja": "コレクションティッカーシンボル"
                },
                "TEXT_COLLECTION_DESCRIPTION_PLACEHOLDER": {
                    "en": "Collection Description.",
                    "ja": "コレクションの説明"
                },
                "TEXT_PERMISSION_DENIED": {
                    "en": "Permission Denied",
                    "ja": "許可が拒否されました"
                },
                "TEXT_NFT_NAME_PLACEHOLDER": {
                    "en": "NFT Token name.",
                    "ja": "NFTトークンの名"
                },
                "TEXT_NFT_DESCRIPTION_PLACEHOLDER": {
                    "en": "NFT Token description.",
                    "ja": "NFTトークンの説明"
                },
                "TEXT_NFT_PROPERTIES_CONTENT_MEDIA": {
                    "en": "Media Content",
                    "ja": "メディアコンテンツ "
                },
                "TEXT_NFT_PROPERTIES_SECRET_TITLE": {
                    "en": "Content Media Passphrase",
                    "ja": "コンテンツ メディア パスフレーズ"
                },
                "TEXT_NFT_PROPERTIES_CONTENT": {
                    "en": "Media Content (eg epub / pdf)",
                    "ja": "メディアコンテンツ (epub / pdf など)"
                },
                "TEXT_NFT_PROPERTIES_SECRET": {
                    "en": "empty for open access",
                    "ja": "なしでは公開コンテンツです"
                },
                "TEXT_NFT_PROPERTIES_AUTHOR": {
                    "en": "Author",
                    "ja": "著者"
                },
                "TEXT_NFT_PROPERTIES_ISBN": {
                    "en": "ISBN",
                    "ja": "ISBN"
                },
                "TEXT_NFT_PROPERTIES_LANGUAGE": {
                    "en": "Language",
                    "ja": "言語"
                },
                "TEXT_NFT_PROPERTIES_EDITION": {
                    "en": "Edition No.",
                    "ja": "エディション番号"
                },
                "TEXT_NFT_PROPERTIES_CONTRIBUTORS": {
                    "en": "Contributors",
                    "ja": "貢献者"
                },
                "TEXT_NFT_PROPERTIES_EDITORS": {
                    "en": "Editors",
                    "ja": "編集者"
                },
                "TEXT_DOMAIN": {
                    "en": "Creator domain name",
                    "ja": "作成者のドメイン名"
                },
                "TEXT_SET_WALLET": {
                    "en": "Set Wallet (login)",
                    "ja": "ウォレットの設定（ログイン）"
                },
                "TEXT_SUBMIT": {
                    "en": "Submit",
                    "ja": "送信"
                },
                "TEXT_SUB_CATEGORY": {
                    "en": "Sub Category",
                    "ja": "サブカテゴリー"
                },
                "TEXT_START": {
                    "en": "Start",
                    "ja": "スタート"
                },
                "TEXT_TRENDING_NFTS": {
                    "en": "Trending NFT's",
                    "ja": "トレンディングNFT"
                },
                "TEXT_FREEZING_METADATA": {
                    "en": "Freezing Metadata",
                    "ja": "メタデータの凍結"
                },
                "TEXT_PLEASE_WAIT": {
                    "en": "Please Wait..",
                    "ja": "お待ちください"
                },
                "TEXT_CHANGE_CHAIN": {
                    "en": "Change Chain",
                    "ja": "チェーンを変更"
                },
                "TEXT_CREATE_NFT": {
                    "en": "Create NFT",
                    "ja": "NFTを作る"
                },
                "CREATE_NFT_INVALID_DATA_SIZE": {
                    "en": "File too Large, maximum files size in 100MB",
                    "ja": "ファイルが大きすぎます。最大ファイルサイズは 100MB です"
                },
                "CREATE_NFT_INVALID_DATA_BOOK": {
                    "en": "Invalid file type, valid types are PDF and EPUB",
                    "ja": "無効なファイル タイプです。有効なタイプは PDF と EPUB です"
                },
                "TEXT_POST_UPDATE": {
                    "en": "Post Update",
                    "ja": "ブログを書く"
                },
                "TEXT_EDIT_SOCIAL": {
                    "en": "Edit Social",
                    "ja": "ソーシャルを編集"
                },
                "TEXT_EDIT": {
                    "en": "Edit",
                    "ja": "編集"
                },
                "TEXT_WALLET": {
                    "en": "Wallet",
                    "ja": "ウォレット"
                },
                "TEXT_PROFILE": {
                    "en": "Profile",
                    "ja": "プロフィール"
                },
                "TEXT_ENTER_PRICE_PLACEHOLDER": {
                    "en": "Enter Price",
                    "ja": "価格"
                },
                "TEXT_WALLET_LOGIN": {
                    "en": "Wallet Login",
                    "ja": "ウォレットログイン"
                },
                "TEXT_SOLD": {
                    "en": "Sold",
                    "ja": "売約済み"
                },
                "TEXT_OPEN": {
                    "en": "Open",
                    "ja": "オープン"
                },
                "TEXT_OFFER": {
                    "en": "Offer",
                    "ja": "オファー"
                },
                "TEXT_BEST_OFFER": {
                    "en": "Best Offer",
                    "ja": "ベストオファー"
                },
                "ACCEPT_OFFER_LIST_FIRST": {
                    "en": "Please list this item beofre accepting an offer. This is required because equityDAO is a decentralized marketplace. We advise to list for a very large price to avoid an unwanted purchase. Then accept the original offer.",
                    "ja": "オファーを受ける前に、このNFTを出品してください。「equityDAO」は分散型マーケットプレイスであるため、必須となります。不要な購入を避けるために、非常に大きな価格で出品することをお勧めします。その後、元のオファーを受け入れてください。"
                },
                "TEXT_ACCEPT": {
                    "en": "Accept",
                    "ja": "アクセプト"
                },
                "TEXT_PLACE_OFFER": {
                    "en": "Place Offer",
                    "ja": "オファーする"
                },
                "TEXT_PRICE": {
                    "en": "Price",
                    "ja": "価格"
                },
                "TEXT_CHAIN": {
                    "en": "Chain",
                    "ja": "チェーン"
                },
                "TEXT_OFFER_PRICE": {
                    "en": "Offer Price",
                    "ja": "提供価格"
                },
                "TEXT_OFFERER": {
                    "en": "Offerer",
                    "ja": "提供者"
                },
                "TEXT_STATUS": {
                    "en": "Status",
                    "ja": "スターテス"
                },
                "TEXT_ACTION": {
                    "en": "Action",
                    "ja": "アクション"
                },
                "TEXT_ACCEPTED": {
                    "en": "ACCEPTED",
                    "ja": "受け入れられました"
                },
                "TEXT_SENT": {
                    "en": "Sent",
                    "ja": "送信しました"
                },
                "TEXT_BUY": {
                    "en": "Buy",
                    "ja": "買う"
                },
                "TEXT_LIST": {
                    "en": "List",
                    "ja": "リスト"
                },
                "TEXT_DELIST": {
                    "en": "DeList",
                    "ja": "アンリスト"
                },
                "TEXT_COMING_SOON": {
                    "en": "Coming Soon",
                    "ja": "近日公開"
                },
                "TEXT_COMING_SOON_HEADER": {
                    "en": "Launch Coming Soon",
                    "ja": "近日発売NFTコレクション"
                },
                "TEXT_COMING_SOON_TEXT": {
                    "en": "Buy and sell on Japans Premiere NFT marketplace",
                    "ja": "プレミアNFTマーケットプレイス"
                },
                "TEXT_ADD_TO_COLLECTION": {
                    "en": "Add To Collection",
                    "ja": "コレクションに追加します"
                },
                "TEXT_UPDATE_NFT_COLLECTION": {
                    "en": "Update Collection",
                    "ja": "コレクションを更新する"
                },
                "TEXT_ANOTHER_NFT": {
                    "en": "Load another NFT?",
                    "ja": "保存済みのデータを読み込みますか？"
                },
                "TEXT_BECOME_A_CREATOR": {
                    "en": "Become a creator",
                    "ja": "クリエイターになる"
                },
                "TEXT_WEBSITE": {
                    "en": "Website",
                    "ja": "Webサイト"
                },
                "TEXT_ABOUT": {
                    "en": "About",
                    "ja": "ギガー・ストーについて"
                },
                "TEXT_EMAIL": {
                    "en": "e-mail",
                    "ja": "Eメール"
                },
                "TEXT_EMAIL_PLACEHOLDER": {
                    "en": "Enter your e-mail.",
                    "ja": "Eメールアドレスを入力してください"
                },
                "TEXT_SNS": {
                    "en": "Social Media",
                    "ja": "SNS"
                },
                "TEXT_SNS_PLACEHOLDER": {
                    "en": "Enter Your Social Media / Portfolion link",
                    "ja": "SNSリンク"
                },
                "TEXT_V_TUBER": {
                    "en": "V-tuber",
                    "ja": "V-tuber"
                },
                "TEXT_MANGA": {
                    "en": "Manga",
                    "ja": "マンガ"
                },
                "TEXT_BOOK": {
                    "en": "Book",
                    "ja": "本"
                },
                "TEXT_MESSAGE_PLACEHOLDER": {
                    "en": "Please tell us about your project or concept, we would love to hear from you ! please include links that can help explain your idea.",
                    "ja": "ご自身の作品のコンセプト、アートワークに対する思いなどを教えてください。"
                },
                "TEXT_ANIME": {
                    "en": "Anime",
                    "ja": "アニメ"
                },
                "TEXT_ART": {
                    "en": "Art",
                    "ja": "美術"
                },
                "TEXT_GAME": {
                    "en": "Game",
                    "ja": "ゲーム"
                },
                "TEXT_GAMING": {
                    "en": "Gaming",
                    "ja": "ゲーム"
                },
                "TEXT_ID": {
                    "en": "Id",
                    "ja": "ID"
                },
                "TEXT_IDOL": {
                    "en": "Idol",
                    "ja": "アイドル"
                },
                "TEXT_GOTOCHI": {
                    "en": "Gotochi",
                    "ja": "ご当地"
                },
                "TEXT_LAUNCHPAD": {
                    "en": "Launchpad",
                    "ja": "発射台"
                },
                "TEXT_ACCOUNT": {
                    "en": "Account",
                    "ja": "アカウント"
                },
                "TEXT_ADVANCED": {
                    "en": "Advanced",
                    "ja": "アドバンスド"
                },
                "TEXT_ADVERT": {
                    "en": "Advert",
                    "ja": "広告"
                },
                "TEXT_AD_MEDIA": {
                    "en": "Media Format",
                    "ja": "広告メディア"
                },
                "TEXT_AGE": {
                    "en": "Age",
                    "ja": "年齢"
                },
                "TEXT_AGE_LOWER": {
                    "en": "From age",
                    "ja": "年齢から"
                },
                "TEXT_AGE_UPPER": {
                    "en": "To age",
                    "ja": "上の年齢"
                },
                "TEXT_AMOUNT": {
                    "en": "Amount",
                    "ja": "金高"
                },
                "TEXT_ASPECT_RATIO": {
                    "en": "Aspect Ratio",
                    "ja": "アスペクト比"
                },
                "TEXT_ATTRIBUTES": {
                    "en": "Attributes",
                    "ja": "属性"
                },
                "TEXT_AUDIENCE_TARGETING": {
                    "en": "Audience Targeting",
                    "ja": "オーディエンスターゲティング"
                },
                "TEXT_BALANCE": {
                    "en": "Balance",
                    "ja": "バランス"
                },
                "TEXT_CAMPAIGN": {
                    "en": "Campaign",
                    "ja": "キャンペーン編集"
                },
                "TEXT_COMPANY": {
                    "en": "Company",
                    "ja": "会社"
                },
                "TEXT_CONNECT_WALLET": {
                    "en": "Connect Wallet",
                    "ja": "ウォレットを接続する"
                },
                "CONTACT_ERROR" : {
                    "en": "Contact Error",
                    "ja": "接触エラー"
                },
                "TEXT_CONTACT": {
                    "en": "Contact",
                    "ja": "ウォレット"
                },
                "TEXT_COLLECTION": {
                    "en": "Collection",
                    "ja": "コレクション"
                },
                "TEXT_COLLECTION_NAME": {
                    "en": "Collection Name",
                    "ja": "コレクションの名"
                },
                "TEXT_COLLECTION_SYMBOL": {
                    "en": "Collection Symbol",
                    "ja": "コレクションのシンボル"
                },
                "TEXT_COLLECTION_DESCRIPTION": {
                    "en": "Collection Description",
                    "ja": "コレクションの説明"
                },
                "TEXT_NFT_NAME": {
                    "en": "NFT Name",
                    "ja": "NFTの名"
                },
                "TEXT_NFT_DESCRIPTION": {
                    "en": "NFT Description",
                    "ja": "NFTの説明"
                },
                "TEXT_NFT_SUPPLY": {
                    "en": "NFT Supply",
                    "ja": "NFT 供給"
                },
                "TEXT_NFT_MINTPRICE": {
                    "en": "NFT Mint Price (lazymint)",
                    "ja": "NFTミント価格（レイジーミント）"
                },
                "TEXT_MEDIA_TYPE": {
                    "en": "Media Type",
                    "ja": "メディアタイプ"
                },
                "TEXT_PEOPLE": {
                    "en": "People",
                    "ja": "サービスプロバイダー"
                },
                "TEXT_JOBS": {
                    "en": "Jobs",
                    "ja": ""
                },
                "TEXT_LOCALS": {
                    "en": "Locals",
                    "ja": ""
                },
                "TEXT_STORES": {
                    "en": "Stores",
                    "ja": ""
                },
                "TEXT_CATEGORY": {
                    "en": "Category",
                    "ja": "カテゴリー"
                },
                "CATEGORY_ART": {
                    "en": "Art",
                    "ja": "美術"
                },
                "CATEGORY_BOOK": {
                    "en": "Book",
                    "ja": "本"
                },
                "CATEGORY_MANGA": {
                    "en": "Manga",
                    "ja": "マンガ"
                },
                "CATEGORY_ANIME": {
                    "en": "Anime",
                    "ja": "アニメ"
                },
                "CATEGORY_MUSIC": {
                    "en": "Music",
                    "ja": "音楽"
                },
                "CATEGORY_GOTOCHI": {
                    "en": "Gotochi",
                    "ja": "ご当地"
                },
                "CATEGORY_GAME": {
                    "en": "Game",
                    "ja": "ゲーム"
                },
                "CATEGORY_IDOL": {
                    "en": "Idol",
                    "ja": "アイドル"
                },
                "CATEGORY_SPORT": {
                    "en": "Sport",
                    "ja": "スポーツ"
                },
                "CATEGORY_LAUNCHPAD": {
                    "en": "Launchpad",
                    "ja": "発射台"
                },
                "TEXT_TRAIT": {
                    "en": "Trait",
                    "ja": "特性"
                },
                "TEXT_TYPE": {
                    "en": "Type",
                    "ja": "タイプ"
                },
                "TEXT_VALUE": {
                    "en": "Value",
                    "ja": "バリュー"
                },
                "TEXT_LIST_FOR_SALE": {
                    "en": "List for sale.",
                    "ja": "売りに出す"
                },
                "TEXT_LIST_PRICE": {
                    "en": "List Price",
                    "ja": "価格"
                },
                "TEXT_LIST": {
                    "en": "List",
                    "ja": "リスト"
                },
                "TEXT_CREATIVE_DETAILS": {
                    "en": "Ad Details",
                    "ja": "広告の詳細"
                },
                "TEXT_UPLOAD_COLLECTION_POSTER": {
                    "en": "Collection Poster",
                    "ja": "コレクションポスター"
                },
                "TEXT_PASSPHRASE": {
                    "en": "Passphrase",
                    "ja": "パスワード"
                },
                "TEXT_DELETE": {
                    "en": "Delete",
                    "ja": "消去"
                },
                "TEXT_EXPLORE": {
                    "en": "Explore",
                    "ja": "探検する"
                },
                "TEXT_GENDER": {
                    "en": "Gender",
                    "ja": "性別"
                },
                "TEXT_HOME": {
                    "en": "Home",
                    "ja": "ホーム"
                },
                "TEXT_INITIAL_BALANCE": {
                    "en": "Initial Balance",
                    "ja": "財政収支"
                },
                "TEXT_INTERESTS": {
                    "en": "Interests",
                    "ja": "ご興味"
                },
                "TEXT_LANGUAGE": {
                    "en": "Language",
                    "ja": "言語"
                },
                "TEXT_LOCATION": {
                    "en": "Locations",
                    "ja": "場所"
                },
                "TEXT_LOGIN": {
                    "en": "Login",
                    "ja": "ログイン"
                },
                "TEXT_LOGOUT": {
                    "en": "Logout",
                    "ja": "ログアウト"
                },
                "TEXT_LOG_IN_WITH_METAMASK": {
                    "en": "Log in with metamask",
                    "ja": "メタマスクでサインアップ"
                },
                "TEXT_MEDIA": {
                    "en": "Media",
                    "ja": "メディ"
                },
                "TEXT_METAMASK": {
                    "en": "Metamask",
                    "ja": "Metamask"
                },
                "TEXT_NAME": {
                    "en": "Name",
                    "ja": "お名前"
                },
                "TEXT_NAME_PLACEHOLDER": {
                    "en": "Enter your name.",
                    "ja": "名前を入力してください"
                },
                "TEXT_OR": {
                    "en": "Or",
                    "ja": "それとも"
                },
                "TEXT_OR_EMAIL": {
                    "en": "Or Email",
                    "ja": "ヤ e-mail"
                },
                "TEXT_PRIVACY": {
                    "en": "Privacy",
                    "ja": "プライバシー"
                },
                "TEXT_PRODUCT": {
                    "en": "Product",
                    "ja": "製品"
                },
                "TEXT_REMOVE": {
                    "en": "Remove",
                    "ja": "消去"
                },
                "TEXT_RETRACT_OFFER": {
                    "en": "Cancel Offer",
                    "ja": "オファーをキャンセル"
                },
                "TEXT_REWARDS": {
                    "en": "Rewards",
                    "ja": "広告報酬"
                },
                "TEXT_SAVE": {
                    "en": "Save",
                    "ja": "保存"
                },
                "CREATE_SERVICE_HEADER": {
                    "en": "Your Service",
                    "ja": "ギグの説明"
                },
                "CREATE_SERVICE_PREVIEW": {
                    "en": "Preview",
                    "ja": "プレビュー"
                },
                "TEXT_DESCRIPTION": {
                    "en": "Preview Description",
                    "ja": "プレビューの説明"
                },
                "SAVE_MODAL_MESSAGE_SUCCESS": {
                    "en": "Saved Home Page Successfully",
                    "ja": "ホームページを保存しました"
                },
                "SAVE_MODAL_MESSAGE_FAILED": {
                    "en": "Unable to save, please check data and try again.",
                    "ja": "保存できません。データを確認して、もう一度お試しください。"
                },
                "TEXT_SAVE_CREATOR": {
                    "en": "Save",
                    "ja": "保存"
                },
                "TEXT_CREATE_NFT_COLLECTION": {
                    "en": "Create Collection",
                    "ja": "NFTコレクションを保存する"
                },
                "TEXT_BATCH_MINT": {
                    "en": "Lazy Mint",
                    "ja": "レイジーミント"
                },
                "TEXT_MINT": {
                    "en": "Mint",
                    "ja": "ミント"
                },
                "TEXT_SEARCH": {
                    "en": "Search",
                    "ja": "検索"
                },
                "TEXT_SERVICES": {
                    "en": "Services",
                    "ja": "サービス"
                },
                "TEXT_SIGN_UP": {
                    "en": "Sign Up",
                    "ja": "送信する"
                },
                "TEXT_SIGN_UP_WITH_METAMASK": {
                    "en": "Sign up with metamask",
                    "ja": "メタマスクでサインアップ"
                },
                "TEXT_STAKING": {
                    "en": "Staking",
                    "ja": "ステーキング"
                },
                "TEXT_TOP_UP": {
                    "en": "Top Up",
                    "ja": "資金を追加する"
                },
                "TEXT_UPLOAD": {
                    "en": "Upload",
                    "ja": "アップロード"
                },
                "TEXT_UPLOAD_FILE": {
                    "en": "Upload file",
                    "ja": "アップロードファイル"
                },
                "TEXT_ENABLE": {
                    "en": "Enable",
                    "ja": "エネーブル"
                },
                "PRODUCT_PRICE_FROM": {
                    "en": "From ",
                    "ja": "基本料金"
                },
                "LIST_BANNER_TEXT": {
                    "en": "Build Your Success.",
                    "ja": "成功を築く"
                },
                "SAVE_LISTED_ACCOUNT": {
                    "en": "Connected Account ",
                    "ja": "関連アカウント "
                },
                "TEXT_LEARN_MORE": {
                    "en": "Learn More.",
                    "ja": "進む"
                },
                "TEXT_TERMS_CONDITIONS": {
                    "en": "Terms and Conditions.",
                    "ja": "利用規約"
                },
                "TERMS_INTRODUCTION": {
                    "en": "Introduction",
                    "ja": "紹介"
                },
                "TERMS_INTRODUCTION_A": {
                    "en": "Thumpr (the “Platform”) provides a range of online services and technologies designed to help people connect, find opportunities, and engage in commerce across a variety of sectors, including services, jobs, local services (classifieds), and online shopping. These Terms of Service (the 'Terms') govern your use of Thumpr, including all associated products, features, apps, services, technologies, and software (collectively, the “Thumpr Services” or “Services”). By accessing or using the Thumpr Services, you agree to these Terms. Please read these terms carefully.",
                    "ja": "Thumpr（以下「プラットフォーム」）は、ユーザー同士をつなぎ、機会を見つけ、さまざまな分野での商取引に参加できるよう設計されたオンラインサービスと技術を提供しています。これには、ギグ、仕事、地域サービス（クラシファイド）、オンラインショッピングなどが含まれます。この利用規約（以下「規約」）は、Thumprおよび関連するすべての製品、機能、アプリ、サービス、技術、ソフトウェア（総称して「Thumprサービス」または「サービス」）のご利用に適用されます。Thumprサービスにアクセスまたは使用することで、これらの規約に同意したものとみなされます。規約をよくお読みください。",
                },
                "TERMS_INTRODUCTION_B": {
                    "en": "The Thumpr Services enable users to participate in a decentralized marketplace where services can be offered, goods can be bought and sold, and employment opportunities can be found, all through traditional and non-traditional payment methods, including cryptocurrency. Whether you're offering local services, seeking employment, buying or selling items, or connecting with others, these Terms apply to your use of the Platform.",
                    "ja": "Thumprサービスは、ユーザーが分散型マーケットプレイスに参加し、サービスを提供したり、商品を売買したり、雇用機会を見つけたりできるようにします。これらは、仮想通貨を含む従来型および非従来型の支払い方法を通じて行われます。地域サービスの提供、雇用の検索、商品やサービスの売買、または他のユーザーとの交流に関係なく、これらの利用規約はプラットフォームのご利用に適用されます。",
                },
                "TERMS_INTRODUCTION_C": {
                    "en": "These Terms constitute an agreement between you and Thumpr. If you do not agree to these Terms, you may not use the Platform. By continuing to use our Services, you agree to comply with these Terms, as well as any additional policies or guidelines that may apply to specific services or interactions on Thumpr.",
                    "ja": "これらの規約は、あなたとThumprとの間の契約を構成します。これらの規約に同意しない場合、プラットフォームを使用することはできません。サービスの利用を続けることで、これらの規約に従うこと、およびThumpr上の特定のサービスや相互作用に適用される可能性のある追加のポリシーやガイドラインに従うことに同意したものとみなされます。",
                },
                "TERMS_INTRODUCTION_D": {
                    "en": "If you have any questions or need further clarification on these Terms, please contact us at ",
                    "ja": "これらの規約について質問がある場合や、さらなる説明が必要な場合は、以下の連絡先までお問い合わせください。",
                },
                "TERMS_SERVICES_PROVIDED": {
                    "en": "Services Provided.",
                    "ja": "提供されるサービス",
                },
                "TERMS_SERVICES_PROVIDED_A": {
                    "en": "Thumpr offers a variety of services through its platform, allowing users to engage in different types of activities, including service work, job postings, local services (classifieds), and online shopping. Users can browse, offer, or request services from others within their community or globally. The Platform acts as a facilitator by connecting individuals and businesses but does not directly provide the services or goods listed. Thumpr is not responsible for ensuring the quality, legality, or accuracy of any listings or transactions conducted through the Platform. Users must use the Platform responsibly and in accordance with all applicable laws and these Terms.",
                    "ja": "Thumprは、プラットフォームを通じてさまざまなサービスを提供しており、ユーザーがギグワーク、求人情報、地域サービス（クラシファイド）、オンラインショッピングなど、さまざまな活動に参加できるようにしています。ユーザーは、自分のコミュニティ内や世界中の他の人々からサービスを閲覧、提供、またはリクエストすることができます。プラットフォームは、個人やビジネスをつなぐファシリテーターとして機能しますが、掲載されているサービスや商品の提供は直接行いません。Thumprは、プラットフォームを通じて行われるリスティングや取引の品質、合法性、正確性を保証する責任を負いません。ユーザーは、プラットフォームを責任を持って使用し、すべての適用法令およびこれらの規約に従う必要があります。"
                },
                "TERMS_SERVICES_PROVIDED_B": {
                    "en": "Sellers on Thumpr agree to adhere to the highest quality standards. Items and services sold on Thumpr must not cause harm to buyers and should be free from defects or risks that could endanger the safety of others. Sellers and service providers must adhere to local laws and regulations and act in the best interest of their customers. Thumpr assumes no liability for damages or losses resulting from the sale or delivery of goods and services,",
                    "ja": "Thumprの販売者は、最高の品質基準を遵守することに同意します。Thumprで販売される商品やサービスは、購入者に危害を加えてはならず、他者の安全を脅かす可能性のある欠陥やリスクがないことが求められます。販売者やサービス提供者は、地域の法律および規制を遵守し、顧客の最善の利益を考慮して行動する必要があります。Thumprは、商品の販売または提供に起因する損害や損失に対して一切の責任を負いません。",
                },
                "TERMS_SERVICES_PROVIDED_C": {
                    "en": "Thumpr may provide links to the websites of affiliated companies or other businesses. When you purchase products or services listed by these third parties, you are engaging directly with them, not with Thumpr. We do not evaluate or endorse the offerings of these businesses or individuals, and we make no warranties regarding their products, services, or website content.",
                    "ja": "Thumprは、提携会社や他のビジネスのウェブサイトへのリンクを提供する場合があります。これらの第三者が掲載した製品やサービスを購入する際は、Thumprではなく、直接彼らと取引を行うことになります。私たちは、これらのビジネスや個人の提供物を評価または支持せず、彼らの製品、サービス、またはウェブサイトの内容に関して一切の保証を行いません。"
                },
                "TERMS_SERVICES_PROVIDED_D": {
                    "en": "In addition to facilitating services and sales, Thumpr provides innovative payment and dispute resolution solutions via cryptocurrency and smart contracts. These processes are largely automated and often operate independently of direct control by the Platform. As a result, we do not take responsibility or liability for the functioning of these systems, including any issues related to payment processing, smart contract errors, or other blockchain-related matters. Users must be aware of the inherent risks in using cryptocurrency-based systems, and by using Thumpr, they agree to accept these risks.",
                    "ja": "サービスや販売を促進することに加えて、Thumprは仮想通貨とスマートコントラクトを通じて革新的な支払いおよび紛争解決ソリューションを提供しています。これらのプロセスは主に自動化されており、プラットフォームによる直接的な制御とは独立して運営されることがよくあります。その結果、私たちはこれらのシステムの機能に関して、一切の責任や義務を負いません。これには、支払い処理、スマートコントラクトのエラー、またはその他のブロックチェーン関連の問題が含まれます。ユーザーは、仮想通貨ベースのシステムを使用する際の固有のリスクを認識し、Thumprを利用することでこれらのリスクを受け入れることに同意したものとみなされます。"
                },
                "TERMS_COMMUNITY_STANDARDS": {
                    "en": "Community Standards",
                    "ja": "コミュニティスタンダード"
                },
                "TERMS_COMMUNITY_STANDARDS_A": {
                    "en": "We are committed to fostering a respectful and trustworthy environment on Thumpr. Users are expected to act in good faith and comply with all applicable laws and our Community Standards while using the Platform. The following activities are strictly prohibited:",
                    "ja": "私たちは、Thumpr上で尊重と信頼のある環境を育むことにコミットしています。ユーザーは、プラットフォームを利用する際に誠実に行動し、適用されるすべての法律およびコミュニティスタンダードを遵守することが求められます。以下の活動は厳しく禁止されています："
                },
                "TERMS_COMMUNITY_STANDARDS_B": {
                    "en": "Promoting, facilitating, or advertising any form of malicious code, or using the platform for unintended purposes such as hacking, phishing, or scamming.",
                    "ja": "悪意のあるコードのいかなる形態を促進、支援、または広告すること、またはハッキング、フィッシング、詐欺などの意図しない目的でプラットフォームを使用すること。",
                },
                "TERMS_COMMUNITY_STANDARDS_C": {
                    "en": "Selling or offering stolen goods, fraudulent services, or counterfeit items.",
                    "ja": "盗品、詐欺的なサービス、または偽造品を販売または提供すること。",
                },
                "TERMS_COMMUNITY_STANDARDS_D": {
                    "en": "Manipulating reviews or creating fake profiles to mislead other users or advertise products.",
                    "ja": "レビューを操作したり、他のユーザーを誤解させたり製品を宣伝するために偽のプロフィールを作成すること。",
                },
                "TERMS_COMMUNITY_STANDARDS_E": {
                    "en": "Engaging in illegal activities, including but not limited to, the sale or solicitation of drugs, tobacco, alcohol, weapons (excluding antiques), or any form of sexual services or pornography, regardless of local laws.",
                    "ja": "違法行為に関与すること、これには薬物、タバコ、アルコール、武器（アンティークを除く）、または地域の法律にかかわらず、性的サービスやポルノグラフィの販売または勧誘が含まれますが、これに限定されません。",
                },
                "TERMS_COMMUNITY_STANDARDS_F": {
                    "en": "Offering services to vulnerable individuals, such as those with special needs or children. Customers seeking professional care services should contact licensed local providers instead.",
                    "ja": "特別なニーズを持つ人々や子供などの脆弱な個人にサービスを提供することは、関連するケアサービスを提供する資格のある専門家でない限り禁止されています。これらのグループに対して専門的なサービスを求める顧客は、資格を持つ地元のプロバイダーを利用するべきです。",
                },
                "TERMS_COMMUNITY_STANDARDS_G": {
                    "en": "Violating others' intellectual property rights, including plagiarism or copying of Thumpr’s content or user data.",
                    "ja": "他者の知的財産権を侵害すること、これには盗作やThumprのコンテンツまたはユーザーデータのコピーが含まれます。",
                },
                "TERMS_COMMUNITY_STANDARDS_H": {
                    "en": "We reserve the right to remove any content and block or ban any user from the Platform at any time for violations of these standards, without prior notice or explanation.",
                    "ja": "当社は、これらの基準に違反した場合、事前の通知や説明なしに、いつでもコンテンツを削除し、ユーザーをプラットフォームからブロックまたは禁止する権利を留保します。",
                },
                "TERMS_PAYMENT": {
                    "en": "Payment Terms",
                    "ja": "支払い条件",
                },
                "TERMS_PAYMENT_A": {
                    "en": "Thumpr exclusively supports cryptocurrency payments. When users engage in transactions through the Platform, payments are processed via escrow accounts to protect both parties. Funds are held until the customer confirms satisfaction with the product or service, at which point the payment is released to the seller. Gas fees associated with blockchain transactions will be paid by the users, and these fees may vary depending on the network conditions and complexity of the transaction. Additionally, Thumpr charges a platform fee of 5% to 10% of the total transaction amount, depending on operational costs. This fee is subject to change, and we encourage users to regularly review our fee structure. We will also make every effort to notify users of significant updates.",
                    "ja": "Thumprは、暗号通貨による支払いのみをサポートしています。ユーザーがプラットフォームを通じて取引を行う際、支払いはエスクロー口座を介して処理され、両者を保護します。資金は、顧客が製品またはサービスに満足したことを確認するまで保留され、その後、支払いが販売者に送金されます。ブロックチェーン取引に関連するガス料金はユーザーが負担し、これらの料金はネットワークの状況や取引の複雑さによって変動する可能性があります。さらに、Thumprは、運営コストに応じて、総取引額の5%から10%のプラットフォーム手数料を請求します。この手数料は変更される可能性があり、ユーザーに定期的に手数料構造を確認することをお勧めします。また、重要な更新についてはユーザーに通知するよう努めます。",
                },
                "TERMS_PAYMENT_B": {
                    "en": "Disputes between users will be adjudicated using decentralized protocols, which may require fees for dispute resolution. These fees, which could be as high as 20% of the original transaction value, vary depending on the complexity of the case and the number of judges required. While we aim to keep these fees low, users should be aware of the potential costs involved in resolving disputes.",
                    "ja": "ユーザー間の紛争は、分散型プロトコルを使用して裁定され、これには紛争解決のための手数料がかかる場合があります。これらの手数料は、元の取引額の最大20%に達する可能性があり、ケースの複雑さや必要な裁判官の数によって異なります。手数料をできるだけ低く抑えることを目指していますが、ユーザーは紛争解決に伴う潜在的なコストについて認識しておく必要があります。",
                },
                "TERMS_PAYMENT_C": {
                    "en": "Thumpr employs advanced smart contract technology to manage payments. However, due to the inherent risks associated with cryptocurrency and smart contracts, there is a possibility of complete loss of funds due to bugs, security vulnerabilities, or other unforeseen circumstances. While we take every precaution to ensure the security of our Platform, Thumpr is not responsible or liable for any loss of funds.",
                    "ja": "Thumprは、支払いを管理するために高度なスマートコントラクト技術を採用しています。しかし、暗号通貨やスマートコントラクトに関連する固有のリスクにより、バグ、セキュリティの脆弱性、またはその他の予期しない状況によって、資金が完全に失われる可能性があります。私たちはプラットフォームのセキュリティを確保するためにあらゆる予防措置を講じていますが、Thumprは資金の損失について責任を負いません。"
                },
                "TERMS_LIMITATION_OF_LIABILITY": {
                    "en": "Limitation of Liability",
                    "ja": "責任の制限 "
                },
                "TERMS_LIMITATION_OF_LIABILITY_A": {
                    "en": "To the fullest extent permitted by law, Thumpr and its affiliates, officers, employees, agents, and partners disclaim all liability and responsibility for any loss or damage, including but not limited to financial loss, loss of data, business interruption, personal injury, or property damage, arising out of or in connection with your use of the Platform or any services or goods obtained through the Platform.",
                    "ja": "法が許す限りにおいて、Thumprおよびその関連会社、役員、従業員、代理人、パートナーは、プラットフォームの使用やプラットフォームを通じて取得したサービスまたは商品に関連して発生したあらゆる損失や損害（財務上の損失、データの損失、業務の中断、個人的な傷害、または財産の損害を含むがこれに限られない）について、すべての責任と責務を否認します。",
                },
                "TERMS_LIMITATION_OF_LIABILITY_B": {
                    "en": "By using the Platform, you acknowledge that Thumpr acts solely as a facilitator, and any disputes, issues, or liabilities arising from the interaction between users are the sole responsibility of those users. Thumpr does not endorse, guarantee, or warrant the quality or legality of any services or goods provided by users on the Platform. Additionally, Thumpr is not responsible for any third-party content or links provided through the Platform.",
                    "ja": "プラットフォームを使用することにより、あなたはThumprが単なる仲介者として機能することを認識し、ユーザー間の相互作用から生じるすべての紛争、問題、または責任はそのユーザー自身の責任であることに同意します。Thumprは、プラットフォーム上でユーザーによって提供されるサービスや商品に関して、その品質や合法性を保証、支持、または保証するものではありません。さらに、Thumprはプラットフォームを通じて提供される第三者のコンテンツやリンクについても責任を負いません。"
                },
                "TERMS_LIMITATION_OF_LIABILITY_C": {
                    "en": "By using Thumpr, you acknowledge and agree that your use of the Platform is entirely at your own risk. Thumpr disclaims any liability for financial loss, personal injury, property damage, or any claims arising from your use of or reliance on the Platform, including but not limited to losses related to cryptocurrency transactions.",
                    "ja": "Thumprを使用することにより、あなたはプラットフォームの利用が自己の責任であることに同意し、Thumprはあなたのプラットフォームの利用またはそれに依存することから生じるいかなる財務上の損失、個人的な損失や損害、または請求に対しても責任を負わないことを理解します。これには、暗号通貨に関連する損失も含まれます。"
                },
                "EBOOK_NO_READ": {
                    "en": "To read the ebook, you need to be the owner of the NFT, please buy or mint the NFT if you wish to read.",
                    "ja": "電子ブックを読むには、NFT の所有者である必要があります。読みたい場合は、NFT を購入またはミントしてください。"
                },
                "CREATE_STEP_5": {
                    "en": "Step 5 - Mint all your NFTs",
                    "ja": "ステップ5 - すべてのNFTを造幣局に預ける"
                },
                "MINT_FETCHING_METADATA_URL": {
                    "en": "Fetching Metadata URL",
                    "ja": "メタデータ URL を取得しています"
                },
                "MINT_PLEASE_ENSURE_A": {
                    "en": "Please ensure your metadata is correct.",
                    "ja": "メタデータが正しいことを確認してください。"
                },
                "MINT_PLEASE_ENSURE_B": {
                    "en": "After Minting is impossible to change your metadata.",
                    "ja": "「ミント」後はメタデータを変更できなくなります。"
                },
                "MINT_PLEASE_ENSURE_C": {
                    "en": "Please note that it can take several minutes for the NFT's to appear below, be careful not to mint again too soon to avoid duplicate NFTs as they can NOT be deleted.",
                    "ja": "NFT が以下に表示されるまでに数分かかる場合があることに注意してください。NFT は削除できないため、重複した NFT を避けるためにすぐに再鋳造しないように注意してください。"
                },
                "MINT_SUPPLY": {
                    "en": "Supply",
                    "ja": "在庫供給"
                },
                "LAZY_MINT_ADVICE": {
                    "en": "With Lazy minting, you do not mint directly. Instead it creates 'vouchers' that other people can use to mint. This is usually the preferred option, because it means you don't pay the 'gas' fee upfront. Users will be able to mint directly from the URL listed below. You can manage these vouchers by clicking the manage voucher button, remember though once a user has minted a voucher, it can not be revoked.",
                    "ja": "遅延ミントは直接ミントしません。 他の人が鋳造に使用できる「バウチャー」を作成します。 これは、「ガス」料金を前払いする必要がないことを意味するため、通常は推奨されるオプションです。 ユーザーは以下の URL から直接ミントすることができます。 [バウチャーの管理] ボタンをクリックすると、これらのバウチャーを管理できます。 ユーザーがクーポンを発行すると、それを取り消すことはできません。"
                },
                "BATCH_MINT_ABOUT": {
                    "en": "About Batch Minting",
                    "ja": "バッチミン党について"
                },
                "BATCH_MINT_ADVICE": {
                    "en": "If you mint here you will pay for the minting 'Gas' fee and the NFT will be transfered directly to your wallet. This is a batch function, meaning all your NFT data above will be minted as separate NFTs. This can help avoid duplicates and the gas fee will be less than if you mint each NFT separately. If you just want to mint one NFT, we recommend you click the mint button in the card above instead. If you have any questions please contact support on discord.",
                    "ja": ""
                },
                "WALLET_UNSURE": {
                    "en": "Unsure which wallet ?",
                    "ja": "どの財布かわからない？"
                },
                "WALLET_ADVICE": {
                    "en": "Thumpr use blockchain to manage orders and payment, because of this you need a wallet to sign up. The simplest option is coinbase wallet, simply login, you don't even need a password, coinbase manages the entire flow. For crypto native people feel free to go for metamask !",
                    "ja": "Thumpr はブロックチェーンを使用して注文と支払いを管理します。そのため、サインアップするにはウォレットが必要です。最も簡単なオプションは、Coinbase ウォレットです。ログインするだけで、パスワードも必要ありません。Coinbase がフロー全体を管理します。暗号通貨に詳しい方は、Metamask をぜひご利用ください。"
                },
                "COUNTRY_CN": {
                  "en": "China",
                  "ja": "中国"
                },
                "COUNTRY_IN": {
                  "en": "India",
                  "ja": "インド"
                },
                "COUNTRY_US": {
                  "en": "United States",
                  "ja": "アメリカ合衆国"
                },
                "COUNTRY_ID": {
                  "en": "Indonesia",
                  "ja": "インドネシア"
                },
                "COUNTRY_PK": {
                  "en": "Pakistan",
                  "ja": "パキスタン"
                },
                "COUNTRY_BR": {
                  "en": "Brazil",
                  "ja": "ブラジル"
                },
                "COUNTRY_NG": {
                  "en": "Nigeria",
                  "ja": "ナイジェリア"
                },
                "COUNTRY_BD": {
                  "en": "Bangladesh",
                  "ja": "バングラデシュ"
                },
                "COUNTRY_RU": {
                  "en": "Russia",
                  "ja": "ロシア"
                },
                "COUNTRY_MX": {
                  "en": "Mexico",
                  "ja": "メキシコ"
                },
                "COUNTRY_JP": {
                  "en": "Japan",
                  "ja": "日本"
                },
                "COUNTRY_ET": {
                  "en": "Ethiopia",
                  "ja": "エチオピア"
                },
                "COUNTRY_PH": {
                  "en": "Philippines",
                  "ja": "フィリピン"
                },
                "COUNTRY_EG": {
                  "en": "Egypt",
                  "ja": "エジプト"
                },
                "COUNTRY_VN": {
                  "en": "Vietnam",
                  "ja": "ベトナム"
                },
                "COUNTRY_CD": {
                  "en": "Democratic Republic of the Congo",
                  "ja": "コンゴ民主共和国"
                },
                "COUNTRY_TR": {
                  "en": "Turkey",
                  "ja": "トルコ"
                },
                "COUNTRY_IR": {
                  "en": "Iran",
                  "ja": "イラン"
                },
                "COUNTRY_DE": {
                  "en": "Germany",
                  "ja": "ドイツ"
                },
                "COUNTRY_TH": {
                  "en": "Thailand",
                  "ja": "タイ"
                },
                "COUNTRY_FR": {
                  "en": "France",
                  "ja": "フランス"
                },
                "COUNTRY_UK": {
                  "en": "United Kingdom",
                  "ja": "イギリス"
                },
                "COUNTRY_IT": {
                  "en": "Italy",
                  "ja": "イタリア"
                },
                "COUNTRY_TZ": {
                  "en": "Tanzania",
                  "ja": "タンザニア"
                },
                "COUNTRY_ZA": {
                  "en": "South Africa",
                  "ja": "南アフリカ"
                },
                "COUNTRY_MM": {
                  "en": "Myanmar",
                  "ja": "ミャンマー"
                },
                "COUNTRY_KR": {
                  "en": "South Korea",
                  "ja": "韓国"
                },
                "COUNTRY_CO": {
                  "en": "Colombia",
                  "ja": "コロンビア"
                },
                "COUNTRY_KE": {
                  "en": "Kenya",
                  "ja": "ケニア"
                },
                "COUNTRY_ES": {
                  "en": "Spain",
                  "ja": "スペイン"
                },
                "COUNTRY_AR": {
                  "en": "Argentina",
                  "ja": "アルゼンチン"
                },
                "COUNTRY_UG": {
                  "en": "Uganda",
                  "ja": "ウガンダ"
                },
                "COUNTRY_UA": {
                  "en": "Ukraine",
                  "ja": "ウクライナ"
                },
                "COUNTRY_SA": {
                  "en": "Saudi Arabia",
                  "ja": "サウジアラビア"
                },
                "COUNTRY_AL": {
                  "en": "Algeria",
                  "ja": "アルジェリア"
                },
                "COUNTRY_SD": {
                  "en": "Sudan",
                  "ja": "スーダン"
                },
                "COUNTRY_IQ": {
                  "en": "Iraq",
                  "ja": "イラク"
                },
                "COUNTRY_PL": {
                  "en": "Poland",
                  "ja": "ポーランド"
                },
                "COUNTRY_CA": {
                  "en": "Canada",
                  "ja": "カナダ"
                },
                "COUNTRY_AF": {
                  "en": "Afghanistan",
                  "ja": "アフガニスタン"
                },
                "COUNTRY_MA": {
                  "en": "Morocco",
                  "ja": "モロッコ"
                },
                "COUNTRY_AU": {
                  "en": "Australia",
                  "ja": "オーストラリア"
                },
                "COUNTRY_GH": {
                  "en": "Ghana",
                  "ja": "ガーナ"
                },
                "COUNTRY_YE": {
                  "en": "Yemen",
                  "ja": "イエメン"
                },
                "COUNTRY_NP": {
                  "en": "Nepal",
                  "ja": "ネパール"
                },
                "COUNTRY_VE": {
                  "en": "Venezuela",
                  "ja": "ベネズエラ"
                },
                "COUNTRY_ML": {
                  "en": "Mali",
                  "ja": "マリ"
                },
                "COUNTRY_CL": {
                  "en": "Chile",
                  "ja": "チリ"
                },
                "COUNTRY_RO": {
                  "en": "Romania",
                  "ja": "ルーマニア"
                },
                "COUNTRY_MW": {
                  "en": "Malawi",
                  "ja": "マラウイ"
                },
                "COUNTRY_KZ": {
                  "en": "Kazakhstan",
                  "ja": "カザフスタン"
                },
                "COUNTRY_ZM": {
                  "en": "Zambia",
                  "ja": "ザンビア"
                },
                "COUNTRY_SY": {
                  "en": "Syria",
                  "ja": "シリア"
                },
                "COUNTRY_SN": {
                  "en": "Senegal",
                  "ja": "セネガル"
                },
                "COUNTRY_ZW": {
                  "en": "Zimbabwe",
                  "ja": "ジンバブエ"
                },
                "COUNTRY_CM": {
                  "en": "Cameroon",
                  "ja": "カメルーン"
                },
                "COUNTRY_AE": {
                  "en": "United Arab Emirates",
                  "ja": "アラブ首長国連邦"
                },
                "COUNTRY_NL": {
                  "en": "Netherlands",
                  "ja": "オランダ"
                },
                "COUNTRY_HT": {
                  "en": "Haiti",
                  "ja": "ハイチ"
                },
                "COUNTRY_BO": {
                  "en": "Bolivia",
                  "ja": "ボリビア"
                },
                "COUNTRY_GR": {
                  "en": "Greece",
                  "ja": "ギリシャ"
                },
                "COUNTRY_SE": {
                  "en": "Sweden",
                  "ja": "スウェーデン"
                },
                "COUNTRY_TD": {
                  "en": "Chad",
                  "ja": "チャド"
                },
                "COUNTRY_CZ": {
                  "en": "Czech Republic",
                  "ja": "チェコ"
                },
                "COUNTRY_PT": {
                  "en": "Portugal",
                  "ja": "ポルトガル"
                },
                "COUNTRY_HU": {
                  "en": "Hungary",
                  "ja": "ハンガリー"
                },
                "COUNTRY_BY": {
                  "en": "Belarus",
                  "ja": "ベラルーシ"
                },
                "COUNTRY_IL": {
                  "en": "Israel",
                  "ja": "イスラエル"
                },
                "COUNTRY_AT": {
                  "en": "Austria",
                  "ja": "オーストリア"
                },
                "COUNTRY_CH": {
                  "en": "Switzerland",
                  "ja": "スイス"
                },
                "COUNTRY_BE": {
                  "en": "Belgium",
                  "ja": "ベルギー"
                },
                "COUNTRY_RS": {
                  "en": "Serbia",
                  "ja": "セルビア"
                },
                "COUNTRY_HN": {
                  "en": "Honduras",
                  "ja": "ホンジュラス"
                },
                "COUNTRY_FI": {
                  "en": "Finland",
                  "ja": "フィンランド"
                },
                "COUNTRY_SK": {
                  "en": "Slovakia",
                  "ja": "スロバキア"
                },
                "COUNTRY_NO": {
                  "en": "Norway",
                  "ja": "ノルウェー"
                },
                "COUNTRY_IE": {
                  "en": "Ireland",
                  "ja": "アイルランド"
                },
                "TEXT_COUNTRY": {
                  "en": "Country",
                  "ja": "国を選択"
                },
                "TEXT_CITY": {
                  "en": "City",
                  "ja": "都市を選択"
                },
                // Special store to keep language
                "VUE_APP_USER_LANGUAGE": ""
            })
        }
    );

export {translationStore};
