<template>
  <div>

    <div class="service-container">

      <div class="flex-row">
        <h1 class="createtitletext">
          {{ product.title }}
        </h1>
        <div
          v-if="userInfo.user_id === product.user_id"
          class="do-edit"
          @click="gotoPath('list/services/' + $route.params.id)"
        >
          {{ localize('TEXT_EDIT') }}
        </div>
      </div>

      <div :class="serviceLayout">

        <div :class="servicesContainer">

          <div
            :id="productContainer"
          >

            <Carousel
              class="service-carousel"
              ref="carousel"
              :wrap-around="false"
              :items-to-show="1.2"
            >

              <Slide
                v-for="(n, i) in product.media"
                :key="i"
              >

                <div
                  :id="'product-banner-' + product.id + '-' + i"
                  class="product-banner-desktop"
                >
                  <video
                    v-if="n.media_filename_preview"
                    class="product-video"
                    loop="true"
                    autoplay="true"
                    :src="getURL(n.media_filename)"
                    muted
                  />
                </div>

              </Slide>

              <template #addons>
                <Navigation />
                <Pagination />
              </template>
            </Carousel>

          </div>

          <div class="service-thumbnails">

            <div
              v-for="(n, i) in product.media"
              class="product-selection"
            >
              <div class="product-thumbnail-container">
                <div
                  class="media-preview" 
                  v-if="n.media_filename_preview"
                  @click="selectCard(i)"
                >
                  <img class="media-thumbnail" :src="getURL(n.media_filename_preview)" />
                </div>

                <div
                  class="media-thumbnail" 
                  v-else-if="n.media_filename"
                  @click="selectCard(i)"
                >
                  <img class="media-thumbnail" :src="getURL(n.media_filename)" />
                </div>

              </div>
            </div>
          </div>

          <h3>
            {{product.descriptionpreview}}
          </h3>

          <div
            v-if="product.local === true"
            class="flex-row"
          >
            <h3>{{product.country}}</h3> / <h3>{{product.city}}</h3>
          </div>

          <div
            class="product-description"
            v-html="product.description"
          >
          </div>

        </div>

        <div :class="optionsContainer">

          <div class="mini-profile">
            <div class="flex-row-center">
              <div
                class="profile-mini-circle"
                @click="viewProfile"
              >
                <img class="profile-image" :src="getURL(profile.image)" />
              </div>
              <div
                class="profile-detail"
              >
                <div v-if="profile.name" class="profile-mini-name" @click="viewProfile"> {{ profile.name }} </div>
                <div v-if="profile.title" class="profile-mini-title"> {{ profile.title }} </div>
                <div v-if="geoip.country" class="profile-country"> {{ localize(getCountry()) }} </div>
              </div>
            </div>
            <div
              v-if="userInfo?.authorized == true"
              class="profile-contact"
              @click="openChat"
            >
              <div
                class="messages-icon-mini"
                v-html="getRawIcon('MESSAGES_DARK')"
              />
                {{localize('TEXT_CONTACT')}}
            </div>
          </div>

          <h2 class="createtitletext">
            {{ localize('TEXT_OFFERINGS') }}
          </h2>

          <div>

            <div
              v-for="(i,k) in product.options" :key="k"
              class="offerings"
            >

              <div v-if="product.options">

                <div class="order-title">
                  <h3>{{k}}</h3>
                </div>

                <div class="offer-container-outer">

                  <div class="offer-container-inner">

                    <div class="order-description">
                      <b> {{ product.options[k].description }} </b>
                    </div>

                    <div class="flex-col">

                      <div class="order-detail">
                        {{localize('TEXT_DAYS_DUE')}} : {{product.options[k].days}}
                      </div>
                      <div class="order-detail">
                        {{localize('TEXT_REVISIONS')}} : {{product.options[k].revisions}}
                      </div>

                    </div>

                    <div class="flex-col-apart">

                      <div>

                        <div
                          v-for="(j,i) in product.options[k].features"
                          class="feature-item"
                        >
                          <div
                            class="feature-tick"
                            v-html="getRawIcon('CIRCLE_CHECK')"
                          />
                          <div>
                            {{ j }}
                          </div>
                        </div>

                      </div>

                      <div class="pay-container">
                        <div class="price flex-row">
                          <b>{{localize('TEXT_PRICE')}} $ {{product.options[k].price}}</b>
                          <div v-if="product.options[k].hourly == true">
                             &nbsp; / {{localize('TEXT_HOUR')}}
                          </div>
                        </div>
                        <div
                          class="continue-button"
                          @click="goPurchase(k)"
                        >
                          <span><span class="swap-text">{{localize('TEXT_CONTINUE')}}</span></span>
<!--
                          <div
                            class="swap-text-box"
                            @click=placeOrder
                          >
                          </div>
-->
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Transition>
      <CModal
        v-if="showPurchaseModal"
        title="Purchase Plan"
        width="medium"
        color="#efefef"
        backgroundColor="#ffffff"
        secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        @close="showPurchaseModal = false"
      >
        <template #header>
          <div> {{ localize('TEXT_PURCHASE') }} </div>
        </template>
        <template #body>
          <div>
            <Payment 
              :product="product"
              :profileName="profile.name"
              :profileImage="getURL(profile.image)"
              :payOption="selectedOption"
              :hourly="product.options[selectedOption].hourly"
              :payAmount="product.options[selectedOption].price"
            />
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

    <Transition>
      <CModal
        v-if="showAddModal"
        title="Please Wait"
        color="#efefef"
        backgroundColor="#333"
        secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        @close="showAddModal = false"
      >
        <template #header>
          <div> {{ localize('TEXT_ADD_SERVICE') }} </div>
        </template>
        <template #body>
          <div>
            {{ localize(showAddMessage) }}
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {translationStore} from "@/stores/translation";
import {inject, onMounted, reactive, ref, toRaw, toRefs, unref, watch, watchEffect} from "vue";
import {Carousel, Navigation, Pagination, Slide} from "vue3-carousel";
import {QuillEditor} from "@vueup/vue-quill";
import BlotFormatter from "quill-blot-formatter";
import ImageUploader from "quill-image-uploader";
import Axios from "axios";
import FormData from "form-data";
import ServiceCard from "./ServiceCard.vue";
import Payment from "./Payment.vue";
import {ERC20FactoryABI} from "../abi.js";

export default {
    "name": "CreateService",
    "components": {
        Carousel,
        Navigation,
        Pagination,
        Slide,
        QuillEditor,
        ServiceCard,
        Payment
    },
    "props": {
        "category": {
            "type": String,
            "default": ""
        },
        "product": {
            "type": Object,
            "default": {},
        },

    },
    setup (props) {

        const translation = translationStore(),
            eBus = inject("eBus"),
            serverConfig = inject("serverConfig"),
            route = inject("route"),
            router = inject("router"),
            web3 = inject("web3"),
            userInfo = inject("userInfo"),
            carousel = ref(),
            selectedOption = ref(),
            profile = ref({}),
            geoip = ref({}),
            icon = iconStore(),
            showPurchaseModal = ref(false),
            showAddMessage = ref(),
            showAddModal = ref(),
            servicesContainer = ref('services-container-desktop'),
            optionsContainer = ref('options-container-desktop'),
            serviceLayout = ref('service-layout-desktop'),
            openChat = function() {

                const params = {

                    users: [profile.value.user_id, userInfo.user_id]

                }

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}openChat`,
                    {

                        params

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                              if (response.data.roomId) {

                                  gotoPath('messenger/' + response.data.roomId);

                              }

                        }

                })

            },
            viewProfile = function() {

                window.open('https://www.thumpr.xyz/profile/' + product.value.user_id, '_blank').focus();

            },
            genRandStr = function () {

                return (new Date().getSeconds() * Math.random()).toString(36).replace(
                    ".",
                    ""
                );

            },
            openLogin = async function () {

                eBus.emit(
                    "app-event",
                    {
                        "action": "showLoginModal",
                        "data": ""
                    }
                );

            },
            goPurchase = function (k) {

                if (userInfo.authorized === false) {

                    openLogin();

                } else {

                    selectedOption.value = k;
                    showPurchaseModal.value = true;

                }

            },
            getCountry = function () {

                return "COUNTRY_" + geoip.value.country;

            },
            modules = [
                {

                    "name": "blotFormatter",
                    "module": BlotFormatter,
                    "options": {/* options */}

                },
                {

                    "name": "imageUploader",
                    "module": ImageUploader,
                    "options": {
                        "upload": (file) => {

                            return new Promise((resolve, reject) => {

                                const formData = new FormData();
                                formData.append(
                                    "file",
                                    file
                                );

                                Axios.post(
                                    "/uploadImage",
                                    formData
                                ).
                                    then((res) => {
                                        console.log(res);
                                        resolve(res.data.url);

                                    }).
                                    catch((err) => {

                                        reject("Upload failed");
                                        console.error(
                                            "Error:",
                                            err
                                        );

                                    });

                            });

                        }

                    }

                }
            ],
            product = ref(props.product),
            maxVideoSize = ref("1000000000"),
            maxImageSize = ref("10000000"),
            detail = ref(),
            currentFeature = ref(),
            selectedMedia = ref(0),
            showTokenModal = ref(false),
            validateError = ref(),
            productContainer = ref(),
            getListing = function (product_id) {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getListing`,
                    {

                        "params": {

                            listType: 'SERVICES',
                            id: route.params.id

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            product.value = response.data;

                            console.log("product.value")
                            console.log(product.value)
                            getProfile(product.value.user_id);
                            updateView(0);

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getProfile = function (user_id) {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getProfile`,
                    {

                        "params": {

                            userId:user_id

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            if (response.data) {

                                profile.value = response.data;

                                try {

                                    geoip.value = JSON.parse(profile.value.geoip);

                                } catch(e) {

                                    console.log(e)

                                }

                            }

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            selectCard = function (i) {

                productContainer.value = `product-container-${product.value.id}`;

                selectedMedia.value = i;

                console.log('selectCard')
                console.log(i)

                if (i.video == true) {

                    product.value.video = product.value.media[i].media_filename;
                    product.value.image = product.value.media[i].media_filename_preview;
                    product.value.video = true;

                } else {

                    product.value.video = "";
                    product.value.image = product.value.media[i].media_filename;
                    product.value.video = false;

                }

                carousel.value.slideTo(i);

            },
            getURL = function (endpoint) {

                return `${process.env.VUE_APP_SERVER_URI + endpoint}`;

            },
            getRawIcon = function (i) {

                return icon.get(`${i}`);

            },
            gotoPath = function (t) {

                router.push({"path": `/${t}`}).catch((err) => {

                    throw err;

                });

            },
            updateView = function (i) {

                if (!i) {

                    i = selectedMedia.value;

                }

                setTimeout(

                    () => {

                        const b = document.getElementById(`product-banner-${product.value.id}-${i}`);

                        if (product.value.media?.length === 0) {
                            
                            return;

                        }

                        if (b) {

                            if (product.value?.media[i]?.media_filename_preview) {

                                b.style.backgroundImage =
                                    `url('${process.env.VUE_APP_SERVER_URI}`
                                    + product.value?.media[i]?.media_filename_preview + "')";

                            } else {

                                b.style.backgroundImage =
                                    `url('${process.env.VUE_APP_SERVER_URI}`
                                    + product.value?.media[i]?.media_filename + "')";

                            }

                        }

                    },
                    1000

                );

                if (serverConfig.view === "desktop") {

                    serviceLayout.value = 'service-layout-desktop';
                    servicesContainer.value = 'services-container-desktop';
                    optionsContainer.value = 'options-container-desktop';

                } else if (serverConfig.view === "tablet") {

                    serviceLayout.value = 'service-layout-desktop';
                    servicesContainer.value = 'services-container-desktop';
                    optionsContainer.value = 'options-container-desktop';

                } else {

                    serviceLayout.value = 'service-layout-mobile';
                    servicesContainer.value = 'services-container-mobile';
                    optionsContainer.value = 'options-container-mobile';

                }

            };

        onMounted(() => {

            watch(
                serverConfig.chainId,
                () => {

                }
            );

            setTimeout(() => {
                for (var i=0; i < product.value.media?.length; i++) {
                    updateView(i);
                }
            }, 1200);

            if (route.params.id) {

                getListing(route.params.id);

            }

            productContainer.value = `product-container-${selectedMedia.value}`;

            watch(
                () => product.value.media?.length,

                (first, second) => {

                    productContainer.value = `product-container-${product.value.id}`;
                    updateView(product.value.media?.length-1);

                }

            );

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

        });

        return {"localize": translation.localize,
            icon,
            eBus,
            web3,
            getListing,
            getURL,
            updateView,
            serviceLayout,
            selectedOption,
            selectCard,
            openLogin,
            goPurchase,
            gotoPath,
            getRawIcon,
            genRandStr,
            userInfo,
            detail,
            profile,
            geoip,
            product,
            carousel,
            servicesContainer,
            optionsContainer,
            selectedMedia,
            currentFeature,
            productContainer,
            showPurchaseModal,
            openChat,
            viewProfile,
            getProfile,
            getCountry,
            serverConfig,
            showTokenModal,
            "icon": icon.get};

    }
};

</script>
<style scoped>
.define-left {
}
.margin-top {
  margin-top: 20px;
}
.titlecolor {
  color: #22255C;
}
.service-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}
.paragraph {
  margin: 10px;
}
.title-mobile {
  margin-left: 0px;
}
.title-desktop {
  margin-left: 10px;
}
.text {
  color: #908ab9;
}
.createtitletext {
  color: #000;
}
.sub-title {
  color: #000;
  font-size: 1.2em;
}
.create-sub-header {
  margin-top: 20px;
  text-align: left;
  font-weight: bold;
  line-height: 35px;
  color: #22255C;
  font-size: 0.9em;
}
.sub-script {
  text-align: left;
  font-weight: bold;
  color: #afafaf;
  line-height: 30px;
  font-size: 0.8em;
}
.create-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.create-upload-box {
  height: 20px;
  border: 1px solid #efefef;
  border-radius: 15px;
  margin-top: 1px;
  margin-bottom: 1px;
  opacity: 0.5;
  background: url("../assets/upload-icon.svg") #efefef;
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}
.create-upload-box {
  height: 100px;
  border: 1px solid #efefef;
  border-radius: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0.5;
  background: url("../assets/upload-icon.svg") #efefef;
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}
.create-upload-box:hover {
  text-decoration: underline;
  opacity: 1.0;
  cursor: pointer;
}
.create-upload-box-thumbnail {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: grey;
  font-size: 2em;
  border: 1px solid #efefef;
  border-radius: 15px;
}
.create-upload-box-thumbnail:hover {
  text-decoration: underline;
  opacity: 1.0;
  cursor: pointer;
}
.add-media-thumbnail {
  height: 64px;
  font-size: 2em;
  color: #575757;
}
.file-items {
  padding: 10px;
  background-color: #eeebff;
  border-radius: 10px;
  font-size: 0.8em;
  margin-top: 10px;
  background: linear-gradient(1deg,#e5e5e5,transparent);
}
.file-name {
  color: #22255c;
  font-weight: bold;
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  white-space: nowrap;
}
.file-item {
  font-family: sans-serif;
  color: #a0a0a0;
  margin-right: 10px;
}
.remove-button {
  cursor: pointer;
  margin-right: 10px;
  font-weight: bold;
  color: #f7608a;
}
.create-token-preview-bg {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  border: 3px dashed #d295ff;
}
.create-token-preview-bg:hover {
  text-decoration: underline;
  opacity: 0.5;
}
.create-token-container {
  padding: 20px;
  width: 33%;
}
.create-token-preview {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding-left: 20px;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 40px;
  justify-content: center;
}
.square {
  padding-bottom: 100%;
}
.smartphone-banner {
  padding-bottom: 16.667%;
}
/*
.ad191-1 {
  padding-bottom: 52.356%;
}
*/
.landscape {
  padding-bottom: 56.25%;
}
.portrait {
  padding-bottom: 177.78%;
}
.ad4-5 {
  padding-bottom: 125%;
}
.billboard {
  padding-bottom: 25.7%;
}
.leaderboard {
  padding-bottom: 12.36%;
}
.medium-rectangle {
  padding-bottom: 83.33%;
}
.halfpage {
  padding-bottom: 200%;
}
.wide-skyscraper {
  padding-bottom: 266%;
}
.skyscraper {
  padding-bottom: 375%;
}
.upload-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.token-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.create-details {
  margin-top: 20px;
}
.create-targeting {
  margin-top: 20px;
}
h2.createtitletext {
  text-align: left;
  color: #22255C;
}
.vspace {
  width: 100%;
  height: 20px;
}
.nowrap {
  flex-wrap: nowrap;
}
.create-item {
  margin-top: 20px;
}
.spend-item {
  margin-top: 20px;
  width: 150px;
}
.create-token-unit {
  color: #22255c;
  margin-left: 20px;
  font-weight: bold;
  font-size: 1.0em;
  margin-right: 10px;
  flex-wrap: nowrap;
}
.pointer {
  cursor: pointer;
}
.create-save-token-button {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
  width: fit-content;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: white;
  height: 50px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 15px 20px 2px rgb(139 106 224 / 33%);
  cursor: pointer;
  background: linear-gradient(338deg,#ff7c88,#ff46a4)
}
.create-token-title {
  font-weight: 600;
  font-family: sans-serif;
  text-decoration: none;
  color: var(--msft-card-font-color);
  font-size: var(--msft-article-heading-font-size, 1.2em);
  line-height: var(--msft-article-heading-line-height, 24px);
  -webkit-line-clamp: var(--heading-max-lines, 3);
  text-align: center;
  margin: 10px;
  outline: 0px;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.step-1-container {
  display: flex;
  flex-direction: row;
  width: 1200px;
}
.step-2-3-container {
  display: flex;
  flex-direction: row;
  justify-content: inherit;
}
.step-2-3-container-left {
  width: 50%;
}
.contract-items {
  display: flex;
  margin-top: 18px;
}
.category {
  display: flex;
}
.create-dropdown-item {
  margin-right: 10px;
}
input#create-service-file-upload-thumbnail {
  display: none;
}
input#create-service-file-upload {
  display: none;
}
label.create-service-file-upload {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  padding: 0;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
}
label.create-service-file-upload-thumbnail {
  display: block;
  background: none;
  padding: 0;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
}
.preview-container {
  margin-left: 20px;
}
.media-preview {
  width: 100%;
  max-width: 300px;
  overflow: none;
  cursor: pointer;
  border-radius: 15px;
  aspect-ratio: 1/1;
  object-fit: cover;
  background-size: cover;
  background-position: 50%;
  transition: all 500ms ease-in-out;
}
.media-preview-thumbnail {
  width: 64px;
  height: 64px;
  overflow: none;
  cursor: pointer;
  border-radius: 15px;
  aspect-ratio: 1/1;
  object-fit: cover;
  background-size: cover;
  background-position: 50%;
  transition: all 500ms ease-in-out;
}
.img media-preview {
  width: 100%;
}
.media-thumbnail {
  width: 64px;
  height: 64px;
  margin-top: 5px;
  margin-right: 5px;
  overflow: none;
  cursor: pointer;
  border-radius: 15px;
  aspect-ratio: 1/1;
  object-fit: cover;
  background-size: cover;
  background-position: 50%;
  transition: all 500ms ease-in-out;
}
.img media-thumbnail {
  width: 100%;
}
.product-preview-mobile {
  width: 100%;
}
.product-preview-desktop {
  width: 60%;
}
.product-banner-desktop {
  width: 100%;
  border-radius: 25px;
  background-size: cover;
  aspect-ratio: 2/1;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.product-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
}
.service-carousel {
  width: 100%;
}
.service-thumbnails {
  display: flex;
}
.edit-service-card-preview {
  border: 2px dashed;
  border-radius: 20px;
}
.offerings {
}
.add-features {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: end;
  margin-left: 10px;
  margin-bottom: 20px;
}
.rem-features {
  margin-left: 10px;
  color: #da2671;
}
.offer-container-outer {
  padding: 1px;
  border-radius: 20px;
  background: radial-gradient(ellipse at top left, #3a9ae7 0, #b53cd3 62%, #041534 100%);
}
.offer-container-inner {
  color: #2d2d2d;
  margin: 3px;
  padding: 15px;
  border-radius: 15px;
  background: white;
}
.feature-tick {
  width: 20px;
  height: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
}
.order-detail {
  margin-bottom: 10px;
}
.order-description {
  margin-bottom: 10px;
}
.quill-editor {
  height: 400px;
  font-size: 1em;
  margin-top: 20px;
}
.save-under-account {
  width: fit-content;
  margin: 33px;
  font-size: 0.9em;
  borer: 1px solid #505050;
}
.quill-input {
  border: none !important;
  font-size: 1.5em;
  height: 500px;
}
.ql-toolbar.ql-snow { border: none !important;}
.margin-top-100 {
  margin-top: 100px;
}
.ql-editor {
  font-size: 18px;
}
.profile-mini-circle {
  height: 128px;
  width: 128px;
  border: 1px solid #efefef;
  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}
.create-upload-circle {
  height: 256px;
  width: 256px;
  border: 1px solid #efefef;
  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0.5;
  background: url("../assets/upload-icon.svg") #efefef;
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}
.profile-detail {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin-left: 50px;
}
.profile-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  font-size: 1em;
  font-weight: 700;
  height: 20px;
  cursor: pointer;
}
.messages-icon-mini {
  margin-right: 20px;
  width: 32px;
  height: 32px;
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profile-mini-name {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}
.profile-mini-name:hover {
  text-decoration: underline;
  cursor: pointer;
}
.profile-mini-title {
  font-size: 1.0em;
  color: #555;
  margin-bottom: 12px;
}
.profile-image {
  border-radius: 50%;
  width: 128px;
  height: 128px;
  object-fit: cover;
}
.do-edit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 10px 10px 20px;
  font-size: 0.8em;
  font-weight: bold;
  color: #505050;
}
.do-edit:hover {
  text-decoration: underline;
  cursor: pointer;
}
.continue-button {
  display: flex;
  height: 20px;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  text-wrap: nowrap;
  font-size: 15px;
  font-weight: 700;
  font-family: Arial, sans-serif;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, .15);
  overflow: hidden;
  align-items: center;
  background: -webkit-repeating-linear-gradient(46deg,#46b6ff,#ff7cf5);
  transition: all .3s ease;
}
.continue-button:hover {
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.3);
  background: radial-gradient(ellipse at left bottom, #fdfdff 100, %#ffffff 0%, #d3d3d3 62%);
  color: #f539e4;
}
.continue-button:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at top left, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 50%);
    border-radius: 8px;
    pointer-events: none;
    z-index: 1;
}
.continue-button:active {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.pay-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.service-layout-desktop {
  display: flex;
}
.service-layout-mobile {
  display: flex;
  flex-direction: column;
}
.service-content-container {
  margin-right: 20px;
}
.swap-text-box {
  padding: 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 300ms;
  border: 2px solid transparent;
  background: #efefef;
}
.swap-text-box:hover {
  border: 2px solid #46b6ff;
  cursor: pointer;
}
.swap-text {
  width: 100px;
  font-size: 1.2em;
  font-weight: bold;
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.options-container-desktop {
  width: 50%;
}
.options-container-mobile {
  width: 100%;
}
.services-container-desktop {
  margin-right: 50px;
  width: 70%;
}
.services-container-mobile {
  width: 100%;
}
.product-description:deep(img) {
  width: 100%;
  height: auto;
}
.feature-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
