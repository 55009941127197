<template>
  <div>
    <div :class="serviceCardContainer">
      <div
        class="creative-preview"
      >
        <div v-if="productMedia.length > 0">

          <Carousel
            class="service-carousel"
            ref="carousel"
            :wrap-around="false"
            :items-to-show="1"
          >

            <Slide
              v-for="(n, i) in productMedia"
              :key="i"
            >

              <div
                :id="productStr + '-' + product.id + '-' + i"
                class="product-banner-desktop"
                @click.prevent="gotoPath('services/' + product.id)"
              >
                <video
                  v-if="n.media_filename_preview"
                  class="product-video"
                  loop="true"
                  autoplay="true"
                  :poster="getURL(n.media_filename_preview)"
                  :src="getURL(n.media_filename)"
                  muted
                />
              </div>

            </Slide>

            <template #addons>
              <Navigation />
            </template>
          </Carousel>

        </div>
        <div v-else>
          <img  class="preview-no-image" src="/public/no-image.png"/>
        </div>

<!--
        <video
          v-if="product.video"
          class="video-creative"
          loop="true"
          autoplay="autoplay"
          :poster=getURL(product.preview)
          :src="getURL(product.video)"
          muted
        />
        <div
          class="media-preview" 
          v-else-if="product.image"
        >
          <img class="media-preview" :src="getURL(product.image)" />
        </div>
-->
      </div>
      <div
        class="creative-content"
      >
        <div class="service-preview-profile">
          <div class="flex-row-center">
            <img v-if="profile.image" class="service-card-profile" :src="'/' + profile.image" />
            <img v-else="profile.image" class="service-card-profile" :src="'/public/defaultprofile.jpg'" />
            <div
              class="service-card-profile-name"
              @click="gotoPath('profile/' + product.user_id)"
            >
              {{ profile.name }}
            </div>
          </div>
          <div>
          </div>
        </div>
        <div
          class="service-preview-title"
          @click.prevent="gotoPath('services/' + product.id)"
        >
          <b>{{ product.title }}</b>
        </div>
        <div
          class="service-preview-description"
          @click.prevent="gotoPath('services/' + product.id)"
        >
          {{ product.descriptionpreview }}
        </div>

        <div class="flex-row-between">
          <div class="service-preview-price">
            {{ localize('PRODUCT_PRICE_FROM') }} $ {{ lowestPrice }}
          </div>
          <div
            class="learn-more"
            @click="gotoPath('services/' + product.id)"
          >
            {{ localize('TEXT_LEARN_MORE') }}
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {translationStore} from "@/stores/translation";
import {inject, onMounted, reactive, ref, toRaw, toRefs, unref, watch, watchEffect, defineProps} from "vue";
import {Carousel, Navigation, Slide} from "vue3-carousel";

export default {
    "name": "ServiceCard",
    "components": {
        Carousel,
        Navigation,
        Slide
    },
    "props": {
        "profile": Object,
        "product": Object,
        "productMedia": Object
    },
    "emits": ["scroll"],
    setup (props, {emit}) {

        const translation = translationStore(),
            router = inject("router"),
            profile = ref(props.profile),
            product = ref(props.product),
            productStr = ref(""),
            lowestPrice = ref(),
            serviceCardContainer = ref(),
            productMedia = reactive(props.productMedia),
            serverConfig = inject("serverConfig"),
            icon = iconStore(),
            doNothing = function () {},
            genRandStr = function () {

                return (new Date().getSeconds() * Math.random()).toString(36).replace(
                    ".",
                    ""
                );

            },
            getURL = function (endpoint) {

                return `${process.env.VUE_APP_SERVER_URI + endpoint}`;

            },
            gotoPath = function (t) {

                router.push({"path": `/${t}`}).catch((err) => {

                    throw err;

                });

            },
            updateView = function (i) {

                setTimeout(

                    () => {

                        const b = document.getElementById(`${productStr.value}-${product.value.id}-${i}`);

                        if (productMedia.length === 0) {
                            
                            return;

                        }

                        if (b) {

                            if (productMedia[i].media_filename_preview) {

                                b.style.backgroundImage =
                                    `url('${process.env.VUE_APP_SERVER_URI}` + productMedia[i].media_filename_preview + "')";

                            } else {

                                b.style.backgroundImage =
                                    `url('${process.env.VUE_APP_SERVER_URI}` + productMedia[i].media_filename + "')";

                            }

                        }

                    },
                    300

                );

                if (serverConfig.view === "desktop") {

                    serviceCardContainer.value = "service-card-container";

                } else if (serverConfig.view === "laptop") {

                    serviceCardContainer.value = "service-card-container";

                } else if (serverConfig.view === "tablet") {

                    serviceCardContainer.value = "service-card-container";

                } else {

                    serviceCardContainer.value = "service-card-container-mobile";

                }
            };

        onMounted(() => {

            productStr.value = genRandStr();

            setTimeout(()=> {

                let options = toRaw(product.value.options)
                Object.keys(options).forEach(k => {

                    if (!lowestPrice.value) {
                      lowestPrice.value = parseInt(options[k].price);
                    }

                    if (parseInt(options[k].price) < parseInt(lowestPrice.value)) {
                      lowestPrice.value = parseInt(options[k].price);

                    }

                })

                for (var i=0; i<productMedia.length; i++) {

                    updateView(i);

                }

            },300);

            watch(
                () => serverConfig.view,
                () => {

                    updateView();

                }
            );
            
        });

        return {"localize": translation.localize,
            icon,
            profile,
            product,
            productStr,
            lowestPrice,
            serverConfig,
            serviceCardContainer,
            genRandStr,
            gotoPath,
            doNothing,
            updateView,
            productMedia,
            getURL,
            "icon": icon.get};

    }
};

</script>
<style scoped>
.margin-top {
  margin-top: 20px;
}
.titlecolor {
  color: #22255C;
}
.container {
  margin-top: -100px;
  text-align: left;
}
.paragraph {
  margin: 10px;
}
.title-mobile {
  margin-left: 0px;
}
.title-desktop {
  margin-left: 10px;
}
.text {
  color: #908ab9;
}
.createtitletext {
  color: #000;
}
.sub-title {
  color: #000;
  font-size: 1.2em;
}
.create-sub-header {
  margin-top: 20px;
  text-align: left;
  font-weight: bold;
  line-height: 35px;
  color: #22255C;
  font-size: 0.9em;
}
.sub-script {
  text-align: left;
  font-weight: bold;
  color: #afafaf;
  line-height: 30px;
  font-size: 0.8em;
}
.token-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.create-upload-box {
  height: 150px;
  border: 1px solid #efefef;
  border-radius: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0.5;
  background: url("../assets/upload-icon.svg") #efefef;
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}
.create-upload-box:hover {
  opacity: 1.0;
  cursor: pointer;
}
.file-items {
  padding: 10px;
  background-color: #eeebff;
  border-radius: 10px;
  font-size: 0.8em;
  margin-top: 10px;
  background: linear-gradient(1deg,#e5e5e5,transparent);
}
.file-name {
  color: #22255c;
  font-weight: bold;
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  white-space: nowrap;
}
.file-item {
  font-family: sans-serif;
  color: #a0a0a0;
  margin-right: 10px;
}
.remove-button {
  cursor: pointer;
  margin-right: 10px;
  font-weight: bold;
  color: #f7608a;
}
.create-token-preview-bg {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  border: 3px dashed #d295ff;
}
.create-token-preview-bg:hover {
  opacity: 0.5;
}
.create-token-container {
  padding: 20px;
  width: 33%;
}
.create-token-preview {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding-left: 20px;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 40px;
  justify-content: center;
}
.square {
  padding-bottom: 100%;
}
.smartphone-banner {
  padding-bottom: 16.667%;
}
/*
.ad191-1 {
  padding-bottom: 52.356%;
}
*/
.landscape {
  padding-bottom: 56.25%;
}
.portrait {
  padding-bottom: 177.78%;
}
.ad4-5 {
  padding-bottom: 125%;
}
.billboard {
  padding-bottom: 25.7%;
}
.leaderboard {
  padding-bottom: 12.36%;
}
.medium-rectangle {
  padding-bottom: 83.33%;
}
.halfpage {
  padding-bottom: 200%;
}
.wide-skyscraper {
  padding-bottom: 266%;
}
.skyscraper {
  padding-bottom: 375%;
}
.upload-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.token-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.create-details {
  margin-top: 20px;
}
.create-targeting {
  margin-top: 20px;
}
h2.createtitletext {
  text-align: left;
  color: #22255C;
}
.vspace {
  width: 100%;
  height: 20px;
}
.nowrap {
  flex-wrap: nowrap;
}
.create-item {
  margin-top: 20px;
}
.spend-item {
  margin-top: 20px;
  width: 150px;
}
.create-token-unit {
  color: #22255c;
  margin-left: 20px;
  font-weight: bold;
  font-size: 1.0em;
  margin-right: 10px;
  flex-wrap: nowrap;
}
.pointer {
  cursor: pointer;
}
.create-save-token-button {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
  width: fit-content;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: white;
  height: 50px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 15px 20px 2px rgb(139 106 224 / 33%);
  cursor: pointer;
  background: linear-gradient(338deg,#ff7c88,#ff46a4)
}
.create-token-title {
  font-weight: 600;
  font-family: sans-serif;
  text-decoration: none;
  color: var(--msft-card-font-color);
  font-size: var(--msft-article-heading-font-size, 1.2em);
  line-height: var(--msft-article-heading-line-height, 24px);
  -webkit-line-clamp: var(--heading-max-lines, 3);
  text-align: center;
  margin: 10px;
  outline: 0px;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.step-1-container {
  display: flex;
  flex-direction: row;
  width: 1200px;
}
.step-2-3-container {
  display: flex;
  flex-direction: row;
  justify-content: inherit;
}
.step-2-3-container-left {
  width: 50%;
}
.contract-items {
  display: flex;
  margin-top: 18px;
}
.service-card-container {
  border-radius: 9px;
  width: 277px;
  height: 400px;
}
.service-card-container-mobile {
  border-radius: 9px;
  width: 100%;
}
.image-creative {
  width: 100%;
  overflow: none;
  cursor: pointer;
  border-radius: 15px;
  aspect-ratio: 1/1;
  object-fit: cover;
  background-size: cover;
  background-position: 50%;
  transition: all 500ms ease-in-out;
}
.image-creative:hover {
  transform: scale(1.03);
}
.video-creative {
  width: 100%;
  max-height: 100%;
  overflow: none;
  cursor: pointer;
  border-radius: 15px;
  aspect-ratio: 1/1;
  object-fit: cover;
  background-size: cover;
  background-position: 50%;
  transition: all 500ms ease-in-out;
}
.video-creative:hover {
  transform: scale(1.03);
}
.creative-preview {
  cursor: pointer;
}
.creative-content {
  height: 200px;
  padding: 10px;
}
.media-preview {
  width: 100%;
  height: 100%;
  overflow: none;
  cursor: pointer;
  border-radius: 15px;
  aspect-ratio: 1/1;
  object-fit: cover;
  background-size: cover;
  background-position: 50%;
  transition: all 500ms ease-in-out;
}
.img media-preview {
  width: 100%;
}
.service-preview-seller {
  display: flex;
  font-size: 1.2em;
  flex-direction: row;
  height: 30px;
}
.service-preview-title {
  height: 30px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.service-preview-description {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limits to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #22255c;
  overflow: hidden;
  text-overflow: ellipsis;
}
.service-preview-price {
  color: #22255c;
  overflow: hidden;
  height: 30px;
  margin-top: 5px;
}
.service-preview-price {
  color: #22255c;
  overflow: hidden;
  font-weight: bold;
  font-size: 1.2em;
}
.product-banner-desktop {
  width: 100%;
  border-radius: 25px;
  background-size: cover;
  aspect-ratio: 2/1;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.product-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
}
.flex-row-between {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.learn-more:hover {
  text-decoration: underline;
  cursor: pointer;
}
.learn-more {
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 4px;
}
.service-preview-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-conent: space-between;
  overflow: hidden;
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
img.service-card-profile {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}
.service-card-profile-name {
  margin-left: 10px;
  font-weight: bold;
  max-width: 150px;
  text-overflow: ellipsis;
}
.service-card-profile-name:hover {
  cursor: pointer;
  text-decoration: underline;
}
.preview-no-image {
    object-fit: cover;
    height: 150px;
    width: 100%;
}

</style>
<style scoped>
:deep(.carousel__prev), :deep(.carousel__next) {
  background-color: white !important;
  padding: 10px !important;
  border-radius: 50% !important;
  opacity: 0.8 !important;
  transition: opacity 0.2s ease-in-out !important;
}

:deep(.carousel__prev:hover), :deep(.carousel__next:hover) {
  opacity: 1 !important;
}

:deep(.carousel__prev--disabled), :deep(.carousel__next--disabled) {
  background-color: #f0f0f0 !important;
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}
</style>
